/** @format */

import React, { useEffect, useState } from "react";
import "../AccountManagePage.css";

import { Link } from "react-router-dom";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Header from "../Header";
import { Button, Col, Form, Row } from "react-bootstrap";
import { TextField, Box } from "@mui/material";
import { Formik, useFormik } from "formik";
import axios from "axios";
import * as Yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faCircleExclamation,
} from "@fortawesome/free-solid-svg-icons";
const RoleMng = () => {
  const [activeTab, setActiveTab] = useState("Profile");
  const [users, setUser] = useState([]);
  const [verifyEmail, setVerifyEmail] = useState(false);
  const [verifyData, setVerifyData] = useState([]);
  const role = JSON.parse(sessionStorage.getItem("data")) || {
    Id: 0,
    roleId: 0,
  };

  const fetchAllData = async () => {
    try {
      const [user] = await Promise.all([
        axios.get("https://enin-server.ga.highendtradingplc.com/user"),
      ]);
      setUser(user.data.find((u) => u.Id === 1));
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchAllData();
  }, []);

  const renderContent = () => {
    switch (activeTab) {
      case "Profile":
        return (
          <div className="account-form" style={{ marginTop: "-10px" }}>
            <Formik
              initialValues={{
                userName: "",
                phoneNumber: "",
                file: "",
                check: "",
              }}
              onSubmit={async (values) => {
                let result = false;
                values.userName =
                  values.userName === "" ? users.userName : values.userName;
                values.phoneNumber =
                  values.phoneNumber === ""
                    ? users.phoneNumber
                    : values.phoneNumber;
                try {
                  const { file } = values;
                  if (!!file) {
                    result = true;
                  }
                  if (result) {
                    const formData = new FormData();
                    formData.append("file", file);
                    formData.append("userName", values.userName);
                    formData.append("phoneNumber", values.phoneNumber);
                    await axios.put(
                      `https://enin-server.ga.highendtradingplc.com/user/update_file/` +
                        users.Id,
                      formData,
                      { headers: { "Content-Type": "multipart/form-data" } }
                    );
                  } else {
                    values.check = 4;
                    await axios.put(
                      `https://enin-server.ga.highendtradingplc.com/user/update/` +
                        users.Id,
                      values
                    );
                  }
                  alert("Data update successfully !!");
                } catch (error) {
                  console.log(error);
                } finally {
                  fetchAllData();
                  values.phoneNumber = "";
                  values.userName = "";
                  values.file = "";
                }
              }}
            >
              {(props) => (
                <Form
                  encType="multipart/form-data"
                  onSubmit={props.handleSubmit}
                >
                  <div className="row">
                    <div className="col-6" style={{ minWidth: "300px" }}>
                      <h4>Profile</h4>
                      <Form.Group as={Row}>
                        <Col sm={12} className="mb-3">
                          <TextField
                            value={props.values.userName}
                            label="Username"
                            size="small"
                            name="userName"
                            style={{ width: "90%" }}
                            onBlur={props.handleBlur}
                            onChange={props.handleChange}
                            error={
                              props.errors.userName && props.touched.userName
                            }
                            helperText={
                              props.errors.userName && props.touched.userName
                                ? props.errors.userName
                                : ""
                            }
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row}>
                        <Col sm={12} className="mb-2">
                          <TextField
                            value={props.values.phoneNumber}
                            label="Phone Number"
                            size="small"
                            name="phoneNumber"
                            style={{ width: "90%" }}
                            onBlur={props.handleBlur}
                            onChange={props.handleChange}
                            error={
                              props.errors.phoneNumber &&
                              props.touched.phoneNumber
                            }
                            helperText={
                              props.errors.phoneNumber &&
                              props.touched.phoneNumber
                                ? props.errors.phoneNumber
                                : ""
                            }
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row}>
                        <Col sm={12}>
                          <Button
                            variant="outline-primary"
                            style={{ width: "90%" }}
                            type="submit"
                          >
                            Save
                          </Button>
                        </Col>
                      </Form.Group>
                    </div>
                    <div className="col-6 pl-4" style={{ minWidth: "300px" }}>
                      <strong className="row">Profile Picture</strong>
                      <img
                        src={`https://enin-server.ga.highendtradingplc.com/${users?.pic}`}
                        className="row"
                        width="250px"
                        height="300px"
                      />
                      <input
                        type="file"
                        name="file"
                        className="mb-2 row"
                        onChange={(e) =>
                          props.setFieldValue("file", e.currentTarget.files[0])
                        }
                        style={{ minWidth: "90%" }}
                      />
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        );
      case "Email":
        return (
          <div className="account-form ml-3" style={{ marginTop: "-10px" }}>
            <Formik
              initialValues={{
                email: "",
                check: "",
                verify: "",
              }}
              onSubmit={async (values) => {
                try {
                  values.check = 4;
                  values.verify = false;
                  values.phoneNumber = users.phoneNumber;
                  values.userName = users.userName;
                  console.log(values);
                  await axios.put(
                    `https://enin-server.ga.highendtradingplc.com/user/update/` +
                      users.Id,
                    values
                  );
                  alert("Email update successfully !!");
                } catch (error) {
                  console.log(error);
                } finally {
                  fetchAllData();
                  values.email = "";
                  values.phoneNumber = "";
                  values.userName = "";
                  values.file = "";
                }
              }}
            >
              {(props) => (
                <Form onSubmit={props.handleSubmit}>
                  <h4>Email</h4>
                  <Form.Group as={Row}>
                    <Col sm={12} className="mb-2">
                      <TextField
                        value={props.values.email}
                        label="Email"
                        size="small"
                        name="email"
                        style={{ width: "300px" }}
                        onBlur={props.handleBlur}
                        onChange={props.handleChange}
                        error={props.errors.email && props.touched.email}
                        helperText={
                          props.errors.email && props.touched.email
                            ? props.errors.email
                            : ""
                        }
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Col sm={12}>
                      <Button
                        variant="outline-primary"
                        style={{ width: "300px" }}
                        type="submit"
                      >
                        Save
                      </Button>
                    </Col>
                  </Form.Group>
                </Form>
              )}
            </Formik>
          </div>
        );
      case "Password":
        return (
          <div className="account-form ml-3" style={{ marginTop: "-10px" }}>
            <Formik
              initialValues={{
                oldPassword: "",
                newPassword: "",
                confirmPassword: "",
              }}
              onSubmit={async (values) => {
                try {
                  await axios
                    .post(
                      "https://enin-server.ga.highendtradingplc.com/login",
                      {
                        userName: users.userName,
                        password: values.oldPassword,
                      }
                    )
                    .then(async (res) => {
                      console.log(res.data);
                      if (res.data.Login) {
                        await axios.put(
                          `https://enin-server.ga.highendtradingplc.com/user/update/` +
                            users.Id,
                          { password: values.newPassword, check: 2 }
                        );
                        alert("Password update successfully !!");
                      } else {
                        alert("You insert incorrect password !!");
                      }
                    });
                } catch (error) {
                  console.log(error);
                } finally {
                  fetchAllData();
                  values.oldPassword = "";
                  values.newPassword = "";
                  values.confirmPassword = "";
                }
              }}
            >
              {(props) => (
                <Form onSubmit={props.handleSubmit}>
                  <h4>Change Password</h4>
                  <Form.Group as={Row}>
                    <Col sm={12} className="mb-3">
                      <TextField
                        value={props.values.oldPassword}
                        label="Old Password"
                        size="small"
                        type="password"
                        name="oldPassword"
                        style={{ width: "90%" }}
                        onBlur={props.handleBlur}
                        onChange={props.handleChange}
                        error={
                          props.errors.oldPassword && props.touched.oldPassword
                        }
                        helperText={
                          props.errors.oldPassword && props.touched.oldPassword
                            ? props.errors.oldPassword
                            : ""
                        }
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Col sm={12} className="mb-3">
                      <TextField
                        value={props.values.newPassword}
                        label="New Password"
                        size="small"
                        type="password"
                        name="newPassword"
                        style={{ width: "90%" }}
                        onBlur={props.handleBlur}
                        onChange={props.handleChange}
                        error={
                          props.errors.newPassword && props.touched.newPassword
                        }
                        helperText={
                          props.errors.newPassword && props.touched.newPassword
                            ? props.errors.newPassword
                            : ""
                        }
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Col sm={12} className="mb-3">
                      <TextField
                        value={props.values.confirmPassword}
                        label="Confirm Password"
                        size="small"
                        type="password"
                        name="confirmPassword"
                        style={{ width: "90%" }}
                        onBlur={props.handleBlur}
                        onChange={props.handleChange}
                        error={
                          props.errors.confirmPassword &&
                          props.touched.confirmPassword
                        }
                        helperText={
                          props.errors.confirmPassword &&
                          props.touched.confirmPassword
                            ? props.errors.confirmPassword
                            : ""
                        }
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Col sm={12}>
                      <Button
                        variant="outline-primary"
                        style={{ width: "90%" }}
                        type="submit"
                      >
                        Save
                      </Button>
                    </Col>
                  </Form.Group>
                </Form>
              )}
            </Formik>
          </div>
        );
      case "Two-factor authentication":
        return (
          <div className="account-form ml-3" style={{ marginTop: "-10px" }}>
            <Formik
              initialValues={{
                value: "",
              }}
              onSubmit={async (values) => {
                try {
                  if (!verifyEmail) {
                    const data = await axios.put(
                      `https://enin-server.ga.highendtradingplc.com/user/update/${users.Id}`,
                      { check: 1 }
                    );
                    setVerifyData(data.data);
                    setVerifyEmail(true);
                    return;
                  } else {
                    console.log(values);
                    if (values.value !== verifyData.verify) {
                      alert("You insert incorrect verification code !!!");
                      return;
                    }
                    await axios.put(
                      `https://enin-server.ga.highendtradingplc.com/user/update/${users.Id}`,
                      { verify: true, check: 4 }
                    );
                    fetchAllData();
                    setVerifyEmail(false);
                  }
                } catch (error) {
                  console.log(error);
                }
              }}
            >
              {(props) => (
                <Form onSubmit={props.handleSubmit}>
                  <h4 className="mb-4">Email Verification </h4>

                  <h6>
                    <strong>
                      {users.email}{" "}
                      {users.verify ? (
                        <span style={{ marginLeft: "10px" }}>
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            style={{ marginBottom: "-2px", color: "green" }}
                          />
                        </span>
                      ) : (
                        <span style={{ marginLeft: "10px" }}>
                          <FontAwesomeIcon
                            icon={faCircleExclamation}
                            style={{ marginBottom: "-2px", color: "red" }}
                          />
                        </span>
                      )}
                    </strong>
                  </h6>
                  <h6 className=" mb-4">
                    your email is{" "}
                    {users.verify ? "verify !!!" : "not verify !!!"}
                  </h6>
                  {!users.verify && (
                    <div>
                      {!verifyEmail && (
                        <Form.Group as={Row}>
                          <Col sm={12}>
                            <Button
                              variant="outline-primary"
                              style={{ width: "300px" }}
                              type="submit"
                            >
                              Verify Email
                            </Button>
                          </Col>
                        </Form.Group>
                      )}
                      {verifyEmail && (
                        <div>
                          <Form.Group as={Row} className="mt-3 mb-3">
                            <Col sm={12}>
                              <TextField
                                label="Verification Code"
                                size="small"
                                style={{ width: "300px" }}
                                name="value"
                                value={props.values.value}
                                onBlur={props.handleBlur}
                                onChange={props.handleChange}
                                error={
                                  props.errors.value && props.touched.value
                                }
                                helperText={
                                  props.errors.value && props.touched.value
                                    ? props.errors.value
                                    : ""
                                }
                              />
                            </Col>
                          </Form.Group>
                          <Form.Group as={Row}>
                            <Col sm={12}>
                              <Button
                                variant="outline-primary"
                                style={{ width: "300px" }}
                                type="submit"
                              >
                                Submit
                              </Button>
                            </Col>
                          </Form.Group>
                        </div>
                      )}
                    </div>
                  )}
                </Form>
              )}
            </Formik>
          </div>
        );
      case "Personal data":
        return <div>Personal data management content goes here...</div>;
      default:
        return null;
    }
  };
  return (
    <div>
      <div>
        <Header />
      </div>
      <main style={{ marginTop: "120px" }}>
        <div
          className="tab-container "
          style={{
            marginTop: "-20px",
            marginBottom: "-5px",
          }}
        >
          <Link className="p-2" to={`/user_mgt`}>
            User Mgt
          </Link>
          <Link className="p-2" to={`/role_mgt`}>
            Role Mgt
          </Link>
          <Link
            className="p-2"
            to={`/account_mgt`}
            style={{
              backgroundColor: "white",
              borderRadius: "5px 5px 0 0",
              border: "solid 0.05em rgb(181, 181, 181)",
            }}
          >
            Account Mgt
          </Link>
        </div>
        <div className="card card-info card-outline">
          {/* <div className="card-header">
            <center>
              <div>
                <h4>System Stock Balance</h4>
              </div>
            </center>
          </div> */}
          <div className="card-body">
            <div
              className="nav-bra pl-3 ml-1 p-2 "
              style={{
                backgroundColor: "rgb(235, 235, 235)",
              }}
            >
              <span>Home / Manage</span>
            </div>

            <div style={{}}>
              <h3 className="pl-3 pt-2">Manage Your Account</h3>
              <h5 className="pl-3">Change your account settings</h5>
            </div>
            <div style={{ border: "solid 1px #ffb71b" }}></div>

            <div className="manage-container mt-1 row">
              <div className="account-menu col">
                <ul>
                  <li
                    className={
                      activeTab === "Profile" ? "active bg-primary" : ""
                    }
                    style={
                      activeTab === "Profile"
                        ? { border: "solid 1px white" }
                        : {}
                    }
                    onClick={() => setActiveTab("Profile")}
                  >
                    Profile
                  </li>
                  <li
                    className={activeTab === "Email" ? "active bg-primary" : ""}
                    onClick={() => setActiveTab("Email")}
                    style={
                      activeTab === "Email" ? { border: "solid 1px white" } : {}
                    }
                  >
                    Email
                  </li>
                  <li
                    className={
                      activeTab === "Password" ? "active bg-primary" : ""
                    }
                    onClick={() => setActiveTab("Password")}
                    style={
                      activeTab === "Password"
                        ? { border: "solid 1px white" }
                        : {}
                    }
                  >
                    Password
                  </li>
                  <li
                    className={
                      activeTab === "Two-factor authentication"
                        ? "active bg-primary"
                        : ""
                    }
                    onClick={() => setActiveTab("Two-factor authentication")}
                    style={
                      activeTab === "Two-factor authentication"
                        ? { border: "solid 1px white" }
                        : {}
                    }
                  >
                    Two-factor authentication
                  </li>
                  <li
                    className={
                      activeTab === "Personal data" ? "active bg-primary" : ""
                    }
                    onClick={() => setActiveTab("Personal data")}
                    style={
                      activeTab === "Personal data"
                        ? { border: "solid 1px white" }
                        : {}
                    }
                  >
                    Personal data
                  </li>
                </ul>
              </div>
              <div
                className="account-content col"
                style={{ float: "right", minWidth: "330px" }}
              >
                {renderContent()}
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};
export default RoleMng;
