/** @format */

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Header from "../Header";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Autocomplete, Box, TextField } from "@mui/material";
const SalesReport = () => {
  const [sales, setSales] = useState([]);
  const [customers, setCustomer] = useState([]);
  const [allSales, setAllSales] = useState([]);
  const [oneCustomer, setOneCustomer] = useState(null);
  const [salesDate, setSalesDate] = useState({
    start: new Date(),
    end: new Date(),
  });
  const fetchAllData = async () => {
    const [sales, customer] = await Promise.all([
      axios.get("https://enin-server.ga.highendtradingplc.com/sales_ref"),
      axios.get("https://enin-server.ga.highendtradingplc.com/customer"),
    ]);
    setAllSales(sales.data);
    setCustomer(customer.data);
    let data = [];
    sales.data?.map((qua) => {
      const date = new Date(qua.date);
      if (
        (date.getFullYear() > salesDate.start.getFullYear() &&
          date.getFullYear() < salesDate.end.getFullYear()) ||
        (date.getFullYear() >= salesDate.start.getFullYear() &&
          date.getMonth() > salesDate.start.getMonth() &&
          date.getFullYear() <= salesDate.end.getFullYear() &&
          date.getMonth() < salesDate.end.getMonth()) ||
        (date.getFullYear() >= salesDate.start.getFullYear() &&
          date.getMonth() >= salesDate.start.getMonth() &&
          date.getDate() >= salesDate.start.getDate() &&
          date.getFullYear() <= salesDate.end.getFullYear() &&
          date.getMonth() <= salesDate.end.getMonth() &&
          date.getDate() <= salesDate.end.getDate())
      ) {
        data.push(qua);
      }
      setSales(data);
    });
  };
  useEffect(() => {
    fetchAllData();
  }, []);
  const defaultProps = {
    options: customers,
    getOptionLabel: (option) => option.fullName,
  };

  const columns = [
    {
      field: "Id",
      headerClassName: "super-app-theme--header",
      headerName: "#",
      width: 100,
    },
    {
      field: "id",
      headerClassName: "super-app-theme--header",
      headerName: "SalesID",
      width: 100,
    },
    {
      field: "fullName",
      headerClassName: "super-app-theme--header",
      headerName: "Customer Name",
      width: 200,
    },
    {
      field: "tinNumber",
      headerClassName: "super-app-theme--header",
      headerName: "Tin",
      width: 150,
    },
    {
      field: "fsNumber",
      headerClassName: "super-app-theme--header",
      headerName: "FS-No",
      width: 150,
    },

    {
      field: "price",
      headerClassName: "super-app-theme--header",
      headerName: "Price",
      width: 150,
    },
    {
      field: "date",
      headerClassName: "super-app-theme--header",
      headerName: "Date",
      width: 150,
    },
  ];
  const rows = sales?.map((sale, index) => {
    const customer = customers.find((s) => s.Id === sale.customerId);

    return {
      Id: index + 1,
      id: sale.Id,
      fullName: customer.fullName,
      tinNumber: customer.tinNumber,
      fsNumber: sale.fsNumber,
      price: sale.totalPrice,
      date: sale.date,
    };
  });
  return (
    <div>
      <div>
        <Header />
      </div>
      <main style={{ marginTop: "120px" }}>
        <div
          className="tab-container "
          style={{
            marginTop: "-20px",
            marginBottom: "-5px",
          }}
        >
          <Link className="p-2" to={`/purchase_report`}>
            Purchase Report
          </Link>
          <Link
            className="p-2"
            to={`/sales_report`}
            style={{
              backgroundColor: "white",
              borderRadius: "5px 5px 0 0",
              border: "solid 0.05em rgb(181, 181, 181)",
            }}
          >
            Sales Report
          </Link>
          <Link className="p-2" to={`/stock_report`}>
            Stock Report
          </Link>
          <Link className="p-2" to={`/quantity_report`}>
            Quantity Report
          </Link>
          <Link className="p-2" to={`/login_history`}>
            Log History
          </Link>
        </div>
        <div className="card card-info card-outline">
          {/* <div className="card-header">
            <center>
              <div>
                <h4>System Stock Balance</h4>
              </div>
            </center>
          </div> */}
          <div className="card-body">
            <div
              className="nav-bra pl-3 ml-1 p-2 "
              style={{
                backgroundColor: "rgb(235, 235, 235)",
              }}
            >
              <span>Home / Sales Report</span>
            </div>
            <br />
            <div style={{ border: "solid 1px #ffb71b" }}></div>
            <br />
            <Form>
              <Form.Group as={Row}>
                <Col lg={3} className="mb-2">
                  <Autocomplete
                    {...defaultProps}
                    id="controlled-demo"
                    size="small"
                    value={oneCustomer}
                    onChange={(event, newValue) => {
                      setOneCustomer(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Select Customer" />
                    )}
                  />
                </Col>
                <Col lg={4} className="mb-2">
                  <Form.Label
                    style={{
                      margin: "7px 5px 0 0",
                    }}
                  >
                    From
                  </Form.Label>
                  <TextField
                    type="date"
                    size="small"
                    style={{ width: "200px" }}
                    onChange={(event) => {
                      if (
                        new Date() >= new Date(event.target.value) &&
                        new Date(salesDate.end) >= new Date(event.target.value)
                      ) {
                        salesDate.start = new Date(event.target.value);
                      }
                    }}
                  />
                </Col>
                <Col lg={4} className="mb-2 ">
                  <Form.Label
                    style={{
                      margin: "7px 27px 0 0",
                    }}
                  >
                    To
                  </Form.Label>
                  <TextField
                    size="small"
                    type="date"
                    style={{ width: "200px" }}
                    onChange={(event) => {
                      if (new Date() >= new Date(event.target.value)) {
                        salesDate.end = new Date(event.target.value);
                      }
                    }}
                  />
                </Col>
                <Col lg={1}>
                  <center>
                    <Button
                      onClick={(e) => {
                        let data = [];
                        if (oneCustomer === null) {
                          allSales?.map((qua) => {
                            const date = new Date(qua.date);
                            if (
                              (date.getFullYear() >
                                salesDate.start.getFullYear() &&
                                date.getFullYear() <
                                  salesDate.end.getFullYear()) ||
                              (date.getFullYear() >=
                                salesDate.start.getFullYear() &&
                                date.getMonth() > salesDate.start.getMonth() &&
                                date.getFullYear() <=
                                  salesDate.end.getFullYear() &&
                                date.getMonth() < salesDate.end.getMonth()) ||
                              (date.getFullYear() >=
                                salesDate.start.getFullYear() &&
                                date.getMonth() >= salesDate.start.getMonth() &&
                                date.getDate() >= salesDate.start.getDate() &&
                                date.getFullYear() <=
                                  salesDate.end.getFullYear() &&
                                date.getMonth() <= salesDate.end.getMonth() &&
                                date.getDate() <= salesDate.end.getDate())
                            ) {
                              data.push(qua);
                            }
                          });
                        } else {
                          allSales?.map((qua) => {
                            const date = new Date(qua.date);
                            if (
                              (date.getFullYear() >
                                salesDate.start.getFullYear() &&
                                date.getFullYear() <
                                  salesDate.end.getFullYear()) ||
                              (date.getFullYear() >=
                                salesDate.start.getFullYear() &&
                                date.getMonth() > salesDate.start.getMonth() &&
                                date.getFullYear() <=
                                  salesDate.end.getFullYear() &&
                                date.getMonth() < salesDate.end.getMonth()) ||
                              (date.getFullYear() >=
                                salesDate.start.getFullYear() &&
                                date.getMonth() >= salesDate.start.getMonth() &&
                                date.getDate() >= salesDate.start.getDate() &&
                                date.getFullYear() <=
                                  salesDate.end.getFullYear() &&
                                date.getMonth() <= salesDate.end.getMonth() &&
                                date.getDate() <= salesDate.end.getDate() &&
                                qua.customerId === oneCustomer.Id)
                            ) {
                              data.push(qua);
                            }
                          });
                        }

                        setSales(data);
                      }}
                      variant="outline-info"
                    >
                      Search
                    </Button>
                  </center>
                </Col>
              </Form.Group>
            </Form>
            <hr />
            <Box
              sx={{
                height: 500,
                width: "100%",
                "& .super-app-theme--header": {
                  backgroundColor: "#1de5ec",
                  color: "white",
                },
              }}
            >
              <DataGrid
                rows={rows}
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                columns={columns}
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                  },
                }}
                pageSize={5}
                getRowId={(row) => row.Id}
              />
            </Box>
          </div>
        </div>
      </main>
    </div>
  );
};
export default SalesReport;
