/** @format */
import React, { useEffect, useState } from "react";
import { Button, Modal, Form, Col, Row } from "react-bootstrap";
import { useFormik, Formik } from "formik";
import TextField from "@mui/material/TextField";
import { Checkbox, MenuItem } from "@mui/material";
import axios from "axios";
import * as Yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faUsers,
  faBox,
  faShoppingCart,
  faReceipt,
  faChartPie,
  faBaby,
} from "@fortawesome/free-solid-svg-icons";
import PeopleIcon from "@mui/icons-material/People";
import Header from "../Header";

const validationSchema = Yup.object().shape({
  fullName: Yup.string()
    .required("Name is required")
    .min(8, "Too Short!")
    .max(50, "Too Long"),
  phoneNumber: Yup.string()
    .required("Phone Number is required")
    .min(9, "Too Short!")
    .max(10, "Too Long"),
  address: Yup.string()
    .required("Address is required")
    .min(3, "Too Short!")
    .max(30, "Too Long"),
  tinNumber: Yup.string()
    .required("Tin Number is required")
    .min(2, "Too Short!")
    .max(20, "Too Long"),
});

const validationSchema2 = Yup.object().shape({
  description: Yup.string().required("Name is required"),
  modelId: Yup.string().required("Model is required"),
  code: Yup.string().required("Code is required"),
  taxId: Yup.string().required("Tax is required"),
  unitId: Yup.string().required("Unit is required"),
  price: Yup.number()
    .required("Price Number is required")
    .min(10, "It is too low!!!")
    .max(100000, "It is too high!!!"),
  typeId: Yup.string().required("Type is required"),
});
const HomePage = () => {
  const [open, setOpen] = useState(false);
  const [opensup, setOpensup] = useState(false);
  const [openitem, setOpenitem] = useState(false);
  const [count, setCount] = useState(0);
  const [countsup, setCountsup] = useState(0);
  const [customers, setCustomers] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [types, setTypes] = useState([]);
  const [taxs, setTaxs] = useState([]);
  const [units, setUnits] = useState([]);
  const [models, setModels] = useState([]);
  const [items, setItems] = useState([]);

  const fetchCount = async () => {
    try {
      const res = await axios.get(
        "https://enin-server.ga.highendtradingplc.com/customer/count"
      );
      setCount(res.data.count);
      const res1 = await axios.get(
        "https://enin-server.ga.highendtradingplc.com/customer"
      );
      setCustomers(res1.data);
      const ress = await axios.get(
        "https://enin-server.ga.highendtradingplc.com/supplier/count"
      );
      setCountsup(ress.data.count);
      const ress1 = await axios.get(
        "https://enin-server.ga.highendtradingplc.com/supplier"
      );
      setSuppliers(ress1.data);

      const res2 = await axios.get(
        "https://enin-server.ga.highendtradingplc.com/unit"
      );
      setUnits(res2.data);
      const res3 = await axios.get(
        "https://enin-server.ga.highendtradingplc.com/type"
      );
      setTypes(res3.data);
      const res4 = await axios.get(
        "https://enin-server.ga.highendtradingplc.com/model"
      );
      setModels(res4.data);
      const res5 = await axios.get(
        "https://enin-server.ga.highendtradingplc.com/item"
      );
      setItems(res5.data);
      const res6 = await axios.get(
        "https://enin-server.ga.highendtradingplc.com/tax"
      );
      setTaxs(res6.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchCount();
  }, []);
  const formikitem = useFormik({
    initialValues: {
      description: "",
      modelId: "",
      code: "",
      unitId: "",
      taxId: "",
      typeId: "",
    },
    validationSchema: validationSchema2,
    onSubmit: async (values, { resetForm, setErrors }) => {
      try {
        const { description, code } = values;
        const itemexist = items.find(
          (item) => item?.description === description && item?.code === code
        );
        if (itemexist) {
          alert("Item already exists");
          handleCloseitem();
          resetForm();
          formikitem.setErrors("already exists");
          return;
        }
        await axios.post(
          "https://enin-server.ga.highendtradingplc.com/item/add",
          values
        );
        fetchCount();
        handleCloseitem();
        resetForm();
        alert("Item added successfully");
      } catch (error) {
        console.log(error);
      }
    },
  });

  const formik = useFormik({
    initialValues: {
      fullName: "",
      tinNumber: "",
      phoneNumber: "",
      address: "",
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        const { fullName, tinNumber, phoneNumber } = values;
        console.log(fullName, tinNumber);
        const Custexist = customers.find(
          (customer) =>
            customer?.fullName === fullName && customer?.tinNumber === tinNumber
        );
        const Custexist1 = customers.find(
          (customer) => customer?.phoneNumber === phoneNumber
        );
        if (Custexist) {
          alert("Customer already exists.");
          setOpen(false);
          resetForm();
          formik.setErrors("already exists");
          return;
        }
        if (Custexist1) {
          alert("Phone number already exists.");
          setOpen(false);
          resetForm();
          formik.setErrors("already exists");
          return;
        }
        await axios.post(
          "https://enin-server.ga.highendtradingplc.com/customer/add",
          values
        );
        fetchCount();
        setOpen(false);
        resetForm();

        alert("Customer added successfully!");
      } catch (error) {
        console.log(error);
        alert("Failed to add customer.");
      }
    },
  });
  const formiksup = useFormik({
    initialValues: {
      fullName: "",
      tinNumber: "",
      phoneNumber: "",
      address: "",
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        const { fullName, tinNumber, phoneNumber } = values;

        const Supexist = suppliers.find(
          (supplier) =>
            supplier?.fullName === fullName && supplier?.tinNumber === tinNumber
        );
        const Supexist1 = suppliers.find(
          (supplier) => supplier?.phoneNumber === phoneNumber
        );
        if (Supexist) {
          alert("Supplier already exists.");
          setOpensup(false);
          resetForm();
          formik.setErrors("already exists");
          return;
        }
        if (Supexist1) {
          alert("Phone number already exists.");
          setOpensup(false);
          resetForm();
          formik.setErrors("already exists");
          return;
        }
        await axios.post(
          "https://enin-server.ga.highendtradingplc.com/supplier/add",
          values
        );
        setOpensup(false);
        resetForm();
        fetchCount();
        alert("Supplier added successfully!");
      } catch (error) {
        console.log(error);
        alert("Failed to add supplier.");
      }
    },
  });

  const handleAdd = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleAddsup = () => {
    setOpensup(true);
  };

  const handleClosesup = () => {
    setOpensup(false);
  };
  const handleAdditem = () => {
    setOpenitem(true);
  };
  const handleCloseitem = () => {
    setOpenitem(false);
  };

  return (
    <div className="">
      <div>
        <Header />
      </div>
      <main style={{ marginTop: "120px" }}>
        <div
          className="card card-info card-outline text-center"
          style={{ backgroundColor: "white" }}
        >
          <h3 className="p-3">Welcome superadmin to Inventory System</h3>
        </div>
        <div className="ml-5 pl-5">
          {" "}
          <div className="row ml-5 mr-3">
            <div
              className="col col-lg-3 ml-1"
              style={{ minWidth: "270px", maxWidth: "350px" }}
            >
              <div className="info-box card-info card-outline row">
                <span className="info-box-icon bg-info col-sm-3">
                  <FontAwesomeIcon icon={faUser} />
                </span>
                <div className="col">
                  <span className="info-box-text" style={{ float: "left" }}>
                    Customer
                  </span>
                  <br />
                  <span className="info-box-number" style={{ float: "left" }}>
                    Total:{count}
                  </span>
                  <br />
                  <span>
                    <Button
                      style={{ float: "right", width: "90px", height: "30px" }}
                      onClick={handleAdd}
                    >
                      <h3 style={{ float: "left", marginTop: "-8px" }}>+</h3>
                      <h6 style={{ float: "left" }}>New</h6>
                    </Button>
                  </span>
                </div>
              </div>
            </div>
            <div
              className="col col-lg-3 ml-1"
              style={{ minWidth: "270px", maxWidth: "350px" }}
            >
              <div className="info-box card-info card-outline row">
                <span className="info-box-icon bg-info col-sm-3">
                  <FontAwesomeIcon icon={faUsers} />
                </span>
                <div className="col">
                  <span className="info-box-text" style={{ float: "left" }}>
                    Supplier
                  </span>
                  <br />
                  <span className="info-box-number" style={{ float: "left" }}>
                    Total: {countsup}
                  </span>
                  <br />
                  <span>
                    <Button
                      style={{ float: "right", width: "90px", height: "30px" }}
                      onClick={handleAddsup}
                    >
                      <h3 style={{ float: "left", marginTop: "-8px" }}>+</h3>
                      <h6 style={{ float: "left" }}>New</h6>
                    </Button>
                  </span>
                </div>
              </div>
            </div>

            <div
              className="col col-lg-3 ml-1"
              style={{ minWidth: "270px", maxWidth: "350px" }}
            >
              <div className="info-box card-info card-outline row">
                <span className="info-box-icon bg-info col-sm-3">
                  <FontAwesomeIcon icon={faBox} />
                </span>
                <div className="col">
                  <span className="info-box-text" style={{ float: "left" }}>
                    Item
                  </span>
                  <br />
                  <span className="info-box-number" style={{ float: "left" }}>
                    Total: {items.length}
                  </span>
                  <br />
                  <span>
                    <Button
                      style={{ float: "right", width: "90px", height: "30px" }}
                      onClick={handleAdditem}
                    >
                      <h3 style={{ float: "left", marginTop: "-8px" }}>+</h3>
                      <h6 style={{ float: "left" }}>New</h6>
                    </Button>
                  </span>
                </div>
              </div>
            </div>

            <div
              className="col col-lg-3 ml-1"
              style={{ minWidth: "270px", maxWidth: "350px" }}
            >
              <div className="info-box card-info card-outline row">
                <span className="info-box-icon bg-info col-sm-3">
                  <FontAwesomeIcon icon={faShoppingCart} />
                </span>
                <div className="col">
                  <span className="info-box-text" style={{ float: "left" }}>
                    Purchase
                  </span>
                  <br />
                  <span className="info-box-number" style={{ float: "left" }}>
                    Request:- 0
                  </span>
                  <br />
                  <span>
                    <Button
                      style={{ float: "right", width: "85px", height: "30px" }}
                    >
                      <h3 style={{ float: "left", marginTop: "-8px" }}>+</h3>
                      <h6 style={{ float: "left" }}>New</h6>
                    </Button>
                  </span>
                </div>
              </div>
            </div>
            <div
              className="col col-lg-3 ml-1"
              style={{ minWidth: "270px", maxWidth: "350px" }}
            >
              <div className="info-box card-info card-outline row">
                <span className="info-box-icon bg-info col-sm-3">
                  <FontAwesomeIcon icon={faReceipt} />
                </span>
                <div className="col">
                  <span className="info-box-text" style={{ float: "left" }}>
                    Sales
                  </span>
                  <br />
                  <span className="info-box-number" style={{ float: "left" }}>
                    Total:- 0
                  </span>
                  <br />
                  <span>
                    <Button
                      style={{ float: "right", width: "90px", height: "30px" }}
                    >
                      <h3 style={{ float: "left", marginTop: "-8px" }}>+</h3>
                      <h6 style={{ float: "left" }}>New</h6>
                    </Button>
                  </span>
                </div>
              </div>
            </div>
            <div
              className="col col-lg-3 ml-1"
              style={{ minWidth: "270px", maxWidth: "350px" }}
            >
              <div className="info-box card-info card-outline row">
                <span className="info-box-icon bg-info col-sm-3">
                  <FontAwesomeIcon icon={faChartPie} />
                </span>
                <div className="col">
                  <span className="info-box-text" style={{ float: "left" }}>
                    Report
                  </span>
                  <br />
                  <span className="info-box-number" style={{ float: "left" }}>
                    Total Balance:- 0
                  </span>
                  <br />
                  <span>
                    <Button
                      style={{ float: "right", width: "90px", height: "30px" }}
                    >
                      <h3 style={{ float: "left", marginTop: "-8px" }}>+</h3>
                      <h6 style={{ float: "left" }}>New</h6>
                    </Button>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Modal show={open} onHide={handleClose} style={{ minWidth: "700px" }}>
        <Modal.Header closeButton>
          <Modal.Title>Create Customer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="m-2 ml-5 pl-5" onSubmit={formik.handleSubmit}>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formHorizontalUsername"
            >
              <Col sm={8}>
                <TextField
                  id="outlined-basic"
                  label="Full Name"
                  variant="outlined"
                  size="small"
                  type="text"
                  name="fullName"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.fullName}
                  error={formik.touched.fullName && formik.errors.fullName}
                  helperText={
                    formik.touched.fullName && formik.errors.fullName
                      ? `${formik.errors.fullName}`
                      : ""
                  }
                  style={{ maxWidth: "300px" }}
                />
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formHorizontalUsername"
            >
              <Col sm={8}>
                <TextField
                  id="outlined-basic"
                  label="Till Number"
                  variant="outlined"
                  size="small"
                  type="text"
                  name="tinNumber"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.tinNumber}
                  error={formik.touched.tinNumber && formik.errors.tinNumber}
                  helperText={
                    formik.touched.tinNumber && formik.errors.tinNumber
                      ? `${formik.errors.tinNumber}`
                      : ""
                  }
                  style={{ maxWidth: "300px" }}
                />
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formHorizontalUsername"
            >
              <Col sm={8}>
                <TextField
                  id="outlined-basic"
                  label="Phone Number"
                  variant="outlined"
                  size="small"
                  type="text"
                  name="phoneNumber"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.phoneNumber}
                  error={
                    formik.touched.phoneNumber && formik.errors.phoneNumber
                  }
                  helperText={
                    formik.touched.phoneNumber && formik.errors.phoneNumber
                      ? `${formik.errors.phoneNumber}`
                      : ""
                  }
                  style={{ maxWidth: "300px" }}
                />
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formHorizontalUsername"
            >
              <Col sm={8}>
                <TextField
                  id="outlined-basic"
                  label="Address"
                  variant="outlined"
                  size="small"
                  type="text"
                  name="address"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.address}
                  error={formik.touched.address && formik.errors.address}
                  helperText={
                    formik.touched.address && formik.errors.address
                      ? `${formik.errors.address}`
                      : ""
                  }
                  style={{ maxWidth: "300px" }}
                />
              </Col>
            </Form.Group>
            <Button variant="primary" type="submit">
              Add Customer
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal
        show={opensup}
        onHide={handleClosesup}
        style={{ minWidth: "700px" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Create Supplier</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="m-2 ml-5 pl-5" onSubmit={formiksup.handleSubmit}>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formHorizontalUsername"
            >
              <Col sm={8}>
                <TextField
                  id="outlined-basic"
                  label="Full Name"
                  variant="outlined"
                  size="small"
                  type="text"
                  name="fullName"
                  onChange={formiksup.handleChange}
                  onBlur={formiksup.handleBlur}
                  value={formiksup.values.fullName}
                  error={
                    formiksup.touched.fullName && formiksup.errors.fullName
                  }
                  helperText={
                    formiksup.touched.fullName && formiksup.errors.fullName
                      ? `${formiksup.errors.fullName}`
                      : ""
                  }
                  style={{ maxWidth: "300px" }}
                />
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formHorizontalUsername"
            >
              <Col sm={8}>
                <TextField
                  id="outlined-basic"
                  label="Till Number"
                  variant="outlined"
                  size="small"
                  type="text"
                  name="tinNumber"
                  onChange={formiksup.handleChange}
                  onBlur={formiksup.handleBlur}
                  value={formiksup.values.tinNumber}
                  error={
                    formiksup.touched.tinNumber && formiksup.errors.tinNumber
                  }
                  helperText={
                    formiksup.touched.tinNumber && formiksup.errors.tinNumber
                      ? `${formiksup.errors.tinNumber}`
                      : ""
                  }
                  style={{ maxWidth: "300px" }}
                />
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formHorizontalUsername"
            >
              <Col sm={8}>
                <TextField
                  id="outlined-basic"
                  label="Phone Number"
                  variant="outlined"
                  size="small"
                  type="text"
                  name="phoneNumber"
                  onChange={formiksup.handleChange}
                  onBlur={formiksup.handleBlur}
                  value={formiksup.values.phoneNumber}
                  error={
                    formiksup.touched.phoneNumber &&
                    formiksup.errors.phoneNumber
                  }
                  helperText={
                    formiksup.touched.phoneNumber &&
                    formiksup.errors.phoneNumber
                      ? `${formiksup.errors.phoneNumber}`
                      : ""
                  }
                  style={{ maxWidth: "300px" }}
                />
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formHorizontalUsername"
            >
              <Col sm={8}>
                <TextField
                  id="outlined-basic"
                  label="Address"
                  variant="outlined"
                  size="small"
                  type="text"
                  name="address"
                  onChange={formiksup.handleChange}
                  onBlur={formiksup.handleBlur}
                  value={formiksup.values.address}
                  error={formiksup.touched.address && formiksup.errors.address}
                  helperText={
                    formiksup.touched.address && formiksup.errors.address
                      ? `${formiksup.errors.address}`
                      : ""
                  }
                  style={{ maxWidth: "300px" }}
                />
              </Col>
            </Form.Group>
            <Button variant="primary" type="submit">
              Add Supplier
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal
        show={openitem}
        onHide={handleCloseitem}
        style={{ minWidth: "700px" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Create Item</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="m-2 ml-5 pl-5" onSubmit={formikitem.handleSubmit}>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formHorizontalUsername"
            >
              <Col sm={8}>
                <TextField
                  id="outlined-basic"
                  label="description"
                  variant="outlined"
                  size="small"
                  type="text"
                  name="description"
                  onChange={formikitem.handleChange}
                  onBlur={formikitem.handleBlur}
                  value={formikitem.values.description}
                  error={
                    formikitem.touched.description &&
                    formikitem.errors.description
                  }
                  helperText={
                    formikitem.touched.description &&
                    formikitem.errors.description
                      ? `${formikitem.errors.description}`
                      : ""
                  }
                  style={{ maxWidth: "300px" }}
                />
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formHorizontalUsername"
            >
              <Col sm={12}>
                <TextField
                  id="outlined-basic"
                  label="Select Model"
                  variant="outlined"
                  select
                  size="small"
                  name="modelId"
                  onChange={formikitem.handleChange}
                  onBlur={formikitem.handleBlur}
                  value={formikitem.values.modelId}
                  error={
                    formikitem.touched.modelId && formikitem.errors.modelId
                  }
                  helperText={
                    formikitem.touched.modelId && formikitem.errors.modelId
                      ? `${formikitem.errors.modelId}`
                      : ""
                  }
                  style={{ width: "227px" }}
                >
                  <MenuItem key={null} value={null}>
                    None
                  </MenuItem>
                  {models.map((model) => {
                    return (
                      <MenuItem key={model.Id} value={model.Id}>
                        {model.modelName}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formHorizontalUsername"
            >
              <Col sm={8}>
                <TextField
                  id="outlined-basic"
                  label="code"
                  variant="outlined"
                  size="small"
                  type="text"
                  name="code"
                  onChange={formikitem.handleChange}
                  onBlur={formikitem.handleBlur}
                  value={formikitem.values.code}
                  error={formikitem.touched.code && formikitem.errors.code}
                  helperText={
                    formikitem.touched.code && formikitem.errors.code
                      ? `${formikitem.errors.code}`
                      : ""
                  }
                  style={{ maxWidth: "301px" }}
                />
              </Col>
            </Form.Group>

            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formHorizontalUsername"
            >
              <Col sm={12}>
                <TextField
                  id="outlined-basic"
                  label="Select unit"
                  variant="outlined"
                  select
                  size="small"
                  name="unitId"
                  onChange={formikitem.handleChange}
                  onBlur={formikitem.handleBlur}
                  value={formikitem.values.unitId}
                  error={formikitem.touched.unitId && formikitem.errors.unitId}
                  helperText={
                    formikitem.touched.unitId && formikitem.errors.unitId
                      ? `${formikitem.errors.unitId}`
                      : ""
                  }
                  style={{ width: "227px" }}
                >
                  <MenuItem key={null} value={null}>
                    None
                  </MenuItem>
                  {units.map((unit) => {
                    return (
                      <MenuItem key={unit.Id} value={unit.Id}>
                        {unit.unitName}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Col>
            </Form.Group>

            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formHorizontalUsername"
            >
              <Col sm={12}>
                <TextField
                  id="outlined-basic"
                  label="Select tax"
                  variant="outlined"
                  select
                  size="small"
                  name="taxId"
                  onChange={formikitem.handleChange}
                  onBlur={formikitem.handleBlur}
                  value={formikitem.values.taxId}
                  error={formikitem.touched.taxId && formikitem.errors.taxId}
                  helperText={
                    formikitem.touched.taxId && formikitem.errors.taxId
                      ? `${formikitem.errors.taxId}`
                      : ""
                  }
                  style={{ width: "227px" }}
                >
                  <MenuItem key={null} value={null}>
                    None
                  </MenuItem>
                  {taxs.map((tax) => {
                    return (
                      <MenuItem key={tax.Id} value={tax.Id}>
                        {tax.taxValue}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formHorizontalUsername"
            >
              <Col sm={12}>
                <TextField
                  id="outlined-basic"
                  label="Select type"
                  variant="outlined"
                  select
                  size="small"
                  name="typeId"
                  onChange={formikitem.handleChange}
                  onBlur={formikitem.handleBlur}
                  value={formikitem.values.typeId}
                  error={formikitem.touched.typeId && formikitem.errors.typeId}
                  helperText={
                    formikitem.touched.typeId && formikitem.errors.typeId
                      ? `${formikitem.errors.typeId}`
                      : ""
                  }
                  style={{ width: "227px" }}
                >
                  <MenuItem key={null} value={null}>
                    None
                  </MenuItem>
                  {types.map((type) => {
                    return (
                      <MenuItem key={type.Id} value={type.Id}>
                        {type.typeName}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Col>
            </Form.Group>

            <Button variant="outline-primary" type="submit">
              Add Item
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default HomePage;
