/** @format */
import React, { useState, useEffect } from "react";
import { Button, Modal, Form, Col, Row } from "react-bootstrap";
import { useFormik } from "formik";
import TextField from "@mui/material/TextField";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import * as Yup from "yup";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import Header from "../Header";
import { faPenToSquare, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const validationSchema = Yup.object().shape({
  typeName: Yup.string()
    .required("Type is required")
    .min(1, "Too Short!")
    .max(20, "Too Long"),
  unitName: Yup.string()
    .required("Unit is required")
    .min(1, "Too Short!")
    .max(20, "Too Long"),
  modelName: Yup.string()
    .required("Model is required")
    .min(1, "Too Short!")
    .max(20, "Too Long"),
  taxValue: Yup.string()
    .required("Tax is required")
    .min(1, "Too Short!")
    .max(20, "Too Long"),
});

const UnitType = () => {
  const [open, setOpen] = useState(false);
  const [types, setTypes] = useState([]);
  const [units, setUnits] = useState([]);
  const [models, setModels] = useState([]);
  const [taxs, setTaxs] = useState([]);
  const { id } = useParams();

  const handleOpen = () => {
    setOpen(false);
  };

  const fetchAllData = async () => {
    try {
      const [type, unit, model, tax] = await Promise.all([
        axios.get("https://enin-server.ga.highendtradingplc.com/type"),
        axios.get("https://enin-server.ga.highendtradingplc.com/unit"),
        axios.get("https://enin-server.ga.highendtradingplc.com/model"),
        axios.get("https://enin-server.ga.highendtradingplc.com/tax"),
      ]);
      setTypes(type.data);
      setUnits(unit.data);
      setModels(model.data);
      setTaxs(tax.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchAllData();
  }, []);

  const formik = useFormik({
    initialValues: {
      typeName: "",
      unitName: "",
      modelName: "",
      taxValue: "",
      taxType: "",
      id: "",
    },

    validationSchema,
    onSubmit: async (values) => {
      try {
        if (values.id === "") {
          await axios.post(
            `https://enin-server.ga.highendtradingplc.com/${
              parseInt(id) === 1
                ? "type"
                : parseInt(id) === 2
                ? "unit"
                : parseInt(id) === 3
                ? "model"
                : "tax"
            }/add`,
            values
          );
          setOpen(false);
        } else {
          await axios.put(
            `https://enin-server.ga.highendtradingplc.com/${
              parseInt(id) === 1
                ? "type"
                : parseInt(id) === 2
                ? "unit"
                : parseInt(id) === 3
                ? "model"
                : "tax"
            }/update/` + values.id,
            values
          );
          setOpen(false);
        }
      } catch (error) {
        console.log(error);
      } finally {
        formik.values.typeName = "";
        formik.values.unitName = "";
        formik.values.modelName = "";
        formik.values.taxValue = "";
        formik.values.taxType = "";
        formik.values.id = "";
        fetchAllData();
      }
    },
  });

  const handleAdd = () => {
    if (parseInt(id) !== 1) {
      formik.values.typeName = "type";
    }
    if (parseInt(id) !== 2) {
      formik.values.unitName = "unit";
    }
    if (parseInt(id) !== 3) {
      formik.values.modelName = "model";
    }
    if (parseInt(id) !== 4) {
      formik.values.taxValue = 0;
      formik.values.taxType = "tax";
    }
    setOpen(true);
  };
  const handleUpdate = (Id) => {
    if (parseInt(id) === 1) {
      const type = types.find((ty) => ty.Id === Id);
      formik.values.typeName = type.typeName;
      formik.values.unitName = "unit";
      formik.values.modelName = "model";
      formik.values.taxValue = 0;
      formik.values.taxType = "tax";
      formik.values.id = Id;
    }
    if (parseInt(id) === 2) {
      const unit = units.find((ty) => ty.Id === Id);
      formik.values.typeName = "type";
      formik.values.unitName = unit.unitName;
      formik.values.modelName = "model";
      formik.values.taxValue = 0;
      formik.values.taxType = "tax";
      formik.values.id = Id;
    }
    if (parseInt(id) === 3) {
      const model = models.find((ty) => ty.Id === Id);
      formik.values.typeName = "type";
      formik.values.unitName = "unit";
      formik.values.modelName = model.modelName;
      formik.values.taxValue = 0;
      formik.values.taxType = "tax";
      formik.values.id = Id;
    }
    if (parseInt(id) === 4) {
      const tax = taxs.find((ty) => ty.Id === Id);
      formik.values.typeName = "type";
      formik.values.unitName = "unit";
      formik.values.modelName = "model";
      formik.values.taxType = tax.taxType;
      formik.values.taxValue = tax.taxValue;
      formik.values.id = Id;
    }
    setOpen(true);
  };
  const handleDelete = async (Id) => {
    try {
      await axios.delete(
        `https://enin-server.ga.highendtradingplc.com/${
          parseInt(id) === 1
            ? "type"
            : parseInt(id) === 2
            ? "unit"
            : parseInt(id) === 3
            ? "model"
            : "tax"
        }/delete/` + Id
      );
      fetchAllData();
    } catch (error) {}
  };
  const style = {
    backgroundColor: "white",
    borderRadius: "5px 5px 0 0",
    border: "solid 0.05em rgb(181, 181, 181)",
  };
  const columnType = [
    {
      field: "Id",
      headerName: "ID",
      headerAlign: "center",
      align: "center",
      minWidth: 80,
    },
    {
      field: "typeName",
      headerName: "Type Name",
      headerAlign: "center",
      align: "center",
      minWidth: 150,
    },
    {
      field: "action",
      headerName: "Action",
      headerAlign: "center",
      align: "center",
      minWidth: 250,
      renderCell: (params) => (
        <div>
          <Button
            onClick={() => handleUpdate(params.row.Id)}
            variant="outline-warning"
            className=""
          >
            <div className="row mt-1">
              <div className="col">
                <FontAwesomeIcon
                  style={{ float: "right" }}
                  icon={faPenToSquare}
                />
              </div>
              <div className="col-8">
                <h6 style={{ float: "left" }}>Update</h6>
              </div>
            </div>
          </Button>
          <Button
            onClick={() => handleDelete(params.row.Id)}
            variant="outline-danger"
            className="m-1"
          >
            <div className="row mt-1">
              <div className="col">
                <FontAwesomeIcon style={{ float: "right" }} icon={faTrashCan} />
              </div>
              <div className="col-8">
                <h6 style={{ float: "left" }}>Delete</h6>
              </div>
            </div>
          </Button>
        </div>
      ),
    },
  ];
  const columnUnit = [
    {
      field: "Id",
      headerName: "ID",
      headerAlign: "center",
      align: "center",
      minWidth: 80,
    },
    {
      field: "unitName",
      headerName: "Unit",
      headerAlign: "center",
      align: "center",
      minWidth: 150,
    },
    {
      field: "action",
      headerName: "Action",
      headerAlign: "center",
      align: "center",
      minWidth: 250,
      renderCell: (params) => (
        <div>
          <Button
            onClick={() => handleUpdate(params.row.Id)}
            variant="outline-warning"
            className=""
          >
            <div className="row mt-1">
              <div className="col">
                <FontAwesomeIcon
                  style={{ float: "right" }}
                  icon={faPenToSquare}
                />
              </div>
              <div className="col-8">
                <h6 style={{ float: "left" }}>Update</h6>
              </div>
            </div>
          </Button>
          <Button
            onClick={() => handleDelete(params.row.Id)}
            variant="outline-danger"
            className="m-1"
          >
            <div className="row mt-1">
              <div className="col">
                <FontAwesomeIcon style={{ float: "right" }} icon={faTrashCan} />
              </div>
              <div className="col-8">
                <h6 style={{ float: "left" }}>Delete</h6>
              </div>
            </div>
          </Button>
        </div>
      ),
    },
  ];
  const columnModel = [
    {
      field: "Id",
      headerName: "ID",
      headerAlign: "center",
      align: "center",
      minWidth: 80,
    },
    {
      field: "modelName",
      headerName: "Model",
      headerAlign: "center",
      align: "center",
      minWidth: 150,
    },
    {
      field: "action",
      headerName: "Action",
      headerAlign: "center",
      align: "center",
      minWidth: 250,
      renderCell: (params) => (
        <div>
          <Button
            onClick={() => handleUpdate(params.row.Id)}
            variant="outline-warning"
            className=""
          >
            <div className="row mt-1">
              <div className="col">
                <FontAwesomeIcon
                  style={{ float: "right" }}
                  icon={faPenToSquare}
                />
              </div>
              <div className="col-8">
                <h6 style={{ float: "left" }}>Update</h6>
              </div>
            </div>
          </Button>
          <Button
            onClick={() => handleDelete(params.row.Id)}
            variant="outline-danger"
            className="m-1"
          >
            <div className="row mt-1">
              <div className="col">
                <FontAwesomeIcon style={{ float: "right" }} icon={faTrashCan} />
              </div>
              <div className="col-8">
                <h6 style={{ float: "left" }}>Delete</h6>
              </div>
            </div>
          </Button>
        </div>
      ),
    },
  ];
  const columnTax = [
    {
      field: "Id",
      headerName: "ID",
      headerAlign: "center",
      align: "center",
      minWidth: 80,
    },
    {
      field: "taxType",
      headerName: "Tax Type",
      headerAlign: "center",
      align: "center",
      minWidth: 150,
    },
    {
      field: "taxValue",
      headerName: "Tax Value",
      headerAlign: "center",
      align: "center",
      minWidth: 150,
      valueGetter: (params) => `${params}%`,
    },
    {
      field: "action",
      headerName: "Action",
      headerAlign: "center",
      align: "center",
      minWidth: 250,
      renderCell: (params) => (
        <div>
          <Button
            onClick={() => handleUpdate(params.row.Id)}
            variant="outline-warning"
            className=""
          >
            <div className="row mt-1">
              <div className="col">
                <FontAwesomeIcon
                  style={{ float: "right" }}
                  icon={faPenToSquare}
                />
              </div>
              <div className="col-8">
                <h6 style={{ float: "left" }}>Update</h6>
              </div>
            </div>
          </Button>
          <Button
            onClick={() => handleDelete(params.row.Id)}
            variant="outline-danger"
            className="m-1"
          >
            <div className="row mt-1">
              <div className="col">
                <FontAwesomeIcon style={{ float: "right" }} icon={faTrashCan} />
              </div>
              <div className="col-8">
                <h6 style={{ float: "left" }}>Delete</h6>
              </div>
            </div>
          </Button>
        </div>
      ),
    },
  ];
  return (
    <div>
      <div>
        <Header />
      </div>
      <main className="inventory-page" style={{ marginTop: "120px" }}>
        <div
          className="tab-container "
          style={{
            marginTop: "-20px",
            marginBottom: "-17px",
          }}
        >
          <Link
            className="p-2"
            to={`/inventory`}
            style={{
              backgroundColor: "white",
              borderRadius: "5px 5px 0 0",
              border: "solid 0.05em rgb(181, 181, 181)",
            }}
          >
            Item
          </Link>
          <Link className="p-2" to={`/purchase`}>
            Purchase
          </Link>
          <Link className="p-2" to={`/adjustment`}>
            Adjustment
          </Link>
          <Link className="p-2" to={`/quantity_on_hand/${1}`}>
            Qty On Hand
          </Link>
        </div>
        <hr />
        <div
          className="sub-tab-container"
          style={
            parseInt(id) === 3 ||
            parseInt(id) === 4 ||
            parseInt(id) === 1 ||
            parseInt(id) === 2
              ? { marginBottom: "0" }
              : {}
          }
        >
          <Link
            className="pr-2 pl-2"
            to={`/inventory/types/${1}`}
            style={parseInt(id) === 1 ? style : {}}
          >
            Type
          </Link>
          <Link
            className="pr-2 pl-2"
            to={`/inventory/types/${3}`}
            style={parseInt(id) === 3 ? style : {}}
          >
            Model
          </Link>
          <Link
            className="pr-2 pl-2"
            to={`/inventory/types/${2}`}
            style={parseInt(id) === 2 ? style : {}}
          >
            Unit
          </Link>
          <Link
            className="pr-2 pl-2"
            to={`/inventory/types/${4}`}
            style={parseInt(id) === 4 ? style : {}}
          >
            Tax Type
          </Link>
        </div>

        <div className="card card-info card-outline">
          <div className="card-header">
            <Button
              variant="outline-info"
              style={{ width: "150px", height: "35px" }}
              onClick={handleAdd}
            >
              <h3
                style={{
                  float: "left",
                  marginTop: "-6px",
                  marginRight: "5px",
                }}
              >
                +
              </h3>
              <h6 style={{ float: "left", marginTop: "1px" }}> Create New</h6>
            </Button>
          </div>
          <div className="card-body">
            <div>
              <div
                className="nav-bra pl-3 ml-1 p-2"
                style={{
                  backgroundColor: "rgb(235, 235, 235)",
                }}
              >
                <span>
                  Item / {parseInt(id) === 1 && "Type"}
                  {parseInt(id) === 2 && "Unit"}
                  {parseInt(id) === 3 && "Model"}
                  {parseInt(id) === 4 && "Tax"} /Home
                </span>
              </div>
              <hr />
            </div>

            <DataGrid
              rows={
                parseInt(id) === 1
                  ? types
                  : parseInt(id) === 2
                  ? units
                  : parseInt(id) === 3
                  ? models
                  : taxs
              }
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              columns={
                parseInt(id) === 1
                  ? columnType
                  : parseInt(id) === 2
                  ? columnUnit
                  : parseInt(id) === 3
                  ? columnModel
                  : columnTax
              }
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                },
              }}
              pageSize={5}
              getRowId={(row) => row.Id}
            />

            <Modal
              style={{ marginTop: "65px" }}
              show={open}
              onHide={handleOpen}
            >
              <Modal.Header closeButton>
                {parseInt(id) === 1 && (
                  <span>
                    Home / Type /{" "}
                    {formik.values.id === "" ? "Create" : "Update"}
                  </span>
                )}
                {parseInt(id) === 3 && (
                  <span>
                    Home / Model /{" "}
                    {formik.values.id === "" ? "Create" : "Update"}
                  </span>
                )}
                {parseInt(id) === 2 && (
                  <span>
                    Home / Unit /{" "}
                    {formik.values.id === "" ? "Create" : "Update"}
                  </span>
                )}
                {parseInt(id) === 4 && (
                  <span>
                    Home / Tax Type /{" "}
                    {formik.values.id === "" ? "Create" : "Update"}
                  </span>
                )}
              </Modal.Header>
              <Modal.Body>
                <Form className="m-2 ml-5 pl-5" onSubmit={formik.handleSubmit}>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formHorizontalUsername"
                  >
                    <Col sm={12}>
                      {parseInt(id) === 1 && (
                        <TextField
                          id="outlined-basic"
                          label="Type Name"
                          variant="outlined"
                          type="text"
                          size="small"
                          name="typeName"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.typeName}
                          error={
                            formik.touched.typeName && formik.errors.typeName
                          }
                          helperText={
                            formik.touched.typeName && formik.errors.typeName
                              ? `${formik.errors.typeName}`
                              : ""
                          }
                          style={{ width: "250px" }}
                        />
                      )}

                      {parseInt(id) === 3 && (
                        <TextField
                          id="outlined-basic"
                          label="Model Name"
                          variant="outlined"
                          type="text"
                          size="small"
                          name="modelName"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.modelName}
                          error={
                            formik.touched.modelName && formik.errors.modelName
                          }
                          helperText={
                            formik.touched.modelName && formik.errors.modelName
                              ? `${formik.errors.modelName}`
                              : ""
                          }
                          style={{ width: "250px" }}
                        />
                      )}
                      {parseInt(id) === 2 && (
                        <TextField
                          id="outlined-basic"
                          label="Unit Name"
                          variant="outlined"
                          type="text"
                          size="small"
                          name="unitName"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.unitName}
                          error={
                            formik.touched.unitName && formik.errors.unitName
                          }
                          helperText={
                            formik.touched.unitName && formik.errors.unitName
                              ? `${formik.errors.unitName}`
                              : ""
                          }
                          style={{ width: "250px" }}
                        />
                      )}

                      {parseInt(id) === 4 && (
                        <TextField
                          id="outlined-basic"
                          label="Tax Type"
                          variant="outlined"
                          type="text"
                          size="small"
                          name="taxType"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.taxType}
                          error={
                            formik.touched.taxType && formik.errors.taxType
                          }
                          helperText={
                            formik.touched.taxType && formik.errors.taxType
                              ? `${formik.errors.taxType}`
                              : ""
                          }
                          style={{ width: "250px" }}
                        />
                      )}
                    </Col>
                  </Form.Group>
                  {parseInt(id) === 4 && (
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formHorizontalUsername"
                    >
                      <Col sm={12}>
                        <TextField
                          id="outlined-basic"
                          label="Tax Value"
                          variant="outlined"
                          type="number"
                          name="taxValue"
                          size="small"
                          className="mr-3"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.taxValue}
                          error={
                            formik.touched.taxValue && formik.errors.taxValue
                          }
                          helperText={
                            formik.touched.taxValue && formik.errors.taxValue
                              ? `${formik.errors.taxValue}`
                              : ""
                          }
                          style={{ width: "200px", float: "left" }}
                        />
                        <h4 style={{ float: "left", marginTop: "7px" }}>%</h4>
                      </Col>
                    </Form.Group>
                  )}
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formHorizontalUsername"
                  >
                    <Col sm={12}>
                      <Button
                        style={{ width: "250px" }}
                        variant="outline-primary"
                        type="submit"
                      >
                        {formik.values.id === "" ? "Create" : "Update"}
                      </Button>
                    </Col>
                  </Form.Group>
                </Form>
              </Modal.Body>
            </Modal>
          </div>
        </div>
      </main>
    </div>
  );
};
export default UnitType;
