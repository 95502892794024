/** @format */

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Header from "../Header";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Box, TextField } from "@mui/material";
const PurchaseReport = () => {
  const [purchases, setPurchases] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [allPurchase, setAllPurchase] = useState([]);
  const [purcahseDate, setPurcahseDate] = useState({
    start: new Date(),
    end: new Date(),
  });
  const fetchAllData = async () => {
    const [purchase, supplier] = await Promise.all([
      axios.get("https://enin-server.ga.highendtradingplc.com/purchase_ref"),
      axios.get("https://enin-server.ga.highendtradingplc.com/supplier"),
    ]);
    setAllPurchase(purchase.data);
    setSuppliers(supplier.data);
    let data = [];
    purchase.data?.map((qua) => {
      const date = new Date(qua.date);
      if (
        (date.getFullYear() > purcahseDate.start.getFullYear() &&
          date.getFullYear() < purcahseDate.end.getFullYear()) ||
        (date.getFullYear() >= purcahseDate.start.getFullYear() &&
          date.getMonth() > purcahseDate.start.getMonth() &&
          date.getFullYear() <= purcahseDate.end.getFullYear() &&
          date.getMonth() < purcahseDate.end.getMonth()) ||
        (date.getFullYear() >= purcahseDate.start.getFullYear() &&
          date.getMonth() >= purcahseDate.start.getMonth() &&
          date.getDate() >= purcahseDate.start.getDate() &&
          date.getFullYear() <= purcahseDate.end.getFullYear() &&
          date.getMonth() <= purcahseDate.end.getMonth() &&
          date.getDate() <= purcahseDate.end.getDate())
      ) {
        data.push(qua);
      }
      setPurchases(data);
    });
  };
  useEffect(() => {
    fetchAllData();
  }, []);
  const columns = [
    {
      field: "Id",
      headerName: "#",
      width: 100,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "id",
      headerName: "PurchaseID",
      width: 100,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "fullName",
      headerName: "Supplier Name",
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "tinNumber",
      headerName: "Tin",
      width: 150,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "fsNumber",
      headerName: "FS-No",
      width: 150,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "mrcNumber",
      headerName: "MRC-No",
      width: 150,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "price",
      headerName: "Price",
      width: 150,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "date",
      headerName: "Date",
      width: 150,
      headerClassName: "super-app-theme--header",
      valueGetter: (params) =>
        `${new Date(params).getDate()}/${
          new Date(params).getMonth() + 1
        }/${new Date(params).getFullYear()}`,
    },
  ];

  const rows = purchases?.map((purchase, index) => {
    const supplier = suppliers.find((s) => s.Id === purchase.supplierId);

    return {
      Id: index + 1,
      id: purchase.Id,
      fullName: supplier.fullName,
      tinNumber: supplier.tinNumber,
      fsNumber: purchase.fsNumber,
      mrcNumber: purchase.mrcNumber,
      price: purchase.totalPrice,
      date: purchase.date,
    };
  });

  return (
    <div>
      <div>
        <Header />
      </div>
      <main style={{ marginTop: "120px" }}>
        <div
          className="tab-container "
          style={{
            marginTop: "-20px",
            marginBottom: "-5px",
          }}
        >
          <Link
            className="p-2"
            to={`/purchase_report`}
            style={{
              backgroundColor: "white",
              borderRadius: "5px 5px 0 0",
              border: "solid 0.05em rgb(181, 181, 181)",
            }}
          >
            Purchase Report
          </Link>
          <Link className="p-2" to={`/sales_report`}>
            Sales Report
          </Link>
          <Link className="p-2" to={`/stock_report`}>
            Stock Report
          </Link>
          <Link className="p-2" to={`/quantity_report`}>
            Quantity Report
          </Link>
          <Link className="p-2" to={`/login_history`}>
            Log History
          </Link>
        </div>
        <div className="card card-info card-outline">
          <div className="card-body">
            <div
              className="nav-bra pl-3 ml-1 p-2 "
              style={{
                backgroundColor: "rgb(235, 235, 235)",
              }}
            >
              <span>Home / Purchase Report</span>
            </div>
            <br />
            <div style={{ border: "solid 1px #ffb71b" }}></div>
            <br />
            <Form>
              <Form.Group as={Row}>
                <Col md={5} className="mb-2">
                  <Form.Label
                    style={{
                      margin: "7px 5px 0 0",
                    }}
                  >
                    From
                  </Form.Label>
                  <TextField
                    type="date"
                    size="small"
                    style={{ width: "200px" }}
                    onChange={(event) => {
                      if (
                        new Date() >= new Date(event.target.value) &&
                        new Date(purcahseDate.end) >=
                          new Date(event.target.value)
                      ) {
                        purcahseDate.start = new Date(event.target.value);
                      }
                    }}
                  />
                </Col>
                <Col md={5} className="mb-2 ">
                  <Form.Label
                    style={{
                      margin: "7px 27px 0 0",
                    }}
                  >
                    To
                  </Form.Label>
                  <TextField
                    size="small"
                    type="date"
                    style={{ width: "200px" }}
                    onChange={(event) => {
                      if (new Date() >= new Date(event.target.value)) {
                        purcahseDate.end = new Date(event.target.value);
                      }
                    }}
                  />
                </Col>
                <Col md={2}>
                  <center>
                    <Button
                      onClick={(e) => {
                        let data = [];
                        allPurchase?.map((qua) => {
                          const date = new Date(qua.date);
                          if (
                            (date.getFullYear() >
                              purcahseDate.start.getFullYear() &&
                              date.getFullYear() <
                                purcahseDate.end.getFullYear()) ||
                            (date.getFullYear() >=
                              purcahseDate.start.getFullYear() &&
                              date.getMonth() > purcahseDate.start.getMonth() &&
                              date.getFullYear() <=
                                purcahseDate.end.getFullYear() &&
                              date.getMonth() < purcahseDate.end.getMonth()) ||
                            (date.getFullYear() >=
                              purcahseDate.start.getFullYear() &&
                              date.getMonth() >=
                                purcahseDate.start.getMonth() &&
                              date.getDate() >= purcahseDate.start.getDate() &&
                              date.getFullYear() <=
                                purcahseDate.end.getFullYear() &&
                              date.getMonth() <= purcahseDate.end.getMonth() &&
                              date.getDate() <= purcahseDate.end.getDate())
                          ) {
                            data.push(qua);
                          }
                        });
                        setPurchases(data);
                      }}
                      variant="outline-info"
                    >
                      Search
                    </Button>
                  </center>
                </Col>
              </Form.Group>
            </Form>
            <hr />
            <Box
              sx={{
                height: 300,
                width: "100%",
                "& .super-app-theme--header": {
                  backgroundColor: "#1de5ec",
                  color: "white",
                },
              }}
            >
              <DataGrid
                rows={rows}
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                columns={columns}
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                  },
                }}
                pageSize={5}
                getRowId={(row) => row.Id}
              />
            </Box>
          </div>
        </div>
      </main>
    </div>
  );
};
export default PurchaseReport;
