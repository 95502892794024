/** @format */
import react, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faUsers,
  faCog,
  faSignOutAlt,
  faUserPlus,
  faBarcode,
  faBasketShopping,
  faFile,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import "./App.css";

const Header = () => {
  const userData = JSON.parse(window.sessionStorage.getItem("data")) || {
    Id: 0,
  };
  const navigate = useNavigate();
  const fetchAllData = () => {
    if (userData.Id === 0) {
      navigate(`/`);
    }
  };
  useEffect(() => {
    fetchAllData();
  }, []);
  return (
    <header className="bg-body-tertiary fixed-top">
      <div className="App-header-top row ">
        <div className="row" style={{ width: "100vw" }}>
          <div className="col-5">
            <div className="row">
              <div className="col text-right" style={{ overflow: "hidden" }}>
                <span style={{ fontSize: 18 }}>
                  Enin<span style={{ color: "#30bde4" }}>_</span>Tech
                  <span style={{ color: "#30bde4" }}>_</span>Inventory
                </span>
              </div>
              <div className="col-2 text-left">
                <Link to="/home" style={{ color: "white", fontSize: 14 }}>
                  Home
                </Link>
              </div>
            </div>
          </div>

          <div className="col-7">
            <div className="row">
              <Link
                to="/user_mgt"
                className="col text-right"
                style={{
                  color: "white",
                  fontSize: 14,
                }}
              >
                <FontAwesomeIcon icon={faCog} />
                Setting
              </Link>
              <Link
                className="col text-center"
                style={{ color: "white", fontSize: 14, overflow: "hidden" }}
              >
                Hello<span style={{ color: "#30bde4" }}>_</span>superadmin!
              </Link>
              <Link
                onClick={(e) => {
                  window.sessionStorage.clear();
                }}
                to="/"
                className="col text-left"
                style={{ color: "white", fontSize: 14 }}
              >
                <FontAwesomeIcon icon={faSignOutAlt} />
                Logout
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="App-header-bottom ">
        <nav className="navbar-menu">
          <div className="row">
            <Link
              className="col"
              to="/customers"
              style={
                window.location.pathname === "/customers"
                  ? { backgroundColor: "rgb(235,235,235)" }
                  : {}
              }
            >
              <center>
                <div
                  style={
                    window.location.pathname === "/customers"
                      ? { color: "black" }
                      : {}
                  }
                >
                  <div>
                    <FontAwesomeIcon icon={faUserPlus} size="2x" />
                  </div>
                  <div>Customers</div>
                </div>
              </center>
            </Link>
            <Link
              className="col"
              to="/supplier"
              style={
                window.location.pathname === "/supplier"
                  ? { backgroundColor: "rgb(235,235,235)" }
                  : {}
              }
            >
              <center>
                <div
                  style={
                    window.location.pathname === "/supplier"
                      ? { color: "black" }
                      : {}
                  }
                >
                  <div>
                    <FontAwesomeIcon size="2x" icon={faUser} />
                  </div>
                  <div>Supplier</div>
                </div>
              </center>
            </Link>
            <Link
              className="col"
              to="/inventory"
              style={
                window.location.pathname === "/inventory" ||
                window.location.pathname === "/inventory/types/4" ||
                window.location.pathname === "/inventory/types/3" ||
                window.location.pathname === "/inventory/types/2" ||
                window.location.pathname === "/inventory/types/1" ||
                window.location.pathname === "/purchase" ||
                window.location.pathname === "/quantity_on_hand" ||
                window.location.pathname === "/adjustment"
                  ? { backgroundColor: "rgb(235,235,235)" }
                  : {}
              }
            >
              <center>
                <div
                  style={
                    window.location.pathname === "/inventory" ||
                    window.location.pathname === "/inventory/types/4" ||
                    window.location.pathname === "/inventory/types/3" ||
                    window.location.pathname === "/inventory/types/2" ||
                    window.location.pathname === "/inventory/types/1" ||
                    window.location.pathname === "/purchase" ||
                    window.location.pathname === "/quantity_on_hand" ||
                    window.location.pathname === "/adjustment"
                      ? { color: "black" }
                      : {}
                  }
                >
                  <div>
                    <FontAwesomeIcon size="2x" icon={faBarcode} />
                  </div>
                  <div>Inventory</div>
                </div>
              </center>
            </Link>
            <Link
              className="col"
              to="/sales"
              style={
                window.location.pathname === "/sales" ||
                window.location.pathname === "/delivery" ||
                window.location.pathname === "/direct_delivery"
                  ? { backgroundColor: "rgb(235,235,235)" }
                  : {}
              }
            >
              <center>
                <div
                  style={
                    window.location.pathname === "/sales" ||
                    window.location.pathname === "/delivery" ||
                    window.location.pathname === "/direct_delivery"
                      ? { color: "black" }
                      : {}
                  }
                >
                  <div>
                    <FontAwesomeIcon size="2x" icon={faBasketShopping} />
                  </div>
                  <div>Sales</div>
                </div>
              </center>
            </Link>
            <Link
              className="col"
              to="/user_mgt"
              style={
                window.location.pathname === "/user_mgt" ||
                window.location.pathname === "/role_mgt" ||
                window.location.pathname === "/account_mgt"
                  ? { backgroundColor: "rgb(235,235,235)" }
                  : {}
              }
            >
              <center>
                <div
                  style={
                    window.location.pathname === "/user_mgt" ||
                    window.location.pathname === "/role_mgt" ||
                    window.location.pathname === "/account_mgt"
                      ? { color: "black" }
                      : {}
                  }
                >
                  <div>
                    <FontAwesomeIcon size="2x" icon={faUsers} />
                  </div>
                  <div>
                    User
                    <span
                      style={
                        window.location.pathname === "/user_mgt" ||
                        window.location.pathname === "/role_mgt" ||
                        window.location.pathname === "/account_mgt"
                          ? { color: "rgb(235,235,235)" }
                          : { color: "#1de5ec" }
                      }
                    >
                      _
                    </span>
                    Mgt
                  </div>
                </div>
              </center>
            </Link>
            <Link
              className="col"
              to="/purchase_report"
              style={
                window.location.pathname === "/purchase_report" ||
                window.location.pathname === "/sales_report" ||
                window.location.pathname === "/stock_report" ||
                window.location.pathname === "/login_history" ||
                window.location.pathname === "/quantity_report"
                  ? { backgroundColor: "rgb(235,235,235)" }
                  : {}
              }
            >
              <center>
                <div
                  style={
                    window.location.pathname === "/purchase_report" ||
                    window.location.pathname === "/sales_report" ||
                    window.location.pathname === "/stock_report" ||
                    window.location.pathname === "/login_history" ||
                    window.location.pathname === "/quantity_report"
                      ? { color: "black" }
                      : {}
                  }
                >
                  <div>
                    <FontAwesomeIcon size="2x" icon={faFile} />
                  </div>
                  <div>Report</div>
                </div>
              </center>
            </Link>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
