/** @format */
import React, { useEffect, useState } from "react";
import { Button, Modal, Form, Col, Row } from "react-bootstrap";
import { useFormik, Formik } from "formik";
import TextField from "@mui/material/TextField";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import Header from "../Header";
import { faL } from "@fortawesome/free-solid-svg-icons";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { Checkbox, MenuItem } from "@mui/material";
import axios from "axios";
import { faPenToSquare, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const InventoryPage = () => {
  const [open, setOpen] = useState(false);
  const [types, setTypes] = useState([]);
  const [units, setUnits] = useState([]);
  const [models, setModels] = useState([]);
  const [taxs, setTaxs] = useState([]);
  const [items, setItems] = useState([]);
  const [update, setUpdate] = useState(false);
  const [popup, setPopup] = useState(false);
  const [idvalue, setidvalue] = useState({
    idValue: 0,
  });
  const [handle, setHandle] = useState({
    item: true,
    createItem: false,
    addType: false,
    addModel: false,
    addUnit: false,
    addTax: false,
  });
  const handleAdd = () => {
    setOpen(true);
  };
  const handlePopup = () => {
    setPopup(false);
  };
  const handleOpen = () => {
    setOpen(false);
  };
  const handleItem = () => {
    setHandle((prev) => ({ item: true }));
  };
  const handleType = () => {
    setPopup(true);
    setHandle((prev) => ({ addType: true, createItem: true }));
  };
  const handleModel = () => {
    setPopup(true);
    setHandle((prev) => ({ addModel: true, createItem: true }));
  };
  const handleUnit = () => {
    setPopup(true);
    setHandle((prev) => ({ addUnit: true, createItem: true }));
  };
  const handleTax = () => {
    setPopup(true);
    setHandle((prev) => ({ addTax: true, createItem: true }));
  };
  const createItem = () => {
    setHandle((prev) => ({ createItem: true }));
  };
  const fetchAllData = async () => {
    try {
      const [type, unit, model, tax, item] = await Promise.all([
        axios.get("https://enin-server.ga.highendtradingplc.com/type"),
        axios.get("https://enin-server.ga.highendtradingplc.com/unit"),
        axios.get("https://enin-server.ga.highendtradingplc.com/model"),
        axios.get("https://enin-server.ga.highendtradingplc.com/tax"),
        axios.get("https://enin-server.ga.highendtradingplc.com/item"),
      ]);
      setTypes(type.data);
      setUnits(unit.data);
      setModels(model.data);
      setTaxs(tax.data);
      setItems(item.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchAllData();
  }, []);

  const validationSchema = Yup.object().shape({
    description: Yup.string().required("Name is required"),
    code: Yup.string().required("Code is required"),
    modelId: Yup.string().required("Model is required"),
    unitId: Yup.string().required("Unit is required"),
    statusId: Yup.string().required("Stock is required"),
    typeId: Yup.string().required("Type is required"),
  });
  const formik = useFormik({
    initialValues: {
      description: "",
      modelId: "",
      code: "",
      unitId: "",
      taxId: "",
      typeId: "",
      statusId: "",
      quantity: 0,
      id: "",
    },

    validationSchema,
    onSubmit: async (values) => {
      try {
        const { description } = values;
        console.log(values);
        const userExists = items.some(
          (user) => user.description === description
        );
        //
        if (userExists) {
          formik.setErrors({
            description: "Item already exists.",
          });
          return;
        }
        if (handle.createItem && !handle.addModel) {
          await axios.post(
            `https://enin-server.ga.highendtradingplc.com/item/add`,
            values
          );
          handle.createItem = false;
          handle.item = true;
        } else if (idvalue.idValue !== 0) {
          await axios
            .put(
              "https://enin-server.ga.highendtradingplc.com/item/update/" +
                values.id,
              values
            )
            .then((res) => console.log(res))
            .catch((err) => console.log(err));
        }
        fetchAllData();
        setOpen(false);
      } catch (error) {
        console.log(error);
      } finally {
        formik.values.modelId = "";
        formik.values.typeId = "";
        formik.values.unitId = "";
        formik.values.description = "";
        formik.values.code = "";
        formik.values.taxId = "";
        formik.values.statusId = "";
        formik.values.id = "";
      }
    },
  });
  const handleDelete = async (id) => {
    try {
      await axios.delete(
        `https://enin-server.ga.highendtradingplc.com/item/delete/${id}`
      );
      setItems(items.filter((item) => item.Id !== id));
    } catch (error) {
      console.log(error);
    }
  };
  const handleUpdate = async (id) => {
    const item = items.find((i) => i.Id === id);
    formik.values.modelId = item.modelId;
    formik.values.typeId = item.typeId;
    formik.values.unitId = item.unitId;
    formik.values.description = item.description;
    formik.values.code = item.code;
    formik.values.taxId = item.taxId;
    formik.values.statusId = item.statusId;
    formik.values.id = id;
    setidvalue(id);
    createItem();
  };
  const columnItem = [
    { field: "Id", headerName: "ID", width: 100 },
    { field: "description", headerName: "description", width: 200 },
    {
      field: "code",
      headerName: "Code",
      width: 150,
    },
    {
      field: "typeId",
      headerName: "Type",
      width: 150,
      valueGetter: (params) =>
        types.find((type) => type.Id === params)?.typeName || "",
    },
    {
      field: "modelId",
      headerName: "Model",
      width: 150,
      valueGetter: (params) =>
        models.find((model) => model.Id === params)?.modelName || "",
    },
    {
      field: "unitId",
      headerName: "Unit",
      width: 150,
      valueGetter: (params) =>
        units.find((unit) => unit.Id === params)?.unitName || "",
    },
    {
      field: "action",
      headerName: "Action",
      width: 220,
      renderCell: (params) => (
        <>
          <Button
            onClick={() => handleUpdate(params.row.Id)}
            variant="outline-warning"
            className="m-1"
          >
            <div
              className="row"
              style={{
                marginBottom: "-10px",
                marginLeft: "-10px",
              }}
            >
              <div className="col">
                <FontAwesomeIcon
                  style={{ float: "right", marginRight: "-7px" }}
                  icon={faPenToSquare}
                />
              </div>
              <div className="col-7">
                <h6
                  style={{
                    float: "left",
                    marginLeft: "-3px",
                  }}
                >
                  Edit
                </h6>
              </div>
            </div>
          </Button>
          <Button
            onClick={() => handleDelete(params.row.Id)}
            variant="outline-danger"
            className=""
          >
            <div
              className="row "
              style={{
                marginBottom: "-10px",
                marginLeft: "-10px",
              }}
            >
              <div className="col">
                <FontAwesomeIcon
                  style={{ float: "right", marginRight: "-5px" }}
                  icon={faTrashCan}
                />
              </div>
              <div className="col-8">
                <h6 style={{ float: "left", marginLeft: "-3px" }}>Delete</h6>
              </div>
            </div>
          </Button>
        </>
      ),
    },
  ];
  const style = {
    backgroundColor: "white",
    borderRadius: "5px 5px 0 0",
    border: "solid 0.05em rgb(181, 181, 181)",
  };
  return (
    <div>
      <div>
        <Header />
      </div>
      <main className="inventory-page" style={{ marginTop: "120px" }}>
        <div
          className="tab-container "
          style={{
            marginTop: "-20px",
            marginBottom: "-17px",
          }}
        >
          <Link
            className="p-2"
            to={`/inventory`}
            style={{
              backgroundColor: "white",
              borderRadius: "5px 5px 0 0",
              border: "solid 0.05em rgb(181, 181, 181)",
            }}
          >
            Item
          </Link>
          <Link className="p-2" to={`/purchase`}>
            Purchase
          </Link>
          <Link className="p-2" to={`/adjustment`}>
            Adjustment
          </Link>
          <Link className="p-2" to={`/quantity_on_hand/${1}`}>
            Qty On Hand
          </Link>
        </div>
        <hr />
        <div
          className="sub-tab-container"
          style={
            handle.addModel || handle.addTax || handle.addType || handle.addUnit
              ? { marginBottom: "0" }
              : {}
          }
        >
          <Link className="pr-2 pl-2" to={`/inventory/types/${1}`}>
            Type
          </Link>
          <Link className="pr-2 pl-2" to={`/inventory/types/${3}`}>
            Model
          </Link>
          <Link className="pr-2 pl-2" to={`/inventory/types/${2}`}>
            Unit
          </Link>
          <Link className="pr-2 pl-2" to={`/inventory/types/${4}`}>
            Tax Type
          </Link>
        </div>

        <div className="card card-info card-outline">
          <div className="card-header">
            {!handle.createItem && (
              <Button
                variant="outline-info"
                style={{ width: "150px", height: "35px" }}
                onClick={createItem}
              >
                <h3
                  style={{
                    float: "left",
                    marginTop: "-6px",
                    marginRight: "5px",
                  }}
                >
                  +
                </h3>
                <h6 style={{ float: "left", marginTop: "1px" }}> Create New</h6>
              </Button>
            )}
            {handle.createItem && (
              <div>
                <h5 style={{ float: "left" }}>
                  {formik.values.id === "" ? "Create New" : "Update"} Item
                </h5>
                <Button
                  onClick={handleItem}
                  style={{ float: "right", height: "30px", width: "30px" }}
                  variant="outline-danger"
                >
                  <h3
                    style={{
                      float: "left",
                      marginTop: "-8px",
                      marginLeft: "-7px",
                    }}
                  >
                    X
                  </h3>
                </Button>
              </div>
            )}
          </div>
          <div className="card-body">
            {handle.createItem && (
              <div>
                <div
                  className="nav-bra pl-3 ml-1 p-2"
                  style={{
                    backgroundColor: "rgb(235, 235, 235)",
                  }}
                >
                  <span>Home / Item</span>
                </div>
                <hr />
              </div>
            )}
            {handle.item && (
              <div>
                <div
                  className="nav-bra pl-3 ml-1 p-2"
                  style={{
                    backgroundColor: "rgb(235, 235, 235)",
                  }}
                >
                  <span>Item / Home</span>
                </div>
                <hr />
              </div>
            )}
            {handle.item && (
              <DataGrid
                rows={items}
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                columns={columnItem}
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                  },
                }}
                initialState={{
                  ...items.initialState,
                  pagination: {
                    paginationModel: { pageSize: 5 },
                  },
                }}
                pageSizeOptions={[5, 10, 15]}
                getRowId={(row) => row.Id}
              />
            )}
            {handle.createItem && (
              <div>
                <center>
                  <div>
                    <Form
                      lassName="m-2 ml-5 pl-5"
                      onSubmit={formik.handleSubmit}
                    >
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formHorizontalUsername"
                      >
                        <Col sm={12}>
                          <TextField
                            id="outlined-basic"
                            label="description"
                            variant="outlined"
                            type="text"
                            size="small"
                            name="description"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.description}
                            error={
                              formik.touched.description &&
                              formik.errors.description
                            }
                            helperText={
                              formik.touched.description &&
                              formik.errors.description
                                ? `${formik.errors.description}`
                                : ""
                            }
                            style={{ width: "300px" }}
                          />
                        </Col>
                      </Form.Group>

                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formHorizontalUsername"
                      >
                        <Col sm={12}>
                          <TextField
                            id="outlined-basic"
                            label="Select Type"
                            variant="outlined"
                            select
                            size="small"
                            name="typeId"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.typeId}
                            error={
                              formik.touched.typeId && formik.errors.typeId
                            }
                            helperText={
                              formik.touched.typeId && formik.errors.typeId
                                ? `${formik.errors.typeId}`
                                : ""
                            }
                            style={{ width: "250px" }}
                          >
                            <MenuItem key={null} value={null}>
                              None
                            </MenuItem>
                            {types.map((typ) => {
                              return (
                                <MenuItem key={typ.Id} value={typ.Id}>
                                  {typ.typeName}
                                </MenuItem>
                              );
                            })}
                          </TextField>
                          <Button
                            variant="outline-info"
                            //onClick={openAddModal}
                            style={{
                              width: "30px",
                              height: "40px",
                              marginLeft: "20px",
                            }}
                            onClick={handleType}
                          >
                            <h2
                              style={{
                                marginTop: "-5px",
                                marginLeft: "-7px",
                              }}
                            >
                              +
                            </h2>
                          </Button>
                        </Col>
                      </Form.Group>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formHorizontalUsername"
                      >
                        <Col sm={12}>
                          <TextField
                            id="outlined-basic"
                            label="Select Model"
                            variant="outlined"
                            select
                            size="small"
                            name="modelId"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.modelId}
                            error={
                              formik.touched.modelId && formik.errors.modelId
                            }
                            helperText={
                              formik.touched.modelId && formik.errors.modelId
                                ? `${formik.errors.modelId}`
                                : ""
                            }
                            style={{ width: "250px" }}
                          >
                            <MenuItem key={null} value={null}>
                              None
                            </MenuItem>
                            {models.map((model) => {
                              return (
                                <MenuItem key={model.Id} value={model.Id}>
                                  {model.modelName}
                                </MenuItem>
                              );
                            })}
                          </TextField>
                          <Button
                            variant="outline-info"
                            //onClick={openAddModal}
                            style={{
                              width: "30px",
                              height: "40px",
                              marginLeft: "20px",
                            }}
                            onClick={handleModel}
                          >
                            <h2
                              style={{
                                marginTop: "-5px",
                                marginLeft: "-7px",
                              }}
                            >
                              +
                            </h2>
                          </Button>
                        </Col>
                      </Form.Group>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formHorizontalUsername"
                      >
                        <Col sm={12} style={{ width: "400px" }}>
                          <TextField
                            id="outlined-basic"
                            label="Code"
                            variant="outlined"
                            type="text"
                            name="code"
                            size="small"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.code}
                            error={formik.touched.code && formik.errors.code}
                            helperText={
                              formik.touched.code && formik.errors.code
                                ? `${formik.errors.code}`
                                : ""
                            }
                            style={{ width: "300px" }}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formHorizontalUsername"
                      >
                        <Col sm={12}>
                          <TextField
                            id="outlined-basic"
                            label="Select Unit"
                            variant="outlined"
                            select
                            size="small"
                            name="unitId"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.unitId}
                            error={
                              formik.touched.unitId && formik.errors.unitId
                            }
                            helperText={
                              formik.touched.unitId && formik.errors.unitId
                                ? `${formik.errors.unitId}`
                                : ""
                            }
                            style={{ width: "250px" }}
                          >
                            <MenuItem key={null} value={null}>
                              None
                            </MenuItem>
                            {units.map((unit) => {
                              return (
                                <MenuItem key={unit.Id} value={unit.Id}>
                                  {unit.unitName}
                                </MenuItem>
                              );
                            })}
                          </TextField>
                          <Button
                            variant="outline-info"
                            //onClick={openAddModal}
                            style={{
                              width: "30px",
                              height: "40px",
                              marginLeft: "20px",
                            }}
                            onClick={handleUnit}
                          >
                            <h2
                              style={{
                                marginTop: "-5px",
                                marginLeft: "-7px",
                              }}
                            >
                              +
                            </h2>
                          </Button>
                        </Col>
                      </Form.Group>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formHorizontalUsername"
                      >
                        <Col sm={12}>
                          <TextField
                            id="outlined-basic"
                            label="Select Tax"
                            variant="outlined"
                            select
                            size="small"
                            name="taxId"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.taxId}
                            error={formik.touched.taxId && formik.errors.taxId}
                            helperText={
                              formik.touched.taxId && formik.errors.taxId
                                ? `${formik.errors.taxId}`
                                : ""
                            }
                            style={{ width: "250px" }}
                          >
                            <MenuItem key={null} value={null}>
                              None
                            </MenuItem>
                            {taxs.map((tax) => {
                              return (
                                <MenuItem key={tax.Id} value={tax.Id}>
                                  {tax.taxType}
                                </MenuItem>
                              );
                            })}
                          </TextField>
                          <Button
                            variant="outline-info"
                            //onClick={openAddModal}
                            style={{
                              width: "30px",
                              height: "40px",
                              marginLeft: "20px",
                            }}
                            onClick={handleTax}
                          >
                            <h2
                              style={{
                                marginTop: "-5px",
                                marginLeft: "-7px",
                              }}
                            >
                              +
                            </h2>
                          </Button>
                        </Col>
                      </Form.Group>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formHorizontalUsername"
                      >
                        <Col sm={12}>
                          <TextField
                            id="outlined-basic"
                            label="Select Item Type"
                            variant="outlined"
                            select
                            size="small"
                            name="statusId"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.statusId}
                            error={
                              formik.touched.statusId && formik.errors.statusId
                            }
                            helperText={
                              formik.touched.statusId && formik.errors.statusId
                                ? `${formik.errors.statusId}`
                                : ""
                            }
                            style={{ width: "300px" }}
                          >
                            <MenuItem key={null} value={null}>
                              None
                            </MenuItem>
                            <MenuItem key={1} value={1}>
                              Service
                            </MenuItem>
                            <MenuItem key={2} value={2}>
                              Consumable
                            </MenuItem>
                          </TextField>
                        </Col>
                      </Form.Group>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formHorizontalUsername"
                      >
                        <Col sm={12}>
                          <Button
                            style={{ width: "300px" }}
                            variant="outline-primary"
                            type="submit"
                          >
                            Create
                          </Button>
                        </Col>
                      </Form.Group>
                    </Form>
                  </div>
                </center>
              </div>
            )}
          </div>
        </div>

        <Modal show={popup} onHide={handlePopup} style={{ minWidth: "700px" }}>
          <Modal.Body>
            {handle.addType && (
              <Formik
                initialValues={{
                  typeName: "",
                }}
                validationSchema={Yup.object().shape({
                  typeName: Yup.string()
                    .required("Type is required")
                    .min(2, "Too Short!!!")
                    .max(20, "Too High!!!"),
                })}
                onSubmit={async (values) => {
                  console.log(values);
                  try {
                    await axios.post(
                      `https://enin-server.ga.highendtradingplc.com/type/add`,
                      values
                    );

                    setPopup(false);
                  } catch (error) {
                    console.log(error);
                  } finally {
                    fetchAllData();
                  }
                }}
              >
                {(props) => (
                  <Form className="m-2 ml-5 pl-5" onSubmit={props.handleSubmit}>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formHorizontalUsername"
                    >
                      <Col sm={12}>
                        <TextField
                          id="outlined-basic"
                          label="Type Name"
                          variant="outlined"
                          type="text"
                          name="typeName"
                          size="small"
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          value={props.values.typeName}
                          error={
                            props.touched.typeName && props.errors.typeName
                          }
                          helperText={
                            props.touched.typeName && props.errors.typeName
                              ? `${props.errors.typeName}`
                              : ""
                          }
                          style={{ maxWidth: "300px" }}
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formHorizontalUsername"
                    >
                      <Col sm={12}>
                        <Button
                          style={{ width: "62%" }}
                          variant="outline-primary"
                          type="submit"
                        >
                          Create
                        </Button>
                      </Col>
                    </Form.Group>
                  </Form>
                )}
              </Formik>
            )}
            {handle.addModel && (
              <Formik
                initialValues={{
                  modelName: "",
                }}
                validationSchema={Yup.object().shape({
                  modelName: Yup.string()
                    .required("Model is required")
                    .min(2, "Too Short!!!")
                    .max(20, "Too High!!!"),
                })}
                onSubmit={async (values) => {
                  console.log(values);
                  try {
                    await axios.post(
                      `https://enin-server.ga.highendtradingplc.com/model/add`,
                      values
                    );
                    setPopup(false);
                  } catch (error) {
                    console.log(error);
                  } finally {
                    fetchAllData();
                  }
                }}
              >
                {(props) => (
                  <Form className="m-2 ml-5 pl-5" onSubmit={props.handleSubmit}>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formHorizontalUsername"
                    >
                      <Col sm={12}>
                        <TextField
                          id="outlined-basic"
                          label="Model Name"
                          variant="outlined"
                          type="text"
                          name="modelName"
                          size="small"
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          value={props.values.modelName}
                          error={
                            props.touched.modelName && props.errors.modelName
                          }
                          helperText={
                            props.touched.modelName && props.errors.modelName
                              ? `${props.errors.modelName}`
                              : ""
                          }
                          style={{ maxWidth: "300px" }}
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formHorizontalUsername"
                    >
                      <Col sm={12}>
                        <Button
                          style={{ width: "62%" }}
                          variant="outline-primary"
                          type="submit"
                        >
                          Create
                        </Button>
                      </Col>
                    </Form.Group>
                  </Form>
                )}
              </Formik>
            )}
            {handle.addUnit && (
              <Formik
                initialValues={{
                  unitName: "",
                }}
                validationSchema={Yup.object().shape({
                  unitName: Yup.string()
                    .required("Unit is required")
                    .min(2, "Too Short!!!")
                    .max(20, "Too High!!!"),
                })}
                onSubmit={async (values) => {
                  console.log(values);
                  try {
                    await axios.post(
                      `https://enin-server.ga.highendtradingplc.com/unit/add`,
                      values
                    );
                    setPopup(false);
                  } catch (error) {
                    console.log(error);
                  } finally {
                    fetchAllData();
                  }
                }}
              >
                {(props) => (
                  <Form className="m-2 ml-5 pl-5" onSubmit={props.handleSubmit}>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formHorizontalUsername"
                    >
                      <Col sm={12}>
                        <TextField
                          id="outlined-basic"
                          label="Unit Name"
                          variant="outlined"
                          type="text"
                          name="unitName"
                          size="small"
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          value={props.values.unitName}
                          error={
                            props.touched.unitName && props.errors.unitName
                          }
                          helperText={
                            props.touched.unitName && props.errors.unitName
                              ? `${props.errors.unitName}`
                              : ""
                          }
                          style={{ maxWidth: "300px" }}
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formHorizontalUsername"
                    >
                      <Col sm={12}>
                        <Button
                          style={{ width: "62%" }}
                          variant="outline-primary"
                          type="submit"
                        >
                          Create
                        </Button>
                      </Col>
                    </Form.Group>
                  </Form>
                )}
              </Formik>
            )}
            {handle.addTax && (
              <Formik
                initialValues={{
                  taxValue: "",
                  taxType: "",
                }}
                validationSchema={Yup.object().shape({
                  taxValue: Yup.number()
                    .required("Tax value is required")
                    .min(0, "Too low!!!")
                    .max(50, "Too High!!!"),
                  taxType: Yup.string()
                    .required("Tax type is required")
                    .min(3, "Too Short!!!")
                    .max(30, "Too Long!!!"),
                })}
                onSubmit={async (values) => {
                  console.log(values);
                  try {
                    await axios.post(
                      `https://enin-server.ga.highendtradingplc.com/tax/add`,
                      values
                    );
                    setPopup(false);
                  } catch (error) {
                    console.log(error);
                  } finally {
                    fetchAllData();
                  }
                }}
              >
                {(props) => (
                  <Form className="m-2 ml-5 pl-5" onSubmit={props.handleSubmit}>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formHorizontalUsername"
                    >
                      <Col sm={12}>
                        <TextField
                          id="outlined-basic"
                          label="Tax Type"
                          variant="outlined"
                          type="text"
                          name="taxType"
                          size="small"
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          value={props.values.taxType}
                          error={props.touched.taxType && props.errors.taxType}
                          helperText={
                            props.touched.taxType && props.errors.taxType
                              ? `${props.errors.taxType}`
                              : ""
                          }
                          style={{ width: "250px" }}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formHorizontalUsername"
                    >
                      <Col sm={12}>
                        <TextField
                          id="outlined-basic"
                          label="Tax Value"
                          variant="outlined"
                          type="number"
                          name="taxValue"
                          size="small"
                          className="mr-3"
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          value={props.values.taxValue}
                          error={
                            props.touched.taxValue && props.errors.taxValue
                          }
                          helperText={
                            props.touched.taxValue && props.errors.taxValue
                              ? `${props.errors.taxValue}`
                              : ""
                          }
                          style={{ width: "200px", float: "left" }}
                        />
                        <h4 style={{ float: "left", marginTop: "7px" }}>%</h4>
                      </Col>
                    </Form.Group>

                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formHorizontalUsername"
                    >
                      <Col sm={12}>
                        <Button
                          style={{ width: "250px" }}
                          variant="outline-primary"
                          type="submit"
                        >
                          Create
                        </Button>
                      </Col>
                    </Form.Group>
                  </Form>
                )}
              </Formik>
            )}
          </Modal.Body>
        </Modal>
      </main>
    </div>
  );
};

export default InventoryPage;
