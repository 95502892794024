/** @format */

import React, { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { faFloppyDisk, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { Button, Form, Modal, Col, Row, Table } from "react-bootstrap";
import axios from "axios";
import Header from "../Header";
import ReactToPrint from "react-to-print";
import { Box, TextField, Container } from "@mui/material";
import { Link } from "react-router-dom";
const Delivery = () => {
  const [customers, setCustomers] = useState([]);
  const [salesRefs, setSalesRefs] = useState([]);
  const [deliverys, setDeliverys] = useState([]);
  const [open, setOpen] = useState(false);
  const [items, setItems] = useState([]);
  const [models, setModels] = useState([]);
  const [units, setUnits] = useState([]);
  const [sales, setSales] = useState([]);
  const [salesValue, setSalesValue] = useState([]);
  const [deliveryRefs, setDeliveryRefs] = useState([]);
  const [taxs, setTaxs] = useState([]);
  const [single, setSingle] = useState(false);
  const [newQuantity, setNewQuantity] = useState({
    quantity: "",
  });
  const [newDelivery, setNewDelivery] = useState([]);
  const [salesRefId, setSalesRefId] = useState({
    Id: "",
    customerId: "",
  });
  const [price, setPrice] = useState({
    total: 0,
    vat: 0,
    withHold: 0,
    grandTotal: 0,
  });
  const [printView, setPrintView] = useState(false);
  const componentRef = useRef();
  const fetchAllData = async () => {
    const [
      customer,
      salesRef,
      delivery,
      item,
      model,
      unit,
      sale,
      delivery_ref,
      tax,
    ] = await Promise.all([
      axios.get("https://enin-server.ga.highendtradingplc.com/customer"),
      axios.get("https://enin-server.ga.highendtradingplc.com/sales_ref"),
      axios.get("https://enin-server.ga.highendtradingplc.com/delivery"),
      axios.get("https://enin-server.ga.highendtradingplc.com/item"),
      axios.get("https://enin-server.ga.highendtradingplc.com/model"),
      axios.get("https://enin-server.ga.highendtradingplc.com/unit"),
      axios.get("https://enin-server.ga.highendtradingplc.com/sales"),
      axios.get("https://enin-server.ga.highendtradingplc.com/delivery_ref"),
      axios.get("https://enin-server.ga.highendtradingplc.com/tax"),
    ]);
    setCustomers(customer.data);
    setSalesRefs(salesRef.data?.filter((s) => s.statusId === 2));
    setDeliverys(delivery.data);
    setItems(item.data);
    setModels(model.data);
    setUnits(unit.data);
    setSales(sale.data);
    setDeliveryRefs(delivery_ref.data);
    setTaxs(tax.data);
  };
  useEffect(() => {
    fetchAllData();
  }, []);
  const handleOpen = () => {
    setOpen(false);
  };
  const handleSubmitOne = async (id) => {
    try {
      let sale = newDelivery?.find((s) => s.Id === id);
      const newDeliveryRef = deliveryRefs.find(
        (d) => d.saleRefId === parseInt(salesRefId.Id)
      );

      let delivery = deliverys.find(
        (d) => d.deliveryRefId === newDeliveryRef.Id && sale.itemId === d.itemId
      );
      if (delivery.quantity + parseInt(sale.price) > sale.quantity) {
        sale.price = sale.quantity - delivery.quantity;
      }
      let newId = salesRefId.Id + "";
      if (newId.length === 1) {
        newId = "S-000" + newId;
      } else if (newId.length === 2) {
        newId = "S-00" + newId;
      } else if (newId.length === 3) {
        newId = "S-0" + newId;
      } else {
        newId = "S-" + newId;
      }
      let newItem = items.find((i) => i.Id === sale.itemId);
      newItem.physicalQuantity =
        parseInt(newItem.physicalQuantity) - parseInt(sale.price);
      await axios.put(
        "https://enin-server.ga.highendtradingplc.com/item/update/" +
          newItem.Id,
        newItem
      );
      await axios.post(
        "https://enin-server.ga.highendtradingplc.com/deliveryquantity/add",
        {
          itemId: newItem.Id,
          deliveryPurchaseId: newId,
          quantity: newItem.physicalQuantity,
        }
      );
      await axios.put(
        "https://enin-server.ga.highendtradingplc.com/delivery/update/" +
          delivery.Id,
        {
          itemId: newItem.Id,
          deliveryRefId: delivery.deliveryRefId,
          price: delivery.price,
          quantity: delivery.quantity + parseInt(sale.price),
        }
      );
      if (
        newDelivery.length === 1 &&
        parseInt(sale.price) + parseInt(delivery.quantity) ===
          parseInt(sale.quantity)
      ) {
        let salesRef = salesRefs.find((s) => s.Id === salesRefId.Id);
        salesRef.statusId = 1;
        await axios.put(
          "https://enin-server.ga.highendtradingplc.com/sales_ref/update/" +
            salesRef.Id,
          salesRef
        );
      }
      alert("Data Submit Successfully !");
    } catch (error) {
      console.log(error);
    } finally {
      fetchAllData();
      setOpen(false);
      setSingle(false);
    }
  };
  const handleSubmitAll = async () => {
    setPrintView(true);

    try {
      newDelivery.map(async (sale) => {
        const newDeliveryRef = deliveryRefs.find(
          (d) => d.saleRefId === parseInt(salesRefId.Id)
        );
        let delivery = deliverys.find(
          (d) =>
            d.deliveryRefId === newDeliveryRef.Id && sale.itemId === d.itemId
        );
        let newId = salesRefId.Id + "";
        if (newId.length === 1) {
          newId = "S-000" + newId;
        } else if (newId.length === 2) {
          newId = "S-00" + newId;
        } else if (newId.length === 3) {
          newId = "S-0" + newId;
        } else {
          newId = "S-" + newId;
        }
        let newItem = items.find((i) => i.Id === sale.itemId);
        newItem.physicalQuantity =
          parseInt(newItem.physicalQuantity) -
          (parseInt(sale.quantity) - parseInt(delivery.quantity));
        await axios.put(
          "https://enin-server.ga.highendtradingplc.com/item/update/" +
            newItem.Id,
          newItem
        );
        await axios.post(
          "https://enin-server.ga.highendtradingplc.com/deliveryquantity/add",
          {
            itemId: newItem.Id,
            deliveryPurchaseId: newId,
            quantity: newItem.physicalQuantity,
          }
        );
        await axios.put(
          "https://enin-server.ga.highendtradingplc.com/delivery/update/" +
            delivery.Id,
          {
            itemId: newItem.Id,
            deliveryRefId: delivery.deliveryRefId,
            price: delivery.price,
            quantity:
              delivery.quantity +
              (parseInt(sale.quantity) - parseInt(delivery.quantity)),
          }
        );
      });
      let salesRef = salesRefs.find((s) => s.Id === salesRefId.Id);
      salesRef.statusId = 1;
      await axios.put(
        "https://enin-server.ga.highendtradingplc.com/sales_ref/update/" +
          salesRef.Id,
        salesRef
      );
      alert("Data Submit Successfully !");
    } catch (error) {
      console.log(error);
    } finally {
      fetchAllData();
    }
  };
  const columns = [
    {
      field: "Id",
      headerClassName: "super-app-theme--header",
      headerName: "#",
      width: 100,
    },
    {
      field: "id",
      headerClassName: "super-app-theme--header",
      headerName: "SalesID",
      width: 100,
    },
    {
      field: "fullName",
      headerClassName: "super-app-theme--header",
      headerName: "Customer Name",
      width: 200,
    },

    {
      field: "fsNumber",
      headerClassName: "super-app-theme--header",
      headerName: "Sales",
      width: 120,
    },
    {
      field: "price",
      headerClassName: "super-app-theme--header",
      headerName: "Price",
      width: 150,
    },
    {
      field: "date",
      headerClassName: "super-app-theme--header",
      headerName: "Date",
      width: 120,
      valueGetter: (params) =>
        `${new Date(params).getDate()}/${
          new Date(params).getMonth() + 1
        }/${new Date(params).getFullYear()}`,
    },
    {
      field: "action",
      headerName: "Action",
      Align: "center",
      headerAlign: "center",
      width: 300,
      renderCell: (params) => (
        <>
          <Button
            onClick={(e) => {
              salesRefId.Id = params.row.id;
              const newDeliveryRef = deliveryRefs.find(
                (d) => d.saleRefId === parseInt(params.row.id)
              );

              setNewDelivery(
                sales.filter((d) => {
                  const item = items.find((i) => i.Id === d.itemId);
                  let delivery = deliverys.find(
                    (da) =>
                      da.deliveryRefId === newDeliveryRef.Id &&
                      d.itemId === da.itemId
                  );
                  return (
                    d.saleRefId === params.row.id &&
                    item.statusId === 2 &&
                    delivery.quantity !== d.quantity
                  );
                })
              );
              setSingle(true);
              setOpen(false);
            }}
            variant="outline-warning"
            className="m-1"
          >
            <div
              className="row "
              style={{
                marginBottom: "-10px",
                marginLeft: "-10px",
              }}
            >
              <div className="col">
                <FontAwesomeIcon
                  style={{ float: "right" }}
                  icon={faPenToSquare}
                />
              </div>
              <div className="col-6">
                <h6
                  style={{
                    float: "left",
                    marginLeft: "-10px",
                  }}
                >
                  Edit
                </h6>
              </div>
            </div>
          </Button>
          <Button
            onClick={(e) => {
              price.total = price.vat = price.withHold = price.grandTotal = 0;
              salesRefId.Id = params.row.id;
              salesRefId.customerId = salesRefs.find(
                (s) => s.Id === params.row.id
              )?.customerId;
              const newDeliveryRef = deliveryRefs.find(
                (d) => d.saleRefId === parseInt(params.row.id)
              );
              setNewDelivery(
                sales.filter((d) => {
                  const item = items.find((i) => i.Id === d.itemId);
                  let delivery = deliverys.find(
                    (da) =>
                      da.deliveryRefId === newDeliveryRef.Id &&
                      d.itemId === da.itemId
                  );
                  return (
                    d.saleRefId === params.row.id &&
                    item.statusId === 2 &&
                    delivery.quantity !== d.quantity
                  );
                })
              );
              const newSales = sales.filter((d) => {
                return d.saleRefId === params.row.id;
              });
              newSales.map((s) => {
                const item = items.find((i) => i.Id === s.itemId);
                const tax = taxs.find((t) => t.Id === item.taxId);
                price.total += parseInt(s.price) * parseInt(s.quantity);
                price.vat +=
                  parseFloat(s.price) *
                  parseFloat(s.quantity) *
                  (parseFloat(tax.taxValue) / 100);
                price.withHold +=
                  item.statusId === 1 && parseInt(s.price) >= 3000
                    ? parseFloat(s.price) * parseFloat(s.quantity) * 0.02
                    : item.statusId === 2 && parseInt(s.price) >= 10000
                    ? parseFloat(s.price) * parseFloat(s.quantity) * 0.02
                    : 0;
              });
              price.grandTotal = price.total + price.vat + price.withHold;
              setSalesValue(newSales);
              setOpen(true);
              setSingle(false);
            }}
            variant="outline-primary"
            className="m-1"
          >
            <div
              className="row "
              style={{
                marginBottom: "-10px",
                marginLeft: "-20px",
              }}
            >
              <div className="col">
                <FontAwesomeIcon
                  style={{ float: "right" }}
                  className="fa-regular"
                  icon={faFloppyDisk}
                />
              </div>
              <div className="col-7">
                <h6
                  style={{
                    float: "left",
                    marginLeft: "-10px",
                    marginRight: "15px",
                  }}
                >
                  Delivered
                </h6>
              </div>
            </div>
          </Button>
          <Button
            onClick={(e) => {
              price.total = price.vat = price.withHold = price.grandTotal = 0;
              setNewDelivery(
                sales.filter((d) => {
                  salesRefId.Id = params.row.id;
                  salesRefId.customerId = salesRefs.find(
                    (s) => s.Id === params.row.id
                  )?.customerId;
                  const newDeliveryRef = deliveryRefs.find(
                    (d) => d.saleRefId === parseInt(params.row.id)
                  );
                  const item = items.find((i) => i.Id === d.itemId);
                  let delivery = deliverys.find(
                    (da) =>
                      da.deliveryRefId === newDeliveryRef.Id &&
                      d.itemId === da.itemId
                  );
                  return (
                    d.saleRefId === params.row.id &&
                    item.statusId === 2 &&
                    delivery.quantity !== d.quantity
                  );
                })
              );
              const newSales = sales.filter((d) => {
                return d.saleRefId === params.row.id;
              });

              newSales.map((s) => {
                const item = items.find((i) => i.Id === s.itemId);
                const tax = taxs.find((t) => t.Id === item.taxId);
                price.total += parseInt(s.price) * parseInt(s.quantity);
                price.vat +=
                  parseFloat(s.price) *
                  parseFloat(s.quantity) *
                  (parseFloat(tax.taxValue) / 100);
                price.withHold +=
                  item.statusId === 1 && parseInt(s.price) >= 3000
                    ? parseFloat(s.price) * parseFloat(s.quantity) * 0.02
                    : item.statusId === 2 && parseInt(s.price) >= 10000
                    ? parseFloat(s.price) * parseFloat(s.quantity) * 0.02
                    : 0;
              });
              price.grandTotal = price.total + price.vat + price.withHold;
              setSalesValue(newSales);
              setPrintView(true);
            }}
            variant="outline-info"
            className="m-1"
          >
            <div
              className="row "
              style={{
                marginBottom: "-10px",
              }}
            >
              <div className="col-6">
                <h6>Print</h6>
              </div>
            </div>
          </Button>
        </>
      ),
    },
  ];
  const rows = salesRefs?.map((sale, index) => {
    const customer = customers.find((s) => s.Id === sale.customerId);
    const count = sales
      .map((s) => {
        let result = 0;
        if (s.saleRefId === sale.Id) {
          const item = items.find((i) => i.Id === s.itemId && i.statusId === 2);
          if (!!item) {
            result = 1;
          }
        }
        return result;
      })
      .reduce((acc, crr) => acc + crr, 0);
    return {
      Id: index + 1,
      id: sale?.Id,
      fullName: customer?.fullName,
      fsNumber: count,
      price: sale?.totalPrice,
      date: sale?.date,
    };
  });

  return (
    <div>
      <Header />
      <main style={{ marginTop: "120px" }}>
        <div
          className="tab-container "
          style={{
            marginTop: "-20px",
            marginBottom: "-10px",
          }}
        >
          <Link className="p-2" to={`/sales`}>
            New Sales
          </Link>
          <Link
            className="p-2"
            style={{
              backgroundColor: "white",
              borderRadius: "5px 5px 0 0",
              border: "solid 0.05em rgb(181, 181, 181)",
            }}
            to={`/delivery`}
          >
            Sales Delivery
          </Link>
          <Link className="p-2" to={`/direct_delivery`}>
            Direct Delivery
          </Link>
        </div>
        <div className="card card-outline card-info">
          <div className="card-header">
            <h4 style={{ float: "left" }}>Delivery</h4>
            {single && !open && (
              <Button
                onClick={(e) => {
                  setSingle(false);
                }}
                style={{ float: "right", height: "30px", width: "30px" }}
                variant="outline-danger"
              >
                <h3
                  style={{
                    float: "left",
                    marginTop: "-8px",
                    marginLeft: "-7px",
                  }}
                >
                  X
                </h3>
              </Button>
            )}
          </div>
          <div className="card-body">
            <div
              className="nav-bra pl-3 ml-1 p-2 "
              style={{
                backgroundColor: "rgb(235, 235, 235)",
              }}
            >
              <span>
                Home / Sales Delivery{" "}
                {single && !open && <>/ Delivered Singl Item</>}
              </span>
            </div>
            <hr />
            <div>
              <Box
                sx={{
                  height: 500,
                  width: "100%",
                }}
              >
                {!single && !open ? (
                  <DataGrid
                    rows={rows}
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    columns={columns}
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                      toolbar: {
                        showQuickFilter: true,
                      },
                    }}
                    pageSize={5}
                    getRowId={(row) => row.Id}
                  />
                ) : (
                  <div style={{ overflow: "scroll" }}>
                    <table className="summary-table">
                      <thead className="bg-info">
                        <tr>
                          <th>Id</th>
                          <th>Code</th>
                          <th>Desc</th>
                          <th>Model</th>
                          <th>Unit</th>
                          <th>Qty</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {newDelivery?.map((deliver, index) => {
                          const newDeliveryRef = deliveryRefs.find(
                            (d) => d.saleRefId === parseInt(salesRefId.Id)
                          );
                          const delivery = deliverys.find(
                            (d) =>
                              d.deliveryRefId === newDeliveryRef?.Id &&
                              deliver.itemId === d.itemId
                          );
                          const count =
                            parseInt(deliver?.quantity) -
                            parseInt(delivery?.quantity);

                          const item = items.find(
                            (i) => i.Id === deliver.itemId
                          );
                          const model = models.find(
                            (m) => m.Id === item.modelId
                          );

                          const unit = units.find((u) => u.Id === item.unitId);
                          return (
                            <tr>
                              <td>{deliver.Id}</td>
                              <td>{item.code}</td>
                              <td>{item.description}</td>
                              <td>{model.modelName}</td>
                              <td>{unit?.unitName}</td>
                              <td>
                                {!!count ? (
                                  <input
                                    type="number"
                                    value={
                                      newDelivery[index].price > count
                                        ? count
                                        : newDelivery[index].price
                                    }
                                    onChange={(e) => {
                                      if (
                                        parseInt(e.target.value) < 1 ||
                                        parseInt(e.target.value) > count
                                      ) {
                                        newDelivery[index].price = 1;
                                        setNewDelivery([...newDelivery]);
                                      } else {
                                        newDelivery[index].price = parseInt(
                                          e.target.value
                                        );
                                        setNewDelivery([...newDelivery]);
                                      }
                                    }}
                                  />
                                ) : (
                                  deliver.quantity
                                )}
                              </td>
                              <td>
                                {!!count ? (
                                  <Button
                                    variant="outline-primary"
                                    onClick={() => handleSubmitOne(deliver.Id)}
                                  >
                                    Delivered
                                  </Button>
                                ) : (
                                  <></>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                )}
                {/* {salesRefId.Id !== "" && } */}
              </Box>
            </div>
          </div>
        </div>
        <Modal show={open} onHide={handleOpen}>
          <Modal.Header closeButton>
            <span>Home / Sales Delivery / Customer Delivered</span>
          </Modal.Header>
          <Modal.Body>
            <table className="summary-table">
              <thead className="bg-info">
                <tr>
                  <th>Id</th>
                  <th>Code</th>
                  <th>Desc</th>
                  <th>Model</th>
                  <th>Unit</th>
                  <th>Qty</th>
                </tr>
              </thead>
              <tbody>
                {newDelivery?.map((deliver) => {
                  const item = items.find((i) => i.Id === deliver.itemId);
                  const unit = units.find((u) => u.Id === item.unitId);
                  const model = models.find((m) => m.Id === item.modelId);
                  const newDeliveryRef = deliveryRefs.find(
                    (d) => d.saleRefId === parseInt(salesRefId.Id)
                  );
                  const delivery = deliverys.find(
                    (d) =>
                      d.deliveryRefId === newDeliveryRef?.Id &&
                      deliver.itemId === d.itemId
                  );

                  const count =
                    parseInt(deliver?.quantity) - parseInt(delivery?.quantity);
                  return (
                    <tr>
                      <td>{deliver.Id}</td>
                      <td>{item.code}</td>
                      <td>{item.description}</td>
                      <td>{model?.modelName}</td>
                      <td>{unit?.unitName}</td>
                      <td>{count}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <center>
              <div className="row">
                <div className="col-sm-12 mt-1">
                  <Button variant="outline-primary" onClick={handleSubmitAll}>
                    All Delivered
                  </Button>
                </div>
              </div>
            </center>
          </Modal.Body>
        </Modal>
        <Modal
          show={printView}
          onHide={() => {
            setPrintView(false);
            setOpen(false);
          }}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <ReactToPrint
                trigger={() => <Button color="primary">Print</Button>}
                content={() => componentRef.current}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container ref={componentRef}>
              <div className="row">
                <div className="col-sm-6 ">
                  <br />
                  <h1 style={{ marginLeft: "5vw", marginBottom: "2vh" }}>
                    Invoice
                  </h1>
                  <i
                    style={{ marginLeft: "7vw", marginBottom: "2vh" }}
                    className="fas fa-home fa-3x"
                  />
                </div>

                <div className="col-sm-6 ">
                  <br />
                  <div
                    style={{
                      fontSize: 18,
                      textAlign: "right",
                    }}
                  >
                    <p style={{ margin: "0", fontSize: 24 }}>
                      <b> Company Name</b>
                    </p>
                    <p style={{ margin: "0" }}>+251987654321</p>
                    <p style={{ margin: "0" }}>email@gmail.com</p>
                    <p style={{ margin: "0" }}>address1</p>
                    <p style={{ margin: "0" }}>address2</p>
                  </div>
                </div>
              </div>

              <hr />
              <Row className="">
                <Col sm={2}>
                  <p>
                    <b>BILL To</b>
                  </p>
                </Col>
                <Col sm={5}>
                  <div>
                    <p>
                      <b>Name : </b>
                      {
                        customers?.find((c) => c.Id === salesRefId.customerId)
                          ?.fullName
                      }
                    </p>
                  </div>
                  <div>
                    <p>
                      <b>Phone No : </b>
                      {
                        customers?.find((c) => c.Id === salesRefId.customerId)
                          ?.phoneNumber
                      }
                    </p>
                  </div>
                </Col>
                <Col sm={5}>
                  <div>
                    <p>
                      <b>RefId : </b>
                      {`1111111`}
                    </p>
                  </div>
                  <div>
                    <p>
                      <b>Date : </b>
                      {`${new Date().getDate()}/${
                        new Date().getMonth() + 1
                      }/${new Date().getFullYear()}`}
                    </p>
                  </div>
                </Col>
              </Row>
              <hr />
              <Row>
                <Table className="text-center" style={{ width: "100%" }}>
                  <tr>
                    <th>No</th>
                    <th>Desc</th>
                    <th>Model</th>
                    <th>Unit</th>
                    <th>Qty</th>
                    <th>Recived Qty</th>
                    <th>U.Price</th>
                    <th>T.Price</th>
                  </tr>

                  <tbody>
                    {salesValue.map((request, index) => {
                      const newDeliveryRef = deliveryRefs.find(
                        (d) => d.saleRefId === parseInt(salesRefId.Id)
                      );
                      let delivery = deliverys.find(
                        (d) =>
                          d.deliveryRefId === newDeliveryRef?.Id &&
                          request.itemId === d.itemId
                      );
                      const newitem = items.find(
                        (i) => i.Id === request.itemId
                      );
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>

                          <td>
                            {items.map((item) =>
                              item.Id === parseInt(request.itemId)
                                ? item.description
                                : ""
                            )}
                          </td>
                          <td>
                            {items.map((item) =>
                              item.Id === parseInt(request.itemId)
                                ? models.map((model) =>
                                    model.Id === parseInt(item.modelId)
                                      ? model.modelName
                                      : ""
                                  )
                                : ""
                            )}
                          </td>
                          <td>
                            {items.map((item) =>
                              item.Id === parseInt(request.itemId)
                                ? units.map((unit) =>
                                    unit.Id === parseInt(item.unitId)
                                      ? unit.unitName
                                      : ""
                                  )
                                : ""
                            )}
                          </td>

                          <td>{request.quantity}</td>
                          <td>
                            {!open
                              ? newitem.statusId === 2
                                ? request?.quantity -
                                  (request?.quantity - delivery?.quantity)
                                : request?.quantity
                              : request?.quantity + 1}
                          </td>
                          <td>{request.price}</td>
                          <td>{request.price * request?.quantity}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Row>
              <Row>
                <Col sm={8}>
                  <h3>Terms</h3>
                </Col>
                <Col sm={4}>
                  <Table>
                    <tbody>
                      <tr style={{}}>
                        <td>
                          <b
                            style={{
                              float: "right",
                              fontSize: 18,
                              width: "100px",
                              height: "10px",
                            }}
                          >
                            Total Price
                          </b>
                        </td>
                        <td>
                          <p
                            style={{
                              float: "left",
                              fontSize: 18,
                              width: "110px",
                              height: "10px",
                            }}
                          >
                            {salesValue
                              .map(
                                (s) => parseInt(s.price) * parseInt(s.quantity)
                              )
                              .reduce((acc, crr) => acc + crr, 0)}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b
                            style={{
                              float: "right",
                              fontSize: 18,
                              width: "100px",
                              height: "10px",
                            }}
                          >
                            VAT
                          </b>
                        </td>
                        <td>
                          <p
                            style={{
                              float: "left",
                              fontSize: 18,
                              width: "110px",
                              height: "10px",
                            }}
                          >
                            {price.vat}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b
                            style={{
                              float: "right",
                              fontSize: 18,
                              width: "100px",
                              height: "10px",
                            }}
                          >
                            With Hold
                          </b>
                        </td>
                        <td>
                          <p
                            style={{
                              float: "left",
                              fontSize: 18,
                              width: "110px",
                              height: "10px",
                            }}
                          >
                            {" "}
                            {price.withHold}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b
                            style={{
                              float: "right",
                              fontSize: 18,
                              width: "100px",
                              height: "10px",
                            }}
                          >
                            Grand Total
                          </b>
                        </td>
                        <td>
                          <p
                            style={{
                              float: "left",
                              fontSize: 18,
                              width: "110px",
                              height: "10px",
                            }}
                          >
                            {price.grandTotal}
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
        </Modal>
      </main>
    </div>
  );
};
export default Delivery;
