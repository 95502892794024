/** @format */

import React from "react";
import { Link } from "react-router-dom";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Header from "../Header";
import { Button, Col, Form, Row } from "react-bootstrap";
import { TextField, Box } from "@mui/material";
const LoginHistory = () => {
  const columns = [
    {
      field: "Id",
      headerName: "#",
      width: 100,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "logRefName",
      headerName: "Log Reference Name",
      headerClassName: "super-app-theme--header",
      width: 300,
    },
    {
      field: "createdBy",
      headerName: "Created By",
      headerClassName: "super-app-theme--header",
      width: 200,
    },
    {
      field: "date",
      headerName: "Date",
      headerClassName: "super-app-theme--header",
      width: 200,
    },
    {
      field: "status",
      headerName: "Status",
      headerClassName: "super-app-theme--header",
      width: 200,
    },
    {
      field: "",
      headerName: "",
      headerClassName: "super-app-theme--header",
      width: 250,
    },
  ];
  return (
    <div>
      <div>
        <Header />
      </div>
      <main style={{ marginTop: "120px" }}>
        <div
          className="tab-container "
          style={{
            marginTop: "-20px",
            marginBottom: "-5px",
          }}
        >
          <Link className="p-2" to={`/purchase_report`}>
            Purchase Report
          </Link>
          <Link className="p-2" to={`/sales_report`}>
            Sales Report
          </Link>
          <Link className="p-2" to={`/stock_report`}>
            Stock Report
          </Link>
          <Link className="p-2" to={`/quantity_report`}>
            Quantity Report
          </Link>
          <Link
            className="p-2"
            style={{
              backgroundColor: "white",
              borderRadius: "5px 5px 0 0",
              border: "solid 0.05em rgb(181, 181, 181)",
            }}
          >
            Log History
          </Link>
        </div>
        <div className="card card-info card-outline">
          {/* <div className="card-header">
            <center>
              <div>
                <h4>System Stock Balance</h4>
              </div>
            </center>
          </div> */}
          <div className="card-body">
            <div
              className="nav-bra pl-3 ml-1 p-2 "
              style={{
                backgroundColor: "rgb(235, 235, 235)",
              }}
            >
              <span>Home / Logining History</span>
            </div>
            <br />
            <div style={{ border: "solid 1px #ffb71b" }}></div>
            <br />
            <div
              style={{
                backgroundColor: "white",
                textAlign: "center",
                height: "50px",
              }}
            >
              <h3 className="p-3">Log History</h3>
            </div>

            <Box
              sx={{
                height: 300,
                width: "100%",
                "& .super-app-theme--header": {
                  backgroundColor: "#1de5ec",
                  color: "white",
                },
              }}
            >
              <DataGrid
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                columns={columns}
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                  },
                }}
                pageSize={5}
                getRowId={(row) => row.Id}
              />
            </Box>
          </div>
        </div>
      </main>
    </div>
  );
};
export default LoginHistory;
