/** @format */

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Header from "../Header";
import { Button, Col, Form, Row } from "react-bootstrap";
import { TextField, Box } from "@mui/material";
const StockReport = () => {
  const [items, setItems] = useState([]);
  const [allQuantity, setAllQuantity] = useState([]);
  const [quantitys, setQuantitys] = useState([]);
  const [units, setUnits] = useState([]);
  const [stockDate, setStockDate] = useState({
    start: new Date(),
    end: new Date(),
  });
  const fetchAllData = async () => {
    const [item, quantity, unit] = await Promise.all([
      axios.get("https://enin-server.ga.highendtradingplc.com/item"),
      axios.get(
        "https://enin-server.ga.highendtradingplc.com/deliveryquantity"
      ),
      axios.get("https://enin-server.ga.highendtradingplc.com/unit"),
    ]);
    setItems(item.data);
    setAllQuantity(quantity.data);
    setUnits(unit.data);
    let data = [];
    quantity.data?.map((qua) => {
      const date = new Date(qua.date);
      const newItem = item.data?.find(
        (i) => qua.itemId === i.Id && i.statusId === 2
      );
      if (
        ((date.getFullYear() > stockDate.start.getFullYear() &&
          date.getFullYear() < stockDate.end.getFullYear()) ||
          (date.getFullYear() >= stockDate.start.getFullYear() &&
            date.getMonth() > stockDate.start.getMonth() &&
            date.getFullYear() <= stockDate.end.getFullYear() &&
            date.getMonth() < stockDate.end.getMonth()) ||
          (date.getFullYear() >= stockDate.start.getFullYear() &&
            date.getMonth() >= stockDate.start.getMonth() &&
            date.getDate() >= stockDate.start.getDate() &&
            date.getFullYear() <= stockDate.end.getFullYear() &&
            date.getMonth() <= stockDate.end.getMonth() &&
            date.getDate() <= stockDate.end.getDate())) &&
        !!newItem
      ) {
        data.push(qua);
      }
    });
    setQuantitys(data);
  };
  useEffect(() => {
    fetchAllData();
  }, []);
  const columns = [
    {
      field: "Id",
      headerName: "#",
      width: 100,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "code",
      headerName: "Code",
      headerClassName: "super-app-theme--header",
      width: 100,
    },
    {
      field: "description",
      headerName: "Item Description",
      headerClassName: "super-app-theme--header",
      width: 200,
    },
    {
      field: "unit",
      headerName: "Unit",
      headerClassName: "super-app-theme--header",
      width: 150,
    },
    {
      field: "quantity",
      headerName: "Qty on Hand",
      headerClassName: "super-app-theme--header",
      width: 150,
    },
  ];
  const rows = quantitys?.map((quantity, index) => {
    const item = items.find((i) => i.Id === quantity.itemId);
    const unit = units.find((u) => u.Id === item.unitId);
    return {
      Id: index + 1,
      code: item.code,
      description: item.description,
      unit: unit.unitName,
      quantity: quantity.quantity,
    };
  });

  return (
    <div>
      <div>
        <Header />
      </div>
      <main style={{ marginTop: "120px" }}>
        <div
          className="tab-container "
          style={{
            marginTop: "-20px",
            marginBottom: "-5px",
          }}
        >
          <Link className="p-2" to={`/purchase_report`}>
            Purchase Report
          </Link>
          <Link className="p-2" to={`/sales_report`}>
            Sales Report
          </Link>
          <Link
            className="p-2"
            style={{
              backgroundColor: "white",
              borderRadius: "5px 5px 0 0",
              border: "solid 0.05em rgb(181, 181, 181)",
            }}
            to={`/stock_report`}
          >
            Stock Report
          </Link>
          <Link className="p-2" to={`/quantity_report`}>
            Quantity Report
          </Link>
          <Link className="p-2" to={`/login_history`}>
            Log History
          </Link>
        </div>
        <div className="card card-info card-outline">
          <div className="card-body">
            <div
              className="nav-bra pl-3 ml-1 p-2 "
              style={{
                backgroundColor: "rgb(235, 235, 235)",
              }}
            >
              <span>Home / Stock Report</span>
            </div>
            <br />
            <div style={{ border: "solid 1px #ffb71b" }}></div>
            <br />
            <Form>
              <Form.Group as={Row}>
                <Col md={5} className="mb-2">
                  <Form.Label
                    style={{
                      margin: "7px 5px 0 0",
                    }}
                  >
                    From
                  </Form.Label>
                  <TextField
                    type="date"
                    size="small"
                    onChange={(event) => {
                      if (
                        new Date() >= new Date(event.target.value) &&
                        new Date(stockDate.end) >= new Date(event.target.value)
                      ) {
                        stockDate.start = new Date(event.target.value);
                      }
                    }}
                    style={{ width: "200px" }}
                  />
                </Col>
                <Col md={5} className="mb-2 ">
                  <Form.Label
                    style={{
                      margin: "7px 27px 0 0",
                    }}
                  >
                    To
                  </Form.Label>
                  <TextField
                    size="small"
                    type="date"
                    style={{ width: "200px" }}
                    onChange={(event) => {
                      if (new Date() >= new Date(event.target.value)) {
                        stockDate.end = new Date(event.target.value);
                      }
                    }}
                  />
                </Col>
                <Col md={2}>
                  <center>
                    <Button
                      onClick={(e) => {
                        let data = [];
                        allQuantity?.map((qua) => {
                          const date = new Date(qua.date);
                          if (
                            (date.getFullYear() >
                              stockDate.start.getFullYear() &&
                              date.getFullYear() <
                                stockDate.end.getFullYear()) ||
                            (date.getFullYear() >=
                              stockDate.start.getFullYear() &&
                              date.getMonth() > stockDate.start.getMonth() &&
                              date.getFullYear() <=
                                stockDate.end.getFullYear() &&
                              date.getMonth() < stockDate.end.getMonth()) ||
                            (date.getFullYear() >=
                              stockDate.start.getFullYear() &&
                              date.getMonth() >= stockDate.start.getMonth() &&
                              date.getDate() >= stockDate.start.getDate() &&
                              date.getFullYear() <=
                                stockDate.end.getFullYear() &&
                              date.getMonth() <= stockDate.end.getMonth() &&
                              date.getDate() <= stockDate.end.getDate())
                          ) {
                            data.push(qua);
                          }
                        });
                        setQuantitys(data);
                      }}
                      variant="outline-info"
                    >
                      Search
                    </Button>
                  </center>
                </Col>
              </Form.Group>
            </Form>
            <hr />
            <Box
              sx={{
                height: 500,
                width: "100%",
                "& .super-app-theme--header": {
                  backgroundColor: "#1de5ec",
                  color: "white",
                },
              }}
            >
              <DataGrid
                rows={rows}
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                columns={columns}
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                  },
                }}
                pageSize={5}
                getRowId={(row) => row.Id}
              />
            </Box>
          </div>
        </div>
      </main>
    </div>
  );
};
export default StockReport;
