/** @format */
import React, { useEffect, useState } from "react";
import Header from "../Header";

import { Button, Modal, Form, Col, Row, Label } from "react-bootstrap";
import { Autocomplete, MenuItem } from "@mui/material";
import TextField from "@mui/material/TextField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import {
  faUser,
  faBackwardStep,
  faFloppyDisk,
  faCode,
  faCalendar,
  faPenToSquare,
  faTrashCan,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import axios from "axios";
const validationSchema = Yup.object().shape({
  date: Yup.date().required("Date is required!"),
  fsNumber: Yup.string().required("FS Number is required!"),
  mrcNumber: Yup.string().required("FS Number is required!"),
});
function PurchasePage() {
  const [open, setOpen] = useState(false);
  const [suppliers, setSuppliers] = useState([]);
  const [items, setItems] = useState([]);
  const [models, setModels] = useState([]);
  const [units, setUnits] = useState(null);
  const [itemId, setItemId] = useState(null);
  const [itemCheck, setItemCheck] = useState(null);
  const [supplierCheck, setSupplierCheck] = useState(null);
  const [supplierValue, setSupplierValue] = useState(null);
  const [itemValue, setItemValue] = useState([]);
  const [valids, setValid] = useState([0, 0]);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [taxs, setTaxs] = useState([]);
  const [update, setUpdate] = useState({
    itemId: "",
    quantity: "",
    price: "",
  });
  const [price, setPrice] = useState({
    total: 0,
    vat: 0,
    grandTotal: 0,
  });
  const fetchAllData = async () => {
    try {
      const [supplier, item, model, unit, tax] = await Promise.all([
        axios.get("https://enin-server.ga.highendtradingplc.com/supplier"),
        axios.get("https://enin-server.ga.highendtradingplc.com/item"),
        axios.get("https://enin-server.ga.highendtradingplc.com/model"),
        axios.get("https://enin-server.ga.highendtradingplc.com/unit"),
        axios.get("https://enin-server.ga.highendtradingplc.com/tax"),
      ]);
      setSuppliers(supplier.data);
      setItems(item.data);
      setModels(model.data);
      setUnits(unit.data);
      setTaxs(tax.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAllData();
  }, []);
  const handleOpen = () => {
    setOpen(!open);
  };
  const itemData = {
    options: items,
    getOptionLabel: (option) => option.description,
  };
  const supplierData = {
    options: suppliers,
    getOptionLabel: (option) => option.fullName,
  };
  const formik = useFormik({
    initialValues: {
      supplierId: "",
      date: "",
      fsNumber: "",
      mrcNumber: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        await axios
          .post(
            "https://enin-server.ga.highendtradingplc.com/purchase_ref/add",
            {
              totalPrice: price.grandTotal,
              supplierId: values.supplierId,
              date: values.date,
              fsNumber: values.fsNumber,
              mrcNumber: values.mrcNumber,
            }
          )
          .then(async (res) => {
            if (res) {
              let newId = res.data.data.Id + "";
              if (newId.length === 1) {
                newId = "P-000" + newId;
              } else if (newId.length === 2) {
                newId = "P-00" + newId;
              } else if (newId.length === 3) {
                newId = "P-0" + newId;
              } else {
                newId = "P-" + newId;
              }
              for (let i = 0; i <= itemValue.length - 1; ) {
                console.log(res.data.data.Id);
                const item = itemValue[i].itemId;
                const price = itemValue[i].price;
                const quantity = itemValue[i].quantity;
                await axios.post(
                  "https://enin-server.ga.highendtradingplc.com/purchase/add",
                  {
                    itemId: item,
                    purchaseRefId: res.data.data.Id,
                    price: price,
                    quantity: quantity,
                  }
                );
                let newItem = items.find((i) => i.Id === item);
                newItem.physicalQuantity =
                  parseInt(newItem.physicalQuantity) + parseInt(quantity);
                newItem.logicalQuantity =
                  parseInt(newItem.logicalQuantity) + parseInt(quantity);
                await axios.put(
                  "https://enin-server.ga.highendtradingplc.com/item/update/" +
                    item,
                  newItem
                );
                await axios.post(
                  "https://enin-server.ga.highendtradingplc.com/quantity/add",
                  {
                    itemId: item,
                    salePurchaseId: newId,
                    quantity: newItem.logicalQuantity,
                  }
                );
                await axios.post(
                  "https://enin-server.ga.highendtradingplc.com/deliveryquantity/add",
                  {
                    itemId: item,
                    deliveryPurchaseId: newId,
                    quantity: newItem.physicalQuantity,
                  }
                );
                i = i + 1;
              }
            }
          });
      } catch (error) {
        console.log(error);
      } finally {
        setSupplierValue(null);
        values.fsNumber = "";
        values.date = "";
        values.mrcNumber = "";
        values.supplierId = "";
        clearData();
      }
    },
  });
  const clearData = () => {
    price.total = 0;
    price.vat = 0;
    price.grandTotal = 0;
    setItemValue([]);
  };
  const handleValidity = (i) => {
    valids[i] = 1;
    setValid([...valids]);
    console.log(valids);
  };
  return (
    <div>
      <div>
        <Header />
      </div>

      <main style={{ marginTop: "120px" }}>
        <div
          className="tab-container "
          style={{
            marginTop: "-20px",
            marginBottom: "-5px",
          }}
        >
          <Link className="p-2" to={`/inventory`}>
            Item
          </Link>
          <Link
            className="p-2"
            to={`/purchase`}
            style={{
              backgroundColor: "white",
              borderRadius: "5px 5px 0 0",
              border: "solid 0.05em rgb(181, 181, 181)",
            }}
          >
            Purchase
          </Link>
          <Link className="p-2" to={`/adjustment`}>
            Adjustment
          </Link>
          <Link className="p-2" to={`/quantity_on_hand/${1}`}>
            Qty On Hand
          </Link>
        </div>
        <div className="card card-info card-outline">
          <div className="card-header">
            <h4>New Purchase Entry</h4>
          </div>
          <div className="card-body">
            <div
              className="nav-bra pl-3 ml-1 p-2 "
              style={{
                backgroundColor: "rgb(235, 235, 235)",
              }}
            >
              <span>Purchase / Create</span>
            </div>
            <hr />
            <div>
              <div>
                <Form className="">
                  <Form.Group as={Row} controlId="formHorizontalUsername">
                    <Col md={6} className="mb-3">
                      <Autocomplete
                        {...supplierData}
                        id="controlled-demo"
                        size="small"
                        variant="outlined"
                        name="supplierId"
                        onBlur={(e) => handleValidity(0)}
                        value={supplierValue}
                        onChange={(event, newValue) => {
                          if (!newValue?.Id) {
                            setSupplierCheck(null);
                            return;
                          }
                          setSupplierValue(newValue);
                          formik.values.supplierId = newValue?.Id;
                          setSupplierCheck(5);
                        }}
                        style={{ width: "250px", float: "left" }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={valids[0] === 1 && supplierCheck === null}
                            label={
                              <div style={{ float: "left" }}>
                                <FontAwesomeIcon
                                  icon={faUser}
                                  style={{ float: "left", marginRight: "10px" }}
                                />
                                <span>Supplier</span>
                              </div>
                            }
                          />
                        )}
                      />

                      <Button
                        variant="outline-info"
                        //onClick={openAddModal}
                        style={{
                          width: "30px",
                          height: "40px",
                          marginLeft: "20px",
                          float: "left",
                        }}
                        onClick={handleOpen}
                      >
                        <h2
                          style={{
                            marginTop: "-5px",
                            marginLeft: "-7px",
                          }}
                        >
                          +
                        </h2>
                      </Button>
                    </Col>
                    <Col md={6} className="mb-3">
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.date}
                        type="date"
                        label={
                          <div style={{ float: "left", width: "70px" }}>
                            <FontAwesomeIcon
                              icon={faCalendar}
                              size="1x"
                              style={{
                                float: "left",
                                marginTop: "5px",
                                marginRight: "5px",
                              }}
                            />
                            <h4>Date</h4>
                          </div>
                        }
                        size="small"
                        name="date"
                        error={formik.touched.date && formik.errors.date}
                        helperText={
                          formik.touched.date && formik.errors.date
                            ? `${formik.errors.date}`
                            : ""
                        }
                        style={{ width: "300px" }}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="formHorizontalUsername">
                    <Col md={6} className="mb-3">
                      <TextField
                        id="outlined-basic"
                        label={
                          <div style={{ float: "left" }}>
                            <FontAwesomeIcon
                              icon={faCode}
                              style={{ float: "left", marginRight: "10px" }}
                            />
                            <span> MRCNumber</span>
                          </div>
                        }
                        variant="outlined"
                        type="text"
                        size="small"
                        name="mrcNumber"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.mrcNumber}
                        error={
                          formik.touched.mrcNumber && formik.errors.mrcNumber
                        }
                        helperText={
                          formik.touched.mrcNumber && formik.errors.mrcNumber
                            ? `${formik.errors.mrcNumber}`
                            : ""
                        }
                        style={{ width: "300px" }}
                      />
                    </Col>
                    <Col md={6}>
                      <TextField
                        id="outlined-basic"
                        label={
                          <div style={{ float: "left" }}>
                            <FontAwesomeIcon
                              icon={faCode}
                              style={{ float: "left", marginRight: "10px" }}
                            />
                            <span> FSNumber</span>
                          </div>
                        }
                        variant="outlined"
                        type="text"
                        size="small"
                        name="fsNumber"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.fsNumber}
                        error={
                          formik.touched.fsNumber && formik.errors.fsNumber
                        }
                        helperText={
                          formik.touched.fsNumber && formik.errors.fsNumber
                            ? `${formik.errors.fsNumber}`
                            : ""
                        }
                        style={{ width: "300px" }}
                      />
                    </Col>
                  </Form.Group>
                </Form>
              </div>
              <Formik
                initialValues={{
                  itemId: "",
                  quantity: "",
                  price: "",
                  taxId: "",
                  totalprice: "",
                }}
                validationSchema={Yup.object().shape({
                  quantity: Yup.string().required("Quantity is required"),
                  price: Yup.string().required("Price is required"),
                })}
                onSubmit={async (values) => {
                  try {
                    if (itemCheck === null) {
                      return;
                    }
                    const checkItem = itemValue?.find(
                      (i) => i?.itemId === itemId.Id
                    );
                    if (!!checkItem) {
                      alert("You use the same item!");
                      return;
                    }
                    update.price = values.price;
                    update.quantity = values.quantity;
                    values.totalprice = update.quantity * update.price;
                    const tax = taxs.find((t) => t.Id === values.taxId);
                    price.vat =
                      parseFloat(price.vat) +
                      parseFloat(update.price) *
                        parseFloat(tax.taxValue / 100) *
                        parseFloat(update.quantity);
                    price.total =
                      parseFloat(price.total) + parseFloat(values.totalprice);
                    price.grandTotal = price.total + price.vat;
                    setItemId(null);
                    setItemValue([
                      ...itemValue,
                      {
                        itemId: values.itemId,
                        quantity: update.quantity,
                        price: update.price,
                        taxId: values.taxId,
                        totalprice: values.totalprice,
                      },
                    ]);
                  } catch (error) {
                    console.log(error);
                  } finally {
                    values.price = "";
                    values.quantity = "";
                  }
                }}
              >
                {(props) => (
                  <div className="">
                    <hr />
                    <h3>Purchase Item</h3>
                    <hr />
                    <Form onSubmit={props.handleSubmit}>
                      <Form.Group as={Row} controlId="formHorizontalUsername">
                        <Col md={4} className="mb-3">
                          <Autocomplete
                            {...itemData}
                            id="controlled-demo"
                            size="small"
                            name="itemId"
                            value={itemId}
                            onBlur={(e) => handleValidity(1)}
                            onChange={(event, newValue) => {
                              if (!newValue?.Id) {
                                setItemCheck(null);
                                return;
                              }
                              setItemId(newValue);
                              props.values.taxId = newValue?.taxId;
                              props.values.itemId = newValue?.Id;
                              setItemCheck(5);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={
                                  <div style={{ float: "left" }}>
                                    <span>Select Item</span>
                                  </div>
                                }
                                error={valids[1] === 1 && itemCheck === null}
                              />
                            )}
                          />
                        </Col>
                        <Col md={3} className="mb-3">
                          <TextField
                            id="outlined-basic"
                            label="Qty"
                            variant="outlined"
                            type="text"
                            size="small"
                            name="quantity"
                            onChange={props.handleChange}
                            value={props.values.quantity}
                            onBlur={props.handleBlur}
                            error={
                              props.touched.quantity && props.errors.quantity
                            }
                            helperText={
                              props.touched.quantity && props.errors.quantity
                                ? `${props.errors.quantity}`
                                : ""
                            }
                            style={{ width: "200px" }}
                          />
                        </Col>
                        <Col md={3} className="mb-3">
                          <TextField
                            id="outlined-basic"
                            variant="outlined"
                            label="Price"
                            type="text"
                            size="small"
                            name="price"
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            value={props.values.price}
                            error={props.touched.price && props.errors.price}
                            helperText={
                              props.touched.price && props.errors.price
                                ? `${props.errors.price}`
                                : ""
                            }
                            style={{ width: "200px" }}
                          />
                        </Col>
                        <Col md={2} className="mb-3">
                          <Button
                            style={{ width: "150px", height: "35px" }}
                            variant="outline-primary"
                            type="submit"
                            onClick={(e) => {
                              console.log(itemValue);
                            }}
                          >
                            <h3
                              style={{
                                float: "left",
                                marginTop: "-6px",
                                marginRight: "5px",
                              }}
                            >
                              +
                            </h3>
                            <h6 style={{ float: "left", marginTop: "1px" }}>
                              Add To List
                            </h6>
                          </Button>
                        </Col>
                      </Form.Group>
                    </Form>

                    <div>
                      <div style={{ overflow: "scroll" }}>
                        <table className="summary-table ">
                          <thead className="bg-info">
                            <tr>
                              <th>Id</th>
                              <th>Code</th>
                              <th>Desc</th>
                              <th>Model</th>
                              <th>Unit</th>
                              <th>Qty</th>
                              <th>U.Price</th>
                              <th>T.Price</th>
                              <th style={{ minWidth: "200px" }}>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {itemValue.map((request, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>
                                  {items.map((item) =>
                                    item.Id === parseInt(request.itemId)
                                      ? item.code
                                      : ""
                                  )}
                                </td>

                                <td>
                                  {items.map((item) =>
                                    item.Id === parseInt(request.itemId)
                                      ? item.description
                                      : ""
                                  )}
                                </td>
                                <td>
                                  {items.map((item) =>
                                    item.Id === parseInt(request.itemId)
                                      ? models.map((model) =>
                                          model.Id === parseInt(item.modelId)
                                            ? model.modelName
                                            : ""
                                        )
                                      : ""
                                  )}
                                </td>
                                <td>
                                  {items.map((item) =>
                                    item.Id === parseInt(request.itemId)
                                      ? units.map((unit) =>
                                          unit.Id === parseInt(item.unitId)
                                            ? unit.unitName
                                            : ""
                                        )
                                      : ""
                                  )}
                                </td>
                                <td>
                                  {openUpdate &&
                                  request.itemId === update.itemId ? (
                                    <input
                                      type="number"
                                      minLength={1}
                                      maxLength={20}
                                      className="form-control"
                                      required
                                      style={{ width: "10vw" }}
                                      value={
                                        update.quantity === ""
                                          ? request.quantity
                                          : update.quantity
                                      }
                                      onChange={(e) => {
                                        if (
                                          parseInt(e.target.value) < 1 ||
                                          parseInt(e.target.value) > 20
                                        ) {
                                          setUpdate((prev) => ({
                                            ...prev,
                                            quantity: 1,
                                          }));
                                        } else {
                                          setUpdate((prev) => ({
                                            ...prev,
                                            quantity: parseInt(e.target.value),
                                          }));
                                        }
                                      }}
                                    />
                                  ) : (
                                    request.quantity
                                  )}
                                </td>
                                <td>
                                  {openUpdate &&
                                  request.itemId === update.itemId ? (
                                    <input
                                      type="number"
                                      className={
                                        parseFloat(update.price) < 50
                                          ? "form-control is-invalid"
                                          : "form-control"
                                      }
                                      minLength={100}
                                      maxLength={1000000}
                                      required
                                      value={
                                        update.price === ""
                                          ? request.price
                                          : update.price
                                      }
                                      style={{
                                        margin: "0 ",
                                        width: "19vw",
                                      }}
                                      onChange={(e) => {
                                        if (
                                          parseFloat(e.target.value) < 1 ||
                                          parseFloat(e.target.value) > 1000000
                                        ) {
                                          setUpdate((prev) => ({
                                            ...prev,
                                            price: 1,
                                          }));
                                        } else {
                                          setUpdate((prev) => ({
                                            ...prev,
                                            price: parseFloat(e.target.value),
                                          }));
                                        }

                                        setItemValue([...itemValue]);
                                      }}
                                    />
                                  ) : (
                                    request.price
                                  )}
                                </td>
                                <td>{request.totalprice}</td>
                                <td>
                                  {(request.itemId !== update.itemId ||
                                    !openUpdate) && (
                                    <div>
                                      <Button
                                        variant="outline-warning"
                                        className="mr-3"
                                        onClick={(e) => {
                                          setOpenUpdate(true);
                                          setUpdate({
                                            itemId: request.itemId,
                                            price: "",
                                            quantity: "",
                                          });
                                        }}
                                      >
                                        <div
                                          className="row "
                                          style={{
                                            marginBottom: "-10px",
                                            marginLeft: "-10px",
                                          }}
                                        >
                                          <div className="col">
                                            <FontAwesomeIcon
                                              style={{ float: "right" }}
                                              icon={faPenToSquare}
                                            />
                                          </div>
                                          <div className="col-6">
                                            <h6
                                              style={{
                                                float: "left",
                                                marginLeft: "-10px",
                                              }}
                                            >
                                              Edit
                                            </h6>
                                          </div>
                                        </div>
                                      </Button>
                                      <Button
                                        variant="outline-danger"
                                        onClick={(e) => {
                                          const newItem = itemValue.find(
                                            (i) =>
                                              parseInt(i.itemId) ===
                                              parseInt(request.itemId)
                                          );

                                          const tax = taxs.find(
                                            (t) => t.Id === newItem?.taxId
                                          );
                                          const priceVat =
                                            newItem?.quantity *
                                            parseFloat(newItem?.price) *
                                            parseFloat(tax?.taxValue / 100);
                                          const newItemValue =
                                            itemValue?.filter(
                                              (i) =>
                                                parseInt(i.itemId) !==
                                                parseInt(request.itemId)
                                            );
                                          setItemValue(newItemValue);
                                          price.vat =
                                            parseFloat(price.vat) - priceVat;
                                          price.total =
                                            parseFloat(price.total) -
                                            parseFloat(newItem.price) *
                                              newItem.quantity;
                                          price.grandTotal =
                                            price.vat + price.total;
                                        }}
                                      >
                                        <div
                                          className="row"
                                          style={{
                                            marginBottom: "-10px",
                                            marginLeft: "-10px",
                                          }}
                                        >
                                          <div className="col">
                                            <FontAwesomeIcon
                                              style={{
                                                float: "right",
                                                marginRight: "-5px",
                                              }}
                                              icon={faTrashCan}
                                            />
                                          </div>
                                          <div className="col-8">
                                            <h6
                                              style={{
                                                float: "left",
                                                marginLeft: "-3px",
                                              }}
                                            >
                                              Delete
                                            </h6>
                                          </div>
                                        </div>
                                      </Button>
                                    </div>
                                  )}

                                  {openUpdate &&
                                    request.itemId === update.itemId && (
                                      <div>
                                        <Button
                                          variant="outline-success"
                                          className="mr-3"
                                          onClick={(e) => {
                                            if (
                                              parseFloat(update.price) < 50 ||
                                              parseFloat(update.price) > 1000000
                                            ) {
                                              return;
                                            }
                                            price.grandTotal = 0;
                                            price.total = 0;
                                            price.vat = 0;
                                            itemValue[index].price =
                                              !!update.price
                                                ? parseFloat(update.price)
                                                : parseFloat(
                                                    itemValue[index].price
                                                  );
                                            itemValue[index].quantity =
                                              !!update.quantity
                                                ? parseInt(update.quantity)
                                                : parseFloat(
                                                    itemValue[index].quantity
                                                  );
                                            itemValue[index].totalprice =
                                              itemValue[index].price *
                                              itemValue[index].quantity;
                                            setItemValue([...itemValue]);
                                            update.itemId = "";
                                            update.price = "";
                                            update.quantity = "";
                                            itemValue.map((i) => {
                                              const tax = taxs.find(
                                                (t) => t.Id === i.taxId
                                              );
                                              price.vat =
                                                parseFloat(price.vat) +
                                                parseFloat(i.price) *
                                                  parseFloat(i.quantity) *
                                                  parseFloat(
                                                    parseInt(tax.taxValue) / 100
                                                  );
                                              price.total =
                                                parseFloat(price.total) +
                                                parseFloat(i.price) *
                                                  i.quantity;
                                            });
                                            price.grandTotal =
                                              price.total + price.vat;
                                          }}
                                        >
                                          <div
                                            className="row "
                                            style={{
                                              marginBottom: "-10px",
                                              marginLeft: "-20px",
                                            }}
                                          >
                                            <div className="col">
                                              <FontAwesomeIcon
                                                style={{ float: "right" }}
                                                className="fa-regular"
                                                icon={faFloppyDisk}
                                              />
                                            </div>
                                            <div className="col-6">
                                              <h6
                                                style={{
                                                  float: "left",
                                                  marginLeft: "-10px",
                                                }}
                                              >
                                                Save
                                              </h6>
                                            </div>
                                          </div>
                                        </Button>
                                        <Button
                                          variant="outline-danger"
                                          onClick={(e) => {
                                            setOpenUpdate(false);
                                            setUpdate({
                                              itemId: "",
                                              price: "",
                                              quantity: "",
                                            });
                                          }}
                                        >
                                          <div
                                            className="row"
                                            style={{
                                              marginBottom: "-10px",
                                              marginLeft: "-20px",
                                            }}
                                          >
                                            <div className="col">
                                              <FontAwesomeIcon
                                                style={{
                                                  float: "right",
                                                  marginRight: "-5px",
                                                }}
                                                icon={faXmark}
                                              />
                                            </div>
                                            <div className="col-8">
                                              <h6
                                                style={{
                                                  float: "left",
                                                  marginLeft: "-3px",
                                                }}
                                              >
                                                Cancle
                                              </h6>
                                            </div>
                                          </div>
                                        </Button>
                                      </div>
                                    )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        <div className="row">
                          <div className="col"></div>
                          <div
                            className="col mt-1"
                            style={{
                              textAlign: "right",
                              marginBottom: "20px",
                              float: "right",
                            }}
                          >
                            <Form.Group
                              as={Row}
                              controlId="formHorizontalUsername"
                              style={{ width: "500px" }}
                            >
                              <Col>
                                <Form.Label>Total : </Form.Label>
                              </Col>
                              <Col>
                                <Form.Control
                                  id="outlined-basic"
                                  disabled
                                  label="Qty"
                                  variant="outlined"
                                  type="text"
                                  size="small"
                                  name="qty"
                                  value={price.total}
                                  style={{ width: "150px" }}
                                />
                              </Col>
                            </Form.Group>
                            <Form.Group
                              as={Row}
                              controlId="formHorizontalUsername"
                              style={{ width: "500px" }}
                            >
                              <Col>
                                <Form.Label>VAT : </Form.Label>
                              </Col>
                              <Col>
                                <Form.Control
                                  id="outlined-basic"
                                  disabled
                                  label="Qty"
                                  variant="outlined"
                                  type="text"
                                  size="small"
                                  name="qty"
                                  value={price.vat}
                                  style={{ width: "150px" }}
                                />
                              </Col>
                            </Form.Group>
                            <Form.Group
                              as={Row}
                              controlId="formHorizontalUsername"
                              style={{ width: "500px" }}
                            >
                              <Col>
                                <Form.Label>Grand Total : </Form.Label>
                              </Col>
                              <Col>
                                <Form.Control
                                  id="outlined-basic"
                                  disabled
                                  label="Qty"
                                  variant="outlined"
                                  type="text"
                                  size="small"
                                  name="qty"
                                  value={price.grandTotal}
                                  style={{ width: "150px" }}
                                />
                              </Col>
                            </Form.Group>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </Formik>
            </div>
            <Form onSubmit={formik.handleSubmit}>
              <div style={{ float: "none" }} className="row">
                <Button
                  style={{
                    width: "42%",
                    marginLeft: "4%",
                    height: "40px",
                  }}
                  variant="outline-primary"
                  onClick={clearData}
                >
                  <div className="row mt-1">
                    <div className="col">
                      <FontAwesomeIcon
                        style={{ float: "right" }}
                        icon={faBackwardStep}
                      />
                    </div>
                    <div className="col-9">
                      <h6 style={{ float: "left" }}>Back To List</h6>
                    </div>
                  </div>
                </Button>
                <Button
                  style={{
                    width: "42%",
                    marginLeft: "10%",
                    height: "40px",
                  }}
                  variant="outline-primary"
                  type="submit"
                >
                  <div className="row mt-1">
                    <div className="col">
                      <FontAwesomeIcon
                        style={{ float: "right" }}
                        icon={faFloppyDisk}
                      />
                    </div>
                    <div className="col-7">
                      <h6 style={{ float: "left" }}>Submit</h6>
                    </div>
                  </div>
                </Button>
              </div>
            </Form>
          </div>
        </div>
        <Formik
          initialValues={{
            fullName: "",
            tinNumber: "",
            address: "",
            phoneNumber: "",
          }}
          validationSchema={Yup.object().shape({
            fullName: Yup.string().required("Name is required"),
            phoneNumber: Yup.string().required("Phone Number is required"),
            address: Yup.string().required("Address is required"),
            tinNumber: Yup.string().required("Tin Number is required"),
          })}
          onSubmit={async (values) => {
            try {
              await axios.post(
                "https://enin-server.ga.highendtradingplc.com/supplier/add",
                values
              );
            } catch (error) {
              console.log(error);
            } finally {
              fetchAllData();
              setOpen(false);
            }
          }}
        >
          {(props) => (
            <Modal
              show={open}
              onHide={handleOpen}
              style={{ minWidth: "700px" }}
            >
              <Modal.Header closeButton>
                <span>Purchase / Supplier / Create</span>
              </Modal.Header>
              <Modal.Body>
                <Form className="m-2 ml-5 pl-5" onSubmit={props.handleSubmit}>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formHorizontalUsername"
                  >
                    <Col sm={8}>
                      <TextField
                        id="outlined-basic"
                        label="Full Name"
                        variant="outlined"
                        size="small"
                        type="text"
                        name="fullName"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.fullName}
                        isInvalid={
                          props.touched.fullName && props.errors.fullName
                        }
                        style={{ maxWidth: "300px" }}
                      />

                      {props.touched.fullName && props.errors.fullName && (
                        <div className="text-danger mt-1">
                          {props.errors.fullName}
                        </div>
                      )}
                    </Col>
                  </Form.Group>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formHorizontalUsername"
                  >
                    <Col sm={8}>
                      <TextField
                        id="outlined-basic"
                        label="Till Number"
                        variant="outlined"
                        size="small"
                        type="text"
                        name="tinNumber"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.tinNumber}
                        isInvalid={
                          props.touched.tinNumber && props.errors.tinNumber
                        }
                        style={{ maxWidth: "300px" }}
                      />

                      {props.touched.tinNumber && props.errors.tinNumber && (
                        <div className="text-danger mt-1">
                          {props.errors.tinNumber}
                        </div>
                      )}
                    </Col>
                  </Form.Group>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formHorizontalUsername"
                  >
                    <Col sm={8}>
                      <TextField
                        id="outlined-basic"
                        label="Phone Number"
                        variant="outlined"
                        size="small"
                        type="text"
                        name="phoneNumber"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.phoneNumber}
                        isInvalid={
                          props.touched.phoneNumber && props.errors.phoneNumber
                        }
                        style={{ maxWidth: "300px" }}
                      />

                      {props.touched.phoneNumber &&
                        props.errors.phoneNumber && (
                          <div className="text-danger mt-1">
                            {props.errors.phoneNumber}
                          </div>
                        )}
                    </Col>
                  </Form.Group>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formHorizontalUsername"
                  >
                    <Col sm={8}>
                      <TextField
                        id="outlined-basic"
                        label="Address"
                        variant="outlined"
                        size="small"
                        type="text"
                        name="address"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.address}
                        isInvalid={
                          props.touched.address && props.errors.address
                        }
                        style={{ maxWidth: "300px" }}
                      />

                      {props.touched.address && props.errors.address && (
                        <div className="text-danger mt-1">
                          {props.errors.address}
                        </div>
                      )}
                    </Col>
                  </Form.Group>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formHorizontalUsername"
                  >
                    <Col sm={12}>
                      <Button
                        style={{ width: "62%" }}
                        variant="outline-success"
                        type="submit"
                      >
                        Create
                      </Button>
                    </Col>
                  </Form.Group>
                </Form>
              </Modal.Body>
            </Modal>
          )}
        </Formik>
      </main>
    </div>
  );
}

export default PurchasePage;
