/** @format */

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Header from "../Header";
import { useParams } from "react-router-dom";
const QuantityOnHand = () => {
  const [units, setUnits] = useState([]);
  const [items, setItems] = useState([]);
  const [models, setModel] = useState([]);
  const fetchAllData = async () => {
    const [unit, item, model] = await Promise.all([
      axios.get("https://enin-server.ga.highendtradingplc.com/unit"),
      axios.get("https://enin-server.ga.highendtradingplc.com/item"),
      axios.get("https://enin-server.ga.highendtradingplc.com/model"),
    ]);
    setUnits(unit.data);
    setItems(item.data.filter((i) => i.statusId === 2));
    setModel(model.data);
  };
  useEffect(() => {
    fetchAllData();
  }, []);
  const { id } = useParams();
  const column1 = [
    { field: "Id", headerName: "Id", width: 100 },

    { field: "description", headerName: "Description", width: 200 },
    {
      field: "modelId",
      headerName: "Model",
      width: 150,
      valueGetter: (params) =>
        models.find((model) => model.Id === params)?.modelName || "",
    },
    {
      field: "code",
      headerName: "Code",
      width: 150,
    },
    {
      field: "unitId",
      headerName: "Unit",
      width: 150,
      valueGetter: (params) =>
        units.find((unit) => unit.Id === params)?.unitName || "",
    },
    {
      field: "physicalQuantity",
      headerName: "Remain Physical Qty",
      width: 150,
    },
  ];
  const column2 = [
    { field: "Id", headerName: "Id", width: 100 },

    { field: "description", headerName: "Description", width: 200 },
    {
      field: "modelId",
      headerName: "Model",
      width: 150,
      valueGetter: (params) =>
        models.find((model) => model.Id === params)?.modelName || "",
    },
    {
      field: "code",
      headerName: "Code",
      width: 150,
    },
    {
      field: "unitId",
      headerName: "Unit",
      width: 150,
      valueGetter: (params) =>
        units.find((unit) => unit.Id === params)?.unitName || "",
    },
    {
      field: "logicalQuantity",
      headerName: "Remain Logical Qty",
      width: 150,
    },
  ];
  const style = {
    backgroundColor: "white",
    borderRadius: "5px 5px 0 0",
    border: "solid 0.05em rgb(181, 181, 181)",
  };

  return (
    <div>
      <div style={{ position: "sticky" }}>
        <Header />
      </div>
      <main style={{ marginTop: "120px" }}>
        <div
          className="tab-container "
          style={{
            marginTop: "-20px",
            marginBottom: "-17px",
          }}
        >
          <Link className="p-2" to={`/inventory`}>
            Item
          </Link>
          <Link className="p-2" to={`/purchase`}>
            Purchase
          </Link>
          <Link className="p-2" to={`/adjustment`}>
            Adjustment
          </Link>
          <Link
            className="p-2"
            to={`/quantity_on_hand/${1}`}
            style={{
              backgroundColor: "white",
              borderRadius: "5px 5px 0 0",
              border: "solid 0.05em rgb(181, 181, 181)",
            }}
          >
            Qty On Hand
          </Link>
        </div>
        <hr />
        <div className="sub-tab-container" style={{ marginBottom: 0 }}>
          <Link
            className="pr-2 pl-2"
            to={`/quantity_on_hand/${1}`}
            style={parseInt(id) === 1 ? style : {}}
          >
            Physical
          </Link>
          <Link
            className="pr-2 pl-2"
            to={`/quantity_on_hand/${2}`}
            style={parseInt(id) === 2 ? style : {}}
          >
            Logical
          </Link>
        </div>
        <div className="card card-info card-outline">
          <div className="card-header">
            <center>
              <div>
                <h4>System Stock Balance</h4>
              </div>
            </center>
          </div>
          <div className="card-body">
            <DataGrid
              rows={items}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              columns={parseInt(id) === 1 ? column1 : column2}
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                },
              }}
              pageSize={5}
              getRowId={(row) => row.Id}
            />
          </div>
        </div>
      </main>
    </div>
  );
};
export default QuantityOnHand;
