/** @format */
import React, { useEffect, useRef, useState } from "react";
import Header from "../Header";
import { Button, Modal, Form, Col, Row, Table } from "react-bootstrap";
import { Autocomplete, TextField, Container } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactToPrint from "react-to-print";
import {
  faUser,
  faBackwardStep,
  faFloppyDisk,
  faCalendar,
  faCode,
  faPenToSquare,
  faTrashCan,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
const validationSchema = Yup.object().shape({
  customerId: Yup.string().required("Customer is required!"),
  date: Yup.date().required("Date is required!"),
  fsNumber: Yup.string().required("FS Number is required!"),
});
function DirectDelivery() {
  const [open, setOpen] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [items, setItems] = useState([]);
  const [models, setModels] = useState([]);
  const [units, setUnits] = useState([]);
  const [itemId, setItemId] = useState(null);
  const [itemCheck, setItemCheck] = useState(null);
  const [customerCheck, setCustomerCheck] = useState(null);
  const [valids, setValid] = useState([0, 0]);
  const [itemValue, setItemValue] = useState([]);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [taxs, setTaxs] = useState([]);
  const [deliverys, setDelivery] = useState([]);
  const [supplierValue, setSupplierValue] = useState(null);
  const [printView, setPrintView] = useState(false);
  const [update, setUpdate] = useState({
    itemId: "",
    quantity: "",
    price: "",
  });
  const [price, setPrice] = useState({
    total: 0,
    vat: 0,
    withHold: 0,
    grandTotal: 0,
  });
  const componentRef = useRef();
  const fetchAllData = async () => {
    try {
      const [customer, item, model, unit, tax, delivery] = await Promise.all([
        axios.get("https://enin-server.ga.highendtradingplc.com/customer"),
        axios.get("https://enin-server.ga.highendtradingplc.com/item"),
        axios.get("https://enin-server.ga.highendtradingplc.com/model"),
        axios.get("https://enin-server.ga.highendtradingplc.com/unit"),
        axios.get("https://enin-server.ga.highendtradingplc.com/tax"),
        axios.get("https://enin-server.ga.highendtradingplc.com/delivery"),
      ]);
      setCustomers(customer.data);
      setItems(item.data);
      setModels(model.data);
      setUnits(unit.data);
      setTaxs(tax.data);
      // setDelivery(delivery.data?.filter((d) => parseInt(d.remainQuantity) > 0));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAllData();
  }, []);
  const handleOpen = () => {
    setOpen(!open);
  };
  const itemData = {
    options: items,
    getOptionLabel: (option) => option.description,
  };
  const customerData = {
    options: customers,
    getOptionLabel: (option) => option.fullName,
  };

  const formik = useFormik({
    initialValues: {
      customerId: "",
      date: "",
      fsNumber: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const status = itemValue.some((i) => i.statusId === 2) ? 2 : 1;
        const saleRef = await axios.post(
          "https://enin-server.ga.highendtradingplc.com/sales_ref/add",
          {
            totalPrice: price.grandTotal,
            customerId: values.customerId,
            date: values.date,
            fsNumber: values.fsNumber,
            statusId: status,
          }
        );
        const saleRefId = saleRef.data.data.Id;
        const newId = `DD-${saleRefId.toString().padStart(4, "0")}`;

        await Promise.all(
          itemValue.map(async (item) => {
            await axios.post(
              "https://enin-server.ga.highendtradingplc.com/sales/add",
              {
                itemId: item.itemId,
                saleRefId: saleRefId,
                price: item.price,
                quantity: item.quantity,
              }
            );

            let newItem = items.find((i) => i.Id === item.itemId);

            if (newItem.statusId === 2) {
              newItem.logicalQuantity -= item.quantity;
              await axios.put(
                `https://enin-server.ga.highendtradingplc.com/item/update/${item.itemId}`,
                newItem
              );

              newItem.physicalQuantity -= item.quantity;
              await axios.put(
                `https://enin-server.ga.highendtradingplc.com/item/update/${item.itemId}`,
                newItem
              );

              await axios.post(
                "https://enin-server.ga.highendtradingplc.com/quantity/add",
                {
                  itemId: item.itemId,
                  salePurchaseId: newId,
                  quantity: newItem.logicalQuantity,
                }
              );

              await axios.post(
                "https://enin-server.ga.highendtradingplc.com/deliveryquantity/add",
                {
                  itemId: item.itemId,
                  deliveryPurchaseId: newId,
                  quantity: newItem.physicalQuantity,
                }
              );
            }
          })
        );
      } catch (error) {
        console.error("Error saving data", error);
      } finally {
        window.location.reload();
      }
    },
  });

  const clearData = () => {
    price.total = 0;
    price.vat = 0;
    price.withHold = 0;
    price.grandTotal = 0;
    setItemValue([]);
  };

  const handleValidity = (i) => {
    valids[i] = 1;
    setValid([...valids]);
  };

  return (
    <div className="">
      <div className="">
        <Header />
      </div>

      <main style={{ marginTop: "120px" }}>
        <div
          className="tab-container "
          style={{
            marginTop: "-20px",
            marginBottom: "-17px",
          }}
        >
          <Link className="p-2" to={`/sales`}>
            New Sales
          </Link>
          <Link className="p-2" to={`/delivery`}>
            Sales Delivery
          </Link>
          <Link
            className="p-2"
            style={{
              backgroundColor: "white",
              borderRadius: "5px 5px 0 0",
              border: "solid 0.05em rgb(181, 181, 181)",
            }}
            to={`/direct_delivery`}
          >
            Direct Delivery
          </Link>
        </div>
        <div
          className="card card-info card-outline"
          style={{ marginTop: "10px" }}
        >
          <div className="card-header">
            <h4>Direct Delivery</h4>
          </div>

          <div className="card-body" style={{ overflow: "hidden" }}>
            <div
              className="nav-bra pl-3 ml-1 p-2 "
              style={{
                backgroundColor: "rgb(235, 235, 235)",
              }}
            >
              <span>Direct Delivery / Create</span>
            </div>
            <hr />
            <div>
              <div>
                <Form className="">
                  <Form.Group as={Row} controlId="formHorizontalUsername">
                    <Col md={6} className="mb-3">
                      <Autocomplete
                        {...customerData}
                        id="controlled-demo"
                        size="small"
                        variant="outlined"
                        name="customerId"
                        onBlur={(e) => handleValidity(0)}
                        value={supplierValue}
                        onChange={(event, newValue) => {
                          if (!newValue?.Id) {
                            setCustomerCheck(null);
                            return;
                          }
                          setSupplierValue(newValue);
                          formik.values.customerId = newValue?.Id;
                          setCustomerCheck(5);
                        }}
                        style={{ width: "250px", float: "left" }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={valids[0] === 1 && customerCheck === null}
                            label={
                              <div style={{ float: "left" }}>
                                <FontAwesomeIcon
                                  icon={faUser}
                                  style={{ float: "left", marginRight: "10px" }}
                                />
                                <span>Customer</span>
                              </div>
                            }
                          />
                        )}
                      />
                      <Button
                        variant="outline-info"
                        //onClick={openAddModal}
                        style={{
                          width: "30px",
                          height: "40px",
                          marginLeft: "20px",
                          float: "left",
                        }}
                        onClick={handleOpen}
                      >
                        <h2
                          style={{
                            marginTop: "-5px",
                            marginLeft: "-7px",
                          }}
                        >
                          +
                        </h2>
                      </Button>
                    </Col>
                    <Col md={6} className="mb-3">
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.date}
                        type="date"
                        label={
                          <div style={{ float: "left", width: "70px" }}>
                            <FontAwesomeIcon
                              icon={faCalendar}
                              size="1x"
                              style={{
                                float: "left",
                                marginTop: "5px",
                                marginRight: "5px",
                              }}
                            />
                            <h4>Date</h4>
                          </div>
                        }
                        size="small"
                        name="date"
                        error={formik.touched.date && formik.errors.date}
                        helperText={
                          formik.touched.date && formik.errors.date
                            ? `${formik.errors.date}`
                            : ""
                        }
                        style={{ width: "300px" }}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="formHorizontalUsername">
                    <Col md={6}></Col>
                    <Col md={6}>
                      <TextField
                        id="outlined-basic"
                        label={
                          <div style={{ float: "left" }}>
                            <FontAwesomeIcon
                              icon={faCode}
                              style={{ float: "left", marginRight: "10px" }}
                            />
                            <span> FSNumber</span>
                          </div>
                        }
                        variant="outlined"
                        type="text"
                        size="small"
                        name="fsNumber"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.fsNumber}
                        error={
                          formik.touched.fsNumber && formik.errors.fsNumber
                        }
                        helperText={
                          formik.touched.fsNumber && formik.errors.fsNumber
                            ? `${formik.errors.fsNumber}`
                            : ""
                        }
                        style={{ width: "300px" }}
                      />
                    </Col>
                  </Form.Group>
                </Form>
              </div>
              <div className="">
                <hr />
                <h5>Sale Item</h5>
                <hr />
                <Formik
                  initialValues={{
                    itemId: "",
                    quantity: "",
                    price: "",
                    totalprice: "",
                    taxId: "",
                    statusId: "",
                  }}
                  validationSchema={Yup.object().shape({
                    quantity: Yup.string().required("Quantity is required"),
                    price: Yup.string().required("Price is required"),
                  })}
                  onSubmit={async (values) => {
                    try {
                      if (itemCheck === null) {
                        return;
                      }
                      const checkItem = itemValue?.find(
                        (i) => i?.itemId === itemId.Id
                      );
                      if (!!checkItem) {
                        alert("You use the same item!");
                        return;
                      }
                      const itemQuantity = items.find(
                        (i) => i.Id === itemId.Id
                      );
                      if (itemQuantity.statusId === 2) {
                        const countQuantity = deliverys
                          .map((d) => {
                            return d.itemId === itemQuantity.Id
                              ? d.logicalQuantity
                              : 0;
                          })
                          .reduce((acc, crr) => acc + crr, 0);
                        const totalRemainQuantity =
                          parseInt(itemQuantity.logicalQuantity) -
                          (countQuantity + parseInt(values.quantity));
                        if (totalRemainQuantity < 0) {
                          alert(
                            `There is ${
                              parseInt(itemQuantity.logicalQuantity) -
                              countQuantity
                            } quantity only available !`
                          );
                          return;
                        }
                      }
                      update.price = values.price;
                      update.quantity = values.quantity;
                      values.totalprice = update.quantity * update.price;
                      console.log(values);
                      const tax = taxs.find((t) => t.Id === values.taxId);
                      console.log(tax);
                      price.total =
                        parseFloat(price.total) + parseFloat(values.totalprice);
                      price.vat =
                        parseFloat(price.vat) +
                        parseFloat(update.price) *
                          parseFloat(tax.taxValue / 100) *
                          parseFloat(update.quantity);

                      setItemValue([
                        ...itemValue,
                        {
                          itemId: values.itemId,
                          quantity: update.quantity,
                          price: update.price,
                          taxId: values.taxId,
                          totalprice: values.totalprice,
                          statusId: values.statusId,
                        },
                      ]);
                      console.log(itemValue);
                      let service = 0,
                        good = 0;

                      itemValue?.map((iv) => {
                        if (iv.statusId === 1 && parseFloat(iv.price) >= 3000) {
                          service =
                            service +
                            parseFloat(
                              0.02 *
                                parseFloat(iv.price) *
                                parseInt(iv.quantity)
                            );
                        }
                        if (
                          iv.statusId === 2 &&
                          parseFloat(iv.price) >= 10000
                        ) {
                          good =
                            good +
                            parseFloat(
                              0.02 *
                                parseFloat(iv.price) *
                                parseInt(iv.quantity)
                            );
                        }
                      });
                      service += items.find(
                        (i) =>
                          i.Id === values.itemId &&
                          i.statusId === 1 &&
                          parseFloat(values.price) >= 3000
                      )
                        ? parseFloat(0.02 * parseFloat(values.price)) *
                          parseInt(values.quantity)
                        : 0;
                      good += items.find(
                        (i) =>
                          i.Id === values.itemId &&
                          i.statusId === 2 &&
                          parseFloat(values.price) >= 10000
                      )
                        ? parseFloat(0.02 * parseFloat(values.price)) *
                          parseInt(values.quantity)
                        : 0;
                      price.withHold = service + good;
                      price.grandTotal =
                        service + good + price.total + price.vat;
                      setItemId(null);
                    } catch (error) {
                      console.log(error);
                    } finally {
                      values.price = "";
                      values.quantity = "";
                    }
                  }}
                >
                  {(props) => (
                    <Form onSubmit={props.handleSubmit}>
                      <Form.Group as={Row} controlId="formHorizontalUsername">
                        <Col md={4} className="mb-3">
                          <Autocomplete
                            {...itemData}
                            id="controlled-demo"
                            size="small"
                            variant="outlined"
                            name="itemId"
                            value={itemId}
                            onBlur={(e) => handleValidity(1)}
                            onChange={(event, newValue) => {
                              if (!newValue?.Id) {
                                setItemCheck(null);
                                return;
                              }
                              setItemId(newValue);
                              props.values.taxId = newValue?.taxId;
                              props.values.itemId = newValue?.Id;
                              props.values.statusId = newValue.statusId;
                              setItemCheck(5);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                error={valids[1] === 1 && itemCheck === null}
                                label={
                                  <div style={{ float: "left" }}>
                                    <span>Select Item</span>
                                  </div>
                                }
                              />
                            )}
                            error={props.touched.itemId && props.errors.itemId}
                            helperText={
                              props.touched.itemId && props.errors.itemId
                                ? `${props.errors.itemId}`
                                : ""
                            }
                          />
                        </Col>
                        <Col md={3} className="mb-3">
                          <TextField
                            id="outlined-basic"
                            label="Qty"
                            variant="outlined"
                            type="number"
                            size="small"
                            name="quantity"
                            onChange={props.handleChange}
                            value={props.values.quantity}
                            onBlur={props.handleBlur}
                            error={
                              props.touched.quantity && props.errors.quantity
                            }
                            helperText={
                              props.touched.quantity && props.errors.quantity
                                ? `${props.errors.quantity}`
                                : ""
                            }
                            style={{ width: "200px" }}
                          />
                        </Col>
                        <Col md={3} className="mb-3">
                          <TextField
                            id="outlined-basic"
                            variant="outlined"
                            label="Price"
                            type="text"
                            size="small"
                            name="price"
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            value={props.values.price}
                            error={props.touched.price && props.errors.price}
                            helperText={
                              props.touched.price && props.errors.price
                                ? `${props.errors.price}`
                                : ""
                            }
                            style={{ width: "200px" }}
                          />
                        </Col>
                        <Col md={2} className="mb-3">
                          <Button
                            variant="outline-info"
                            style={{ width: "150px", height: "35px" }}
                            type="submit"
                          >
                            <h3
                              style={{
                                float: "left",
                                marginTop: "-6px",
                                marginRight: "5px",
                              }}
                            >
                              +
                            </h3>
                            <h6 style={{ float: "left", marginTop: "1px" }}>
                              Add To List
                            </h6>
                          </Button>
                        </Col>
                      </Form.Group>
                    </Form>
                  )}
                </Formik>
              </div>
              <div>
                <div style={{ overflow: "scroll" }}>
                  <table className="summary-table">
                    <thead className="bg-info">
                      <tr>
                        <th>Id</th>
                        <th>Code</th>
                        <th>Desc</th>
                        <th>Model</th>
                        <th>Unit</th>
                        <th>Qty</th>
                        <th>U.Price</th>
                        <th>T.Price</th>
                        <th style={{ minWidth: "200px" }}>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {itemValue.map((request, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            {items.map((item) =>
                              item.Id === parseInt(request.itemId)
                                ? item.code
                                : ""
                            )}
                          </td>

                          <td>
                            {items.map((item) =>
                              item.Id === parseInt(request.itemId)
                                ? item.description
                                : ""
                            )}
                          </td>
                          <td>
                            {items.map((item) =>
                              item.Id === parseInt(request.itemId)
                                ? models.map((model) =>
                                    model.Id === parseInt(item.modelId)
                                      ? model.modelName
                                      : ""
                                  )
                                : ""
                            )}
                          </td>
                          <td>
                            {items.map((item) =>
                              item.Id === parseInt(request.itemId)
                                ? units.map((unit) =>
                                    unit.Id === parseInt(item.unitId)
                                      ? unit.unitName
                                      : ""
                                  )
                                : ""
                            )}
                          </td>

                          <td>
                            {openUpdate && request.itemId === update.itemId ? (
                              <input
                                type="number"
                                minLength={1}
                                maxLength={20}
                                className="form-control"
                                required
                                style={{ width: "10vw" }}
                                value={
                                  update.quantity === ""
                                    ? request.quantity
                                    : update.quantity
                                }
                                onChange={(e) => {
                                  if (
                                    parseInt(e.target.value) < 1 ||
                                    parseInt(e.target.value) > 20
                                  ) {
                                    setUpdate((prev) => ({
                                      ...prev,
                                      quantity: 1,
                                    }));
                                  } else {
                                    setUpdate((prev) => ({
                                      ...prev,
                                      quantity: parseInt(e.target.value),
                                    }));
                                  }
                                }}
                              />
                            ) : (
                              request.quantity
                            )}
                          </td>
                          <td>
                            {openUpdate && request.itemId === update.itemId ? (
                              <input
                                type="number"
                                className={
                                  parseFloat(update.price) < 50
                                    ? "form-control is-invalid"
                                    : "form-control"
                                }
                                minLength={100}
                                maxLength={1000000}
                                required
                                value={
                                  update.price === ""
                                    ? request.price
                                    : update.price
                                }
                                style={{
                                  margin: "0 ",
                                  width: "19vw",
                                }}
                                onChange={(e) => {
                                  if (
                                    parseFloat(e.target.value) < 1 ||
                                    parseFloat(e.target.value) > 1000000
                                  ) {
                                    setUpdate((prev) => ({
                                      ...prev,
                                      price: 1,
                                    }));
                                  } else {
                                    setUpdate((prev) => ({
                                      ...prev,
                                      price: parseFloat(e.target.value),
                                    }));
                                  }

                                  setItemValue([...itemValue]);
                                }}
                              />
                            ) : (
                              request.price
                            )}
                          </td>
                          <td>{request.totalprice}</td>
                          <td>
                            {(request.itemId !== update.itemId ||
                              !openUpdate) && (
                              <div>
                                <Button
                                  variant="outline-warning"
                                  className="mr-3"
                                  onClick={(e) => {
                                    setUpdate({
                                      itemId: request.itemId,
                                      price: "",
                                      quantity: "",
                                    });

                                    setOpenUpdate(true);
                                  }}
                                >
                                  <div
                                    className="row "
                                    style={{
                                      marginBottom: "-10px",
                                      marginLeft: "-10px",
                                    }}
                                  >
                                    <div className="col">
                                      <FontAwesomeIcon
                                        style={{ float: "right" }}
                                        icon={faPenToSquare}
                                      />
                                    </div>
                                    <div className="col-6">
                                      <h6
                                        style={{
                                          float: "left",
                                          marginLeft: "-10px",
                                        }}
                                      >
                                        Edit
                                      </h6>
                                    </div>
                                  </div>
                                </Button>
                                <Button
                                  variant="outline-danger"
                                  onClick={(e) => {
                                    const newItem = itemValue.find(
                                      (i) =>
                                        parseInt(i.itemId) ===
                                        parseInt(request.itemId)
                                    );

                                    const tax = taxs.find(
                                      (t) => t.Id === newItem?.taxId
                                    );
                                    const priceVat =
                                      newItem?.quantity *
                                      parseFloat(newItem?.price) *
                                      parseFloat(tax?.taxValue / 100);
                                    const newItemValue = itemValue?.filter(
                                      (i) =>
                                        parseInt(i.itemId) !==
                                        parseInt(request.itemId)
                                    );
                                    setItemValue(newItemValue);
                                    price.vat =
                                      parseFloat(price.vat) - priceVat;
                                    price.total =
                                      parseFloat(price.total) -
                                      parseFloat(newItem.price) *
                                        newItem.quantity;
                                    price.withHold =
                                      parseFloat(price.withHold) -
                                      (newItem.statusId === 1 &&
                                      parseFloat(newItem.price) >= 3000
                                        ? parseFloat(newItem.price) *
                                          0.02 *
                                          parseFloat(newItem.quantity)
                                        : newItem.statusId === 2 &&
                                          parseFloat(newItem.price) >= 10000
                                        ? parseFloat(newItem.price) *
                                          0.02 *
                                          parseFloat(newItem.quantity)
                                        : 0);
                                    price.grandTotal = price.vat + price.total;
                                  }}
                                >
                                  <div
                                    className="row"
                                    style={{
                                      marginBottom: "-10px",
                                      marginLeft: "-10px",
                                    }}
                                  >
                                    <div className="col">
                                      <FontAwesomeIcon
                                        style={{
                                          float: "right",
                                          marginRight: "-5px",
                                        }}
                                        icon={faTrashCan}
                                      />
                                    </div>
                                    <div className="col-8">
                                      <h6
                                        style={{
                                          float: "left",
                                          marginLeft: "-3px",
                                        }}
                                      >
                                        Delete
                                      </h6>
                                    </div>
                                  </div>
                                </Button>
                              </div>
                            )}

                            {openUpdate && request.itemId === update.itemId && (
                              <div>
                                <Button
                                  variant="outline-success"
                                  className="mr-3"
                                  onClick={(e) => {
                                    if (
                                      parseFloat(update.price) < 50 ||
                                      parseFloat(update.price) > 1000000
                                    ) {
                                      return;
                                    }
                                    const itemQuantity = items.find(
                                      (i) => i.Id === update.itemId
                                    );
                                    if (itemQuantity.statusId === 2) {
                                      const countQuantity = deliverys
                                        .map((d) => {
                                          return d.itemId === itemQuantity.Id
                                            ? d.remainQuantity
                                            : 0;
                                        })
                                        .reduce((acc, crr) => acc + crr, 0);
                                      const totalRemainQuantity =
                                        parseInt(itemQuantity.quantity) -
                                        (countQuantity +
                                          parseInt(update.quantity));
                                      if (totalRemainQuantity < 0) {
                                        alert(
                                          `There is ${
                                            parseInt(itemQuantity.quantity) -
                                            countQuantity
                                          } quantity only available !`
                                        );
                                        return;
                                      }
                                    }

                                    price.grandTotal = 0;
                                    price.total = 0;
                                    price.vat = 0;
                                    console.log("object");
                                    itemValue[index].price = !!update.price
                                      ? parseFloat(update.price)
                                      : parseFloat(itemValue[index].price);
                                    itemValue[index].quantity =
                                      !!update.quantity
                                        ? parseInt(update.quantity)
                                        : parseFloat(itemValue[index].quantity);
                                    itemValue[index].totalprice =
                                      itemValue[index].price *
                                      itemValue[index].quantity;
                                    setItemValue([...itemValue]);
                                    update.itemId = "";
                                    update.price = "";
                                    update.quantity = "";
                                    let service = 0,
                                      good = 0;
                                    itemValue.map((iv) => {
                                      const tax = taxs.find(
                                        (t) => t.Id === iv.taxId
                                      );
                                      if (
                                        iv.statusId === 1 &&
                                        parseFloat(iv.price) >= 3000
                                      ) {
                                        service =
                                          service +
                                          parseFloat(
                                            0.02 *
                                              parseFloat(iv.price) *
                                              parseInt(iv.quantity)
                                          );
                                      }
                                      if (
                                        iv.statusId === 2 &&
                                        parseFloat(iv.price) >= 10000
                                      ) {
                                        good =
                                          good +
                                          parseFloat(
                                            0.02 *
                                              parseFloat(iv.price) *
                                              parseInt(iv.quantity)
                                          );
                                      }
                                      price.vat =
                                        parseFloat(price.vat) +
                                        parseFloat(iv.price) *
                                          parseFloat(iv.quantity) *
                                          parseFloat(
                                            parseInt(tax.taxValue) / 100
                                          );
                                      price.total =
                                        parseFloat(price.total) +
                                        parseFloat(iv.price) * iv.quantity;
                                    });

                                    price.withHold = good + service;
                                    price.grandTotal =
                                      price.total + price.vat + service + good;
                                  }}
                                >
                                  <div
                                    className="row "
                                    style={{
                                      marginBottom: "-10px",
                                      marginLeft: "-20px",
                                    }}
                                  >
                                    <div className="col">
                                      <FontAwesomeIcon
                                        style={{ float: "right" }}
                                        className="fa-regular"
                                        icon={faFloppyDisk}
                                      />
                                    </div>
                                    <div className="col-6">
                                      <h6
                                        style={{
                                          float: "left",
                                          marginLeft: "-10px",
                                        }}
                                      >
                                        Save
                                      </h6>
                                    </div>
                                  </div>
                                </Button>
                                <Button
                                  variant="outline-danger"
                                  onClick={(e) => {
                                    setOpenUpdate(false);
                                    setUpdate({
                                      itemId: "",
                                      price: "",
                                      quantity: "",
                                    });
                                  }}
                                >
                                  <div
                                    className="row"
                                    style={{
                                      marginBottom: "-10px",
                                      marginLeft: "-20px",
                                    }}
                                  >
                                    <div className="col">
                                      <FontAwesomeIcon
                                        style={{
                                          float: "right",
                                          marginRight: "-5px",
                                        }}
                                        icon={faXmark}
                                      />
                                    </div>
                                    <div className="col-8">
                                      <h6
                                        style={{
                                          float: "left",
                                          marginLeft: "-3px",
                                        }}
                                      >
                                        Cancle
                                      </h6>
                                    </div>
                                  </div>
                                </Button>
                              </div>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="row">
                    <div className="col"></div>
                    <div
                      className="col mt-1"
                      style={{
                        textAlign: "right",
                        marginBottom: "20px",
                      }}
                    >
                      <Form.Group
                        as={Row}
                        controlId="formHorizontalUsername"
                        style={{ width: "500px" }}
                      >
                        <Col>
                          <Form.Label>Total :</Form.Label>
                        </Col>
                        <Col>
                          <Form.Control
                            id="outlined-basic"
                            disabled
                            label="Qty"
                            variant="outlined"
                            type="text"
                            size="small"
                            name="qty"
                            value={price.total}
                            style={{ width: "150px", height: "25px" }}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group
                        as={Row}
                        controlId="formHorizontalUsername"
                        style={{ width: "500px" }}
                      >
                        <Col>
                          <Form.Label>VAT :</Form.Label>
                        </Col>
                        <Col>
                          <Form.Control
                            id="outlined-basic"
                            disabled
                            label="Qty"
                            variant="outlined"
                            type="text"
                            size="small"
                            name="qty"
                            value={price.vat}
                            style={{ width: "150px", height: "25px" }}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group
                        as={Row}
                        controlId="formHorizontalUsername"
                        style={{ width: "500px" }}
                      >
                        <Col>
                          <Form.Label>With Hold :</Form.Label>
                        </Col>
                        <Col>
                          <Form.Control
                            id="outlined-basic"
                            disabled
                            label="Qty"
                            variant="outlined"
                            type="text"
                            size="small"
                            name="qty"
                            value={price.withHold}
                            style={{ width: "150px", height: "25px" }}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group
                        as={Row}
                        controlId="formHorizontalUsername"
                        style={{ width: "500px" }}
                      >
                        <Col>
                          <Form.Label>Grand Total :</Form.Label>
                        </Col>
                        <Col>
                          <Form.Control
                            id="outlined-basic"
                            disabled
                            label="Qty"
                            variant="outlined"
                            type="text"
                            size="small"
                            name="qty"
                            value={price.grandTotal}
                            style={{ width: "150px", height: "25px" }}
                          />
                        </Col>
                      </Form.Group>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Form onSubmit={formik.handleSubmit}>
              <div style={{ float: "none" }} className="row">
                <Button
                  style={{
                    width: "42%",
                    marginLeft: "4%",
                    height: "40px",
                  }}
                  variant="outline-primary"
                  onClick={clearData}
                >
                  <div className="row mt-1">
                    <div className="col">
                      <FontAwesomeIcon
                        style={{ float: "right" }}
                        icon={faBackwardStep}
                      />
                    </div>
                    <div className="col-8">
                      <h6 style={{ float: "left" }}>Back To List</h6>
                    </div>
                  </div>
                </Button>
                <Button
                  style={{
                    width: "42%",
                    marginLeft: "10%",
                    height: "40px",
                  }}
                  variant="outline-primary"
                  type="submit"
                >
                  <div className="row mt-1">
                    <div className="col">
                      <FontAwesomeIcon
                        style={{ float: "right" }}
                        icon={faFloppyDisk}
                      />
                    </div>
                    <div className="col-7">
                      <h6 style={{ float: "left" }}>Submit</h6>
                    </div>
                  </div>
                </Button>
              </div>
            </Form>
          </div>
        </div>
        <Formik
          initialValues={{
            fullName: "",
            tinNumber: "",
            address: "",
            phoneNumber: "",
          }}
          validationSchema={Yup.object().shape({
            fullName: Yup.string().required("Name is required"),
            phoneNumber: Yup.string().required("Phone Number is required"),
            address: Yup.string().required("Address is required"),
            tinNumber: Yup.string().required("Tin Number is required"),
          })}
          onSubmit={async (values) => {
            try {
              await axios.post(
                "https://enin-server.ga.highendtradingplc.com/customer/add",
                values
              );
            } catch (error) {
              console.log(error);
            } finally {
              fetchAllData();
              setOpen(false);
              values.fullName = "";
              values.tinNumber = "";
              values.address = "";
              values.phoneNumber = "";
            }
          }}
        >
          {(props) => (
            <Modal
              show={open}
              onHide={handleOpen}
              style={{ minWidth: "700px" }}
            >
              <Modal.Header closeButton>
                <span>Sales / Customer / Create</span>
              </Modal.Header>
              <Modal.Body>
                <Form className="m-2 ml-5 pl-5" onSubmit={props.handleSubmit}>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formHorizontalUsername"
                  >
                    <Col sm={8}>
                      <TextField
                        id="outlined-basic"
                        label="Full Name"
                        variant="outlined"
                        size="small"
                        type="text"
                        name="fullName"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.fullName}
                        isInvalid={
                          props.touched.fullName && props.errors.fullName
                        }
                        style={{ maxWidth: "300px" }}
                      />

                      {props.touched.fullName && props.errors.fullName && (
                        <div className="text-danger mt-1">
                          {props.errors.fullName}
                        </div>
                      )}
                    </Col>
                  </Form.Group>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formHorizontalUsername"
                  >
                    <Col sm={8}>
                      <TextField
                        id="outlined-basic"
                        label="Till Number"
                        variant="outlined"
                        size="small"
                        type="text"
                        name="tinNumber"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.tinNumber}
                        isInvalid={
                          props.touched.tinNumber && props.errors.tinNumber
                        }
                        style={{ maxWidth: "300px" }}
                      />

                      {props.touched.tinNumber && props.errors.tinNumber && (
                        <div className="text-danger mt-1">
                          {props.errors.tinNumber}
                        </div>
                      )}
                    </Col>
                  </Form.Group>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formHorizontalUsername"
                  >
                    <Col sm={8}>
                      <TextField
                        id="outlined-basic"
                        label="Phone Number"
                        variant="outlined"
                        size="small"
                        type="text"
                        name="phoneNumber"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.phoneNumber}
                        isInvalid={
                          props.touched.phoneNumber && props.errors.phoneNumber
                        }
                        style={{ maxWidth: "300px" }}
                      />

                      {props.touched.phoneNumber &&
                        props.errors.phoneNumber && (
                          <div className="text-danger mt-1">
                            {props.errors.phoneNumber}
                          </div>
                        )}
                    </Col>
                  </Form.Group>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formHorizontalUsername"
                  >
                    <Col sm={8}>
                      <TextField
                        id="outlined-basic"
                        label="Address"
                        variant="outlined"
                        size="small"
                        type="text"
                        name="address"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.address}
                        isInvalid={
                          props.touched.address && props.errors.address
                        }
                        style={{ maxWidth: "300px" }}
                      />

                      {props.touched.address && props.errors.address && (
                        <div className="text-danger mt-1">
                          {props.errors.address}
                        </div>
                      )}
                    </Col>
                  </Form.Group>
                  <Form.Group
                    as={Row}
                    className="mb-3"
                    controlId="formHorizontalUsername"
                  >
                    <Col sm={12}>
                      <Button
                        style={{ width: "62%" }}
                        variant="outline-success"
                        type="submit"
                      >
                        Create
                      </Button>
                    </Col>
                  </Form.Group>
                </Form>
              </Modal.Body>
            </Modal>
          )}
        </Formik>
        <Modal
          show={printView}
          onHide={() => {
            setPrintView(false);
          }}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <ReactToPrint
                trigger={() => <Button color="primary">Print</Button>}
                content={() => componentRef.current}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container ref={componentRef}>
              <div className="row">
                <div className="col-sm-6 ">
                  <br />
                  <h1 style={{ marginLeft: "5vw", marginBottom: "2vh" }}>
                    Invoice
                  </h1>
                  <i
                    style={{ marginLeft: "7vw", marginBottom: "2vh" }}
                    className="fas fa-home fa-3x"
                  />
                </div>

                <div className="col-sm-6 ">
                  <br />
                  <div
                    style={{
                      fontSize: 18,
                      textAlign: "right",
                    }}
                  >
                    <p style={{ margin: "0", fontSize: 24 }}>
                      <b> Company Name</b>
                    </p>
                    <p style={{ margin: "0" }}>+251987654321</p>
                    <p style={{ margin: "0" }}>email@gmail.com</p>
                    <p style={{ margin: "0" }}>address1</p>
                    <p style={{ margin: "0" }}>address2</p>
                  </div>
                </div>
              </div>

              <hr />
              <Row className="">
                <Col sm={2}>
                  <p>
                    <b>BILL To</b>
                  </p>
                </Col>

                <Col sm={5}>
                  <div>
                    <p>
                      <b>Name : </b>
                      {supplierValue?.fullName}
                    </p>
                  </div>
                  <div>
                    <p>
                      <b>Phone No : </b>
                      {supplierValue?.phoneNumber}
                    </p>
                  </div>
                </Col>
                <Col sm={5}>
                  <div>
                    <p>
                      <b>RefId : </b>
                      {`1111111`}
                    </p>
                  </div>
                  <div>
                    <p>
                      <b>Date : </b>
                      {`${new Date().getDate()}/${
                        new Date().getMonth() + 1
                      }/${new Date().getFullYear()}`}
                    </p>
                  </div>
                </Col>
              </Row>
              <hr />
              <Row>
                <Table className="text-center" style={{ width: "100%" }}>
                  <tr>
                    <th>No</th>
                    <th>Desc</th>
                    <th>Model</th>
                    <th>Unit</th>
                    <th>Qty</th>
                    <th>Recived Qty</th>
                    <th>U.Price</th>
                    <th>T.Price</th>
                  </tr>

                  <tbody>
                    {itemValue.map((request, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>

                        <td>
                          {items.map((item) =>
                            item.Id === parseInt(request.itemId)
                              ? item.description
                              : ""
                          )}
                        </td>
                        <td>
                          {items.map((item) =>
                            item.Id === parseInt(request.itemId)
                              ? models.map((model) =>
                                  model.Id === parseInt(item.modelId)
                                    ? model.modelName
                                    : ""
                                )
                              : ""
                          )}
                        </td>
                        <td>
                          {items.map((item) =>
                            item.Id === parseInt(request.itemId)
                              ? units.map((unit) =>
                                  unit.Id === parseInt(item.unitId)
                                    ? unit.unitName
                                    : ""
                                )
                              : ""
                          )}
                        </td>

                        <td>{request.quantity}</td>
                        <td>{request.statusId === 2 ? 0 : request.quantity}</td>
                        <td>{request.price}</td>
                        <td>{request.totalprice}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Row>
              <Row>
                <Col sm={8}>
                  <h3>Terms</h3>
                </Col>
                <Col sm={4}>
                  <Table>
                    <tbody>
                      <tr style={{}}>
                        <td>
                          <b
                            style={{
                              float: "right",
                              fontSize: 18,
                              width: "100px",
                              height: "10px",
                            }}
                          >
                            Total Price
                          </b>
                        </td>
                        <td>
                          <p
                            style={{
                              float: "left",
                              fontSize: 18,
                              width: "110px",
                              height: "10px",
                            }}
                          >
                            {price.total}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b
                            style={{
                              float: "right",
                              fontSize: 18,
                              width: "100px",
                              height: "10px",
                            }}
                          >
                            VAT
                          </b>
                        </td>
                        <td>
                          <p
                            style={{
                              float: "left",
                              fontSize: 18,
                              width: "110px",
                              height: "10px",
                            }}
                          >
                            {price.vat}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b
                            style={{
                              float: "right",
                              fontSize: 18,
                              width: "100px",
                              height: "10px",
                            }}
                          >
                            With Hold
                          </b>
                        </td>
                        <td>
                          <p
                            style={{
                              float: "left",
                              fontSize: 18,
                              width: "110px",
                              height: "10px",
                            }}
                          >
                            {" "}
                            {price.withHold}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b
                            style={{
                              float: "right",
                              fontSize: 18,
                              width: "100px",
                              height: "10px",
                            }}
                          >
                            Grand Total
                          </b>
                        </td>
                        <td>
                          <p
                            style={{
                              float: "left",
                              fontSize: 18,
                              width: "110px",
                              height: "10px",
                            }}
                          >
                            {price.grandTotal}
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
        </Modal>
      </main>
    </div>
  );
}

export default DirectDelivery;
