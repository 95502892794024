/** @format */

import React, { useEffect, useState } from "react";
import { Button, Modal, Form, Col, Row } from "react-bootstrap";
import { useFormik } from "formik";
import TextField from "@mui/material/TextField";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import * as Yup from "yup";
import axios from "axios";
import Header from "../Header";
import { faPenToSquare, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem"; // Import MenuItem for the role selection

const validationSchema = Yup.object().shape({
  fullName: Yup.string()
    .required("Full Name is required")
    .min(3, "Too Short!")
    .max(50, "Too Long"),
  userName: Yup.string()
    .required("User Name is required")
    .min(3, "Too Short!")
    .max(30, "Too Long"),
  email: Yup.string()
    .required("Email is required")
    .min(10, "Too Short!")
    .max(30, "Too Long"),

  phoneNumber: Yup.string()
    .required("Phone Number is required")
    .min(9, "Invalid phone number")
    .max(10, "Too Long!"),
  roleId: Yup.string().required("Role is required"),
});

const UserMng = () => {
  const [open, setOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const fetchAllData = async () => {
    try {
      const [user, role] = await Promise.all([
        axios.get("https://enin-server.ga.highendtradingplc.com/user"),
        axios.get("https://enin-server.ga.highendtradingplc.com/role"),
      ]);
      setUsers(user.data);
      setRoles(role.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAllData();
  }, []);

  const formik = useFormik({
    initialValues: {
      fullName: "",
      userName: "",
      password: "",
      phoneNumber: "",
      roleId: "",
      email: "",
      id: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      const { userName } = values;
      const userExists = users.find(
        (user) => user.userName === userName && user.Id !== values.id
      );
      if (userExists) {
        formik.setErrors({
          userName: "User name already exists.",
        });
        return;
      }

      try {
        if (values.id === "") {
          await axios.post(
            "https://enin-server.ga.highendtradingplc.com/user/add",
            values
          );
          alert("User added successfully!");
          setOpen(false);
        } else {
          const user = users.find((u) => u.Id === parseInt(values.id));

          await axios.put(
            `https://enin-server.ga.highendtradingplc.com/user/update/${values.id}`,
            {
              fullName: values.fullName,
              userName: values.userName,
              phoneNumber: values.phoneNumber,
              roleId: values.roleId,
              email: values.email,
              check: 4,
              verify: user.email === values.email ? user.verfiy : false,
            }
          );
          alert("User Update successfully!");
          setOpen(false);
        }
      } catch (error) {
        console.error(error);
      } finally {
        fetchAllData();
      }
    },
  });

  const handleAdd = () => {
    formik.resetForm();
    setOpen(true);
  };

  const handleUpdate = (id) => {
    const user = users.find((usr) => usr.Id === parseInt(id));
    formik.setValues({
      fullName: user.fullName,
      userName: user.userName,
      phoneNumber: user.phoneNumber,
      roleId: user.roleId,
      email: user.email,
      password: "12345",
      id: parseInt(id),
    });
    setOpen(true);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(
        `https://enin-server.ga.highendtradingplc.com/user/delete/${id}`
      );
      fetchAllData();
    } catch (error) {
      console.error(error);
    }
  };

  const handleOpen = () => {
    setOpen(false);
  };

  const columns = [
    { field: "Id", headerName: "ID", minWidth: 80 },
    { field: "fullName", headerName: "Full Name", minWidth: 150 },
    { field: "userName", headerName: "User Name", minWidth: 150 },
    { field: "email", headerName: "Email", minWidth: 150 },
    { field: "phoneNumber", headerName: "Phone Number", minWidth: 150 },
    { field: "createdAt", headerName: "Created At", minWidth: 150 },
    { field: "updatedAt", headerName: "Updated At", minWidth: 150 },
    { field: "roleId", headerName: "Role ID", minWidth: 100 },
    {
      field: "action",
      headerName: "Action",
      minWidth: 240,
      renderCell: (params) => (
        <>
          <Button
            onClick={() => handleUpdate(params.row.Id)}
            variant="outline-warning"
            className="m-1"
          >
            <div
              className="row"
              style={{
                marginBottom: "-10px",
                marginLeft: "-10px",
              }}
            >
              <div className="col">
                <FontAwesomeIcon
                  style={{ float: "right", marginRight: "-5px" }}
                  icon={faPenToSquare}
                />
              </div>
              <div className="col-7">
                <h6
                  style={{
                    float: "left",
                    marginLeft: "-3px",
                  }}
                >
                  Edit
                </h6>
              </div>
            </div>
          </Button>
          <Button
            onClick={() => handleDelete(params.row.Id)}
            variant="outline-danger"
            className=""
          >
            <div
              className="row "
              style={{
                marginBottom: "-10px",
                marginLeft: "-10px",
              }}
            >
              <div className="col">
                <FontAwesomeIcon
                  style={{ float: "right", marginRight: "-5px" }}
                  icon={faTrashCan}
                />
              </div>
              <div className="col-8">
                <h6 style={{ float: "left", marginLeft: "-3px" }}>Delete</h6>
              </div>
            </div>
          </Button>
        </>
      ),
    },
  ];

  return (
    <div>
      <Header />
      <main style={{ marginTop: "120px" }}>
        {/* Navigation Links */}
        <div
          className="tab-container"
          style={{ marginTop: "-20px", marginBottom: "-5px" }}
        >
          <Link
            className="p-2"
            to={`/user_mgt`}
            style={{
              backgroundColor: "white",
              borderRadius: "5px 5px 0 0",
              border: "solid 0.05em rgb(181, 181, 181)",
            }}
          >
            User Mgt
          </Link>
          <Link className="p-2" to={`/role_mgt`}>
            Role Mgt
          </Link>
          <Link className="p-2" to={`/account_mgt`}>
            Account Mgt
          </Link>
        </div>

        {/* User Management Section */}
        <div className="card card-info card-outline">
          <div className="card-header pl-2">
            <Button
              variant="outline-info"
              style={{ width: "150px", height: "35px" }}
              onClick={handleAdd}
            >
              <h3
                style={{ float: "left", marginTop: "-6px", marginRight: "5px" }}
              >
                +
              </h3>
              <h6 style={{ float: "left", marginTop: "1px" }}>Create New</h6>
            </Button>
          </div>

          {/* Data Grid */}
          <DataGrid
            rows={users}
            columns={columns}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            components={{
              Toolbar: GridToolbar,
            }}
            initialState={{
              ...users.initialState,
              pagination: {
                paginationModel: { pageSize: 5 },
              },
            }}
            pageSizeOptions={[5, 10, 15]}
            getRowId={(row) => row.Id}
          />
        </div>

        {/* Modal for Create/Update User */}
        <Modal show={open} onHide={handleOpen} style={{ minWidth: "700px" }}>
          <Modal.Header closeButton>
            <span>
              Home / Users / {formik.values.id === "" ? "Create" : "Update"}
            </span>
          </Modal.Header>
          <Modal.Body>
            <Form className="m-2 ml-5 pl-5" onSubmit={formik.handleSubmit}>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formHorizontalFullName"
              >
                <Col sm={8}>
                  <TextField
                    label="Full Name"
                    variant="outlined"
                    size="small"
                    type="text"
                    name="fullName"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.fullName}
                    error={formik.touched.fullName && formik.errors.fullName}
                    helperText={
                      formik.touched.fullName && formik.errors.fullName
                    }
                    style={{ maxWidth: "300px" }}
                  />
                </Col>
              </Form.Group>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formHorizontalUserName"
              >
                <Col sm={8}>
                  <TextField
                    label="User Name"
                    variant="outlined"
                    size="small"
                    type="text"
                    name="userName"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.userName}
                    error={formik.touched.userName && formik.errors.userName}
                    helperText={
                      formik.touched.userName && formik.errors.userName
                    }
                    style={{ maxWidth: "300px" }}
                  />
                </Col>
              </Form.Group>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formHorizontalUserName"
              >
                <Col sm={8}>
                  <TextField
                    label="Email"
                    variant="outlined"
                    size="small"
                    type="email"
                    name="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    error={formik.touched.email && formik.errors.email}
                    helperText={formik.touched.email && formik.errors.email}
                    style={{ maxWidth: "300px" }}
                  />
                </Col>
              </Form.Group>
              {formik.values.id === "" && (
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formHorizontalPassword"
                >
                  <Col sm={8}>
                    <TextField
                      label="Password"
                      variant="outlined"
                      size="small"
                      type="password"
                      name="password"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.password}
                      error={formik.touched.password && formik.errors.password}
                      helperText={
                        formik.touched.password && formik.errors.password
                      }
                      style={{ maxWidth: "300px" }}
                    />
                  </Col>
                </Form.Group>
              )}
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formHorizontalPhoneNumber"
              >
                <Col sm={8}>
                  <TextField
                    label="Phone Number"
                    variant="outlined"
                    size="small"
                    type="text"
                    name="phoneNumber"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.phoneNumber}
                    error={
                      formik.touched.phoneNumber && formik.errors.phoneNumber
                    }
                    helperText={
                      formik.touched.phoneNumber && formik.errors.phoneNumber
                    }
                    style={{ maxWidth: "300px" }}
                  />
                </Col>
              </Form.Group>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formHorizontalRoleId"
              >
                <Col sm={8}>
                  <TextField
                    label="Role"
                    variant="outlined"
                    size="small"
                    select
                    name="roleId"
                    fullWidth
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.roleId}
                    error={formik.touched.roleId && !!formik.errors.roleId}
                    helperText={formik.touched.roleId && formik.errors.roleId}
                  >
                    <MenuItem value="">Select Role</MenuItem>
                    {roles.map((role) => (
                      <MenuItem key={role.Id} value={role.Id}>
                        {role.roleName}
                      </MenuItem>
                    ))}
                  </TextField>
                </Col>
              </Form.Group>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formHorizontalActions"
              >
                <Col sm={8}>
                  <Button
                    type="submit"
                    variant="outline-primary"
                    style={{ width: "150px" }}
                  >
                    {formik.values.id === "" ? "Create" : "Update"}
                  </Button>
                </Col>
              </Form.Group>
            </Form>
          </Modal.Body>
        </Modal>
      </main>
    </div>
  );
};

export default UserMng;
