/** @format */

// import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "./page/HomePage";
import CustomerPage from "./page/CustomerPage";
import SupplierPage from "./page/SupplierPage";
import InventoryPage from "./page/InventoryPage";
import PurchasePage from "./page/PurchasePage";
import SalesPage from "./page/SalesPage";
import QuantityOnHand from "./page/QuantityOnHand";
import PurchaseReport from "./page/purchaseReport";
import SalesReport from "./page/SalesReport";
import StockReport from "./page/StockReport";
import LoginHistory from "./page/LoginHistory";
import UserMng from "./page/UserMng";
import RoleMng from "./page/RoleMng";
import AccountMng from "./page/AccountMng";
import UnitType from "./page/UnitType";
import Adjustment from "./page/Adjustment";
import QuantityReport from "./page/QuantityReport";
import Delivery from "./page/Delivery";
import Login from "./Login";
import DirectDelivery from "./page/DirectDelivery";
const Home = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="/customers" element={<CustomerPage />} />
        <Route path="/supplier" element={<SupplierPage />} />
        <Route path="/inventory" element={<InventoryPage />} />
        <Route path="/sales" element={<SalesPage />} />
        <Route path="/purchase" element={<PurchasePage />} />
        <Route path="/quantity_on_hand/:id" element={<QuantityOnHand />} />
        <Route path="/purchase_report" element={<PurchaseReport />} />
        <Route path="/sales_report" element={<SalesReport />} />
        <Route path="/stock_report" element={<StockReport />} />
        <Route path="/login_history" element={<LoginHistory />} />
        <Route path="/user_mgt" element={<UserMng />} />
        <Route path="/role_mgt" element={<RoleMng />} />
        <Route path="/account_mgt" element={<AccountMng />} />
        <Route path="/adjustment" element={<Adjustment />} />
        <Route path="/inventory/types/:id" element={<UnitType />} />
        <Route path="/quantity_report" element={<QuantityReport />} />
        <Route path="/delivery" element={<Delivery />} />
        <Route path="/direct_delivery" element={<DirectDelivery />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Home;
