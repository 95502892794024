/** @format */

import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Stack from "@mui/material/Stack";
import { Button, Modal, Form, Col } from "react-bootstrap";
import { TextField } from "@mui/material";
import { Formik } from "formik";

const Login = () => {
  const [users, setUser] = useState({
    userName: "",
    password: "",
    email: "",
  });
  const [opens, setOpens] = useState(false);
  const [forget, setForgets] = useState(false);
  const [check, setChecks] = useState({
    email: false,
    userName: true,
  });
  const navigate = useNavigate();
  const handleCheck = (e) => {
    e.preventDefault();
    if (check.email) {
      setChecks((prev) => ({ email: false, userName: true }));
      setUser((prev) => ({ email: "", password: "" }));
    } else if (check.userName) {
      setChecks((prev) => ({ email: true, userName: false }));
      setUser((prev) => ({ userName: "", password: "" }));
    }
  };
  const handelForget = (e) => {
    e.preventDefault();
    setForgets(true);
  };
  const closeForget = (e) => {
    setForgets(false);
  };
  const handelchange = (e) => {
    setUser((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  const handelclick = async (e) => {
    e.preventDefault();
    console.log(users);
    await axios
      .post("https://enin-server.ga.highendtradingplc.com/login", users)
      .then((res) => {
        if (res.data.Login) {
          const datas = { Id: res.data.Id, roleId: res.data.roleId };
          window.sessionStorage.setItem("data", JSON.stringify(datas));
          navigate(`/${res.data.role}`);
        } else {
          setOpens(true);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="">
      <div id="" className="">
        <center>
          <div className="">
            <div className="">
              {!forget && (
                <div
                  className="card card-outline card-info"
                  style={{
                    width: "500px",
                    marginTop: "50px",
                  }}
                >
                  <div className="card-header">
                    <h4 className="mb-2">Welcome ! 👋</h4>
                    {opens && (
                      <Stack sx={{ width: "100%" }} spacing={2}>
                        <Alert severity="error">
                          <AlertTitle>Try Again</AlertTitle>
                          You Use Invalid Password or Username !!!
                        </Alert>
                      </Stack>
                    )}
                  </div>

                  <div className="card-body">
                    <br />
                    <center>
                      <div>
                        <Form id="formAuthentication" className="mb-2">
                          <div className="">
                            <Form.Group className="mb-3 row">
                              <Col sm={12}>
                                {check.userName && (
                                  <TextField
                                    type="text"
                                    className="form-control"
                                    id="email"
                                    name="userName"
                                    size="small"
                                    label="Username"
                                    autofocus
                                    onChange={handelchange}
                                    style={{ width: "350px" }}
                                  />
                                )}{" "}
                                {check.email && (
                                  <TextField
                                    type="email"
                                    className="form-control"
                                    id="email"
                                    name="email"
                                    label="Email"
                                    autofocus
                                    onChange={handelchange}
                                    style={{ width: "350px" }}
                                  />
                                )}
                              </Col>
                            </Form.Group>
                            <Form.Group className="mb-3 row form-password-toggle">
                              <Col sm={12}>
                                <TextField
                                  type="password"
                                  id="password"
                                  className="form-control"
                                  name="password"
                                  size="small"
                                  value={users.password}
                                  label="password"
                                  aria-describedby="password"
                                  onChange={handelchange}
                                  style={{ width: "350px" }}
                                />
                              </Col>
                            </Form.Group>
                          </div>
                          <Form.Group className="mb-1 row">
                            <Col sm={12}>
                              <Button
                                type="submit"
                                onClick={handelclick}
                                style={{ width: "350px" }}
                              >
                                Sign in
                              </Button>
                            </Col>
                          </Form.Group>
                          <center>
                            <Form.Group className="row ">
                              <Col sm={12}>
                                <span>------ or -------</span>
                              </Col>

                              {/* <div className="col">
                          <button
                            className="btn btn-primary d-grid w-10"
                            type="submit"
                            onClick={handleCheck}
                            style={{ maxWidth: "200px", minWidth: "200px" }}
                          >
                            {check.userName && <span>Sign in With Email</span>}
                            {check.email && <span>Sign in With userName</span>}
                          </button>
                        </div> */}
                            </Form.Group>
                          </center>
                          <Form.Group className="row">
                            <Col sm={12}>
                              <button
                                className="btn btn-primary "
                                type="submit"
                                onClick={handelForget}
                                style={{ width: "350px" }}
                              >
                                Forget Password
                              </button>
                            </Col>
                          </Form.Group>
                        </Form>
                      </div>
                    </center>
                  </div>
                </div>
              )}
            </div>
            {forget && (
              <div className=" ">
                <center>
                  <div id="" className="card-body">
                    <div className="">
                      <div className="">
                        <div
                          className="card"
                          style={{
                            width: "500px",
                            marginTop: "50px",
                          }}
                        >
                          <div className="card-header">
                            <h4>Forget Password</h4>
                          </div>
                          <div className="card-body">
                            <Formik
                              initialValues={{
                                email: "",
                              }}
                              onSubmit={async (values) => {
                                try {
                                  await axios
                                    .put(
                                      `https://enin-server.ga.highendtradingplc.com/user/update/${values.email}`,
                                      { email: values.email, check: 3 }
                                    )
                                    .then((res) => {
                                      console.log(res);
                                      if (res.data === 0) {
                                        alert("Your email is not verified !");
                                        values.email = "";
                                      } else if (res.data === 1) {
                                        alert(
                                          "The new password send by email please check your email !"
                                        );
                                        setForgets(false);
                                      } else {
                                        alert("You insert incorrect email !");
                                        values.email = "";
                                      }
                                    });
                                } catch (error) {
                                  console.log(error);
                                }
                              }}
                            >
                              {(props) => (
                                <form
                                  className="mb-3 "
                                  onSubmit={props.handleSubmit}
                                >
                                  <div className="mb-3">
                                    <TextField
                                      type="email"
                                      className="form-control"
                                      id="email"
                                      name="email"
                                      label="Email"
                                      size="small"
                                      value={props.values.email}
                                      onBlur={props.handleBlur}
                                      onChange={props.handleChange}
                                    />
                                  </div>
                                  <div className="row">
                                    <button
                                      className="m-2 btn btn-primary d-grid w-10 col"
                                      type="submit"
                                    >
                                      Send
                                    </button>
                                    <button
                                      className="m-2 btn btn-dark d-grid w-10 col"
                                      onClick={(e) => {
                                        setForgets(false);
                                      }}
                                    >
                                      Back
                                    </button>
                                  </div>
                                </form>
                              )}
                            </Formik>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </center>
              </div>
            )}
          </div>
        </center>
      </div>
    </div>
  );
};

export default Login;
