/** @format */

import React from "react";
import Home from "./Route";
function App() {
  return (
    <div>
      <div>
        <Home />
      </div>
    </div>
  );
}

export default App;
