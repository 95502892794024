/** @format */

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Form, Col, Row, Modal } from "react-bootstrap";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import axios from "axios";
import Header from "../Header";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBackwardStep,
  faFloppyDisk,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
const validationSchema = Yup.object().shape({
  itemId: Yup.string().required("Item Name is required"),
  remainQuantity: Yup.number()
    .required("Quantity is required")
    .min(1, "Quantity must be at least 1")
    .max(1000, "Quantity too large"),
  status: Yup.string().required(" is required"),
});
const Adjustment = () => {
  const [items, setItems] = useState([]);
  const [adjustments, setAdjustments] = useState([]);
  const [units, setUnits] = useState([]);
  const [models, setModels] = useState([]);
  const [open, setOpen] = useState(false);
  const [datas, setDatas] = useState({
    itemId: "",
    remainQuantity: "",
    status: "",
  });
  const [itemValue, setItemValue] = useState([]);
  const fetchAllData = async () => {
    try {
      const [adjustment, item, unit, model] = await Promise.all([
        axios.get("https://enin-server.ga.highendtradingplc.com/adjustment"),
        axios.get("https://enin-server.ga.highendtradingplc.com/item"),
        axios.get("https://enin-server.ga.highendtradingplc.com/unit"),
        axios.get("https://enin-server.ga.highendtradingplc.com/model"),
      ]);
      setItems(item.data);
      setAdjustments(adjustment.data);
      setUnits(unit.data);
      setModels(model.data);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchAllData();
  }, []);
  const formik = useFormik({
    initialValues: {
      itemId: "",
      remainQuantity: "",
      status: "",
    },
    validationSchema,
    onSubmit: (values) => {
      try {
        let newItem = items.find((i) => i.Id === values.itemId);
        if (values.status === 2) {
          if (
            parseInt(newItem.logicalQuantity) -
              parseInt(values.remainQuantity) <
            0
          ) {
            alert(
              `There is ${parseInt(
                newItem.logicalQuantity
              )} quantity only available !`
            );
            return;
          }
        }
        datas.itemId = values.itemId;
        datas.remainQuantity = values.remainQuantity;
        setItemValue([
          ...itemValue,
          {
            itemId: datas.itemId,
            remainQuantity: datas.remainQuantity,
            status: values.status,
          },
        ]);
      } catch (error) {
        console.log(error);
      } finally {
        values.itemId = "";
        values.remainQuantity = "";
      }
    },
  });
  const handleOpen = () => {
    setOpen(!open);
  };

  const columns = [
    {
      field: "Id",
      headerName: "ID",
      align: "center",
      headerAlign: "center",
      width: 50,
    },

    {
      field: "itemId",
      headerName: "Item Name",
      width: 150,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) =>
        items.find((item) => item.Id === params)?.description || "",
    },
    {
      field: "statusId",
      headerName: "Adjustment Type",
      width: 150,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) =>
        `${params === 1 ? "Add Quantity" : "Remove Quantity"}`,
    },
    {
      field: "quantity",
      headerName: "Qty",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "date",
      headerName: "Date",
      width: 200,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) =>
        `${new Date(params).getDate()}/${
          new Date(params).getMonth() + 1
        }/${new Date(params).getFullYear()}`,
    },
  ];

  return (
    <div>
      <Header />
      <main style={{ marginTop: "120px" }}>
        <div
          className="tab-container "
          style={{
            marginTop: "-20px",
            marginBottom: "-5px",
          }}
        >
          <Link className="p-2" to={`/inventory`}>
            Item
          </Link>
          <Link className="p-2" to={`/purchase`}>
            Purchase
          </Link>
          <Link
            className="p-2"
            to={`/adjustment`}
            style={{
              backgroundColor: "white",
              borderRadius: "5px 5px 0 0",
              border: "solid 0.05em rgb(181, 181, 181)",
            }}
          >
            Adjustment
          </Link>
          <Link className="p-2" to={`/quantity_on_hand/${1}`}>
            Qty On Hand
          </Link>
        </div>
        <div className="card card-outline card-info">
          <div className="card-header">
            {!open && (
              <Button
                variant="outline-info"
                style={{ width: "150px", height: "35px" }}
                onClick={handleOpen}
              >
                <h3
                  style={{
                    float: "left",
                    marginTop: "-6px",
                    marginRight: "5px",
                  }}
                >
                  +
                </h3>
                <h6 style={{ float: "left", marginTop: "1px" }}> Create New</h6>
              </Button>
            )}
            {open && (
              <Button
                onClick={handleOpen}
                style={{ float: "right", height: "30px", width: "30px" }}
                variant="outline-danger"
              >
                <h3
                  style={{
                    float: "left",
                    marginTop: "-8px",
                    marginLeft: "-7px",
                  }}
                >
                  X
                </h3>
              </Button>
            )}
          </div>
          <div className="card-body">
            <div>
              <div
                className="nav-bra pl-3 ml-1 p-2"
                style={{
                  backgroundColor: "rgb(235, 235, 235)",
                }}
              >
                <span>Home / Adjustment {open && "/ Create"}</span>
              </div>
              <hr />
            </div>
            {!open && (
              <DataGrid
                rows={adjustments}
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                columns={columns}
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                  },
                }}
                pageSize={5}
                getRowId={(row) => row.Id}
              />
            )}
            {open && (
              <div>
                <Form onSubmit={formik.handleSubmit}>
                  <Form.Group as={Row} className="mb-3">
                    <Col md={3} className="mb-2">
                      <TextField
                        label="Item Name"
                        variant="outlined"
                        size="small"
                        select
                        name="itemId"
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.itemId}
                        error={formik.touched.itemId && formik.errors.itemId}
                        helperText={
                          formik.touched.itemId && formik.errors.itemId
                        }
                      >
                        <MenuItem value="">Select Item</MenuItem>
                        {items.map((item) => (
                          <MenuItem key={item.Id} value={item.Id}>
                            {item.description}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Col>
                    <Col md={3} className="mb-2">
                      <TextField
                        label="Quantity"
                        variant="outlined"
                        size="small"
                        type="number"
                        name="remainQuantity"
                        fullWidth
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.remainQuantity}
                        error={
                          formik.touched.remainQuantity &&
                          formik.errors.remainQuantity
                        }
                        helperText={
                          formik.touched.remainQuantity &&
                          formik.errors.remainQuantity
                        }
                      />
                    </Col>
                    <Col md={3} className="mb-2">
                      <TextField
                        label="Select Adjustment Type"
                        variant="outlined"
                        size="small"
                        type="text"
                        name="status"
                        fullWidth
                        select
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.status}
                        error={formik.touched.status && !!formik.errors.status}
                        helperText={
                          formik.touched.status && formik.errors.status
                        }
                      >
                        <MenuItem key={0} value={null}>
                          None
                        </MenuItem>
                        <MenuItem key={1} value={1}>
                          Add Quantity
                        </MenuItem>
                        <MenuItem key={1} value={2}>
                          Remove Quantity
                        </MenuItem>
                      </TextField>
                    </Col>
                    <Col md={3} className="mb-2">
                      <Button
                        type="submit"
                        variant="outline-info"
                        style={{ width: "150px" }}
                      >
                        Add To List
                      </Button>
                    </Col>
                  </Form.Group>
                  <Row>
                    <div style={{ width: "100%" }}>
                      <table className="summary-table">
                        <thead className="bg-info">
                          <tr>
                            <th>No</th>
                            <th>Item</th>
                            <th>Model</th>
                            <th>Unit</th>
                            <th>Qty</th>
                            <th>Adjust Type</th>
                            <th style={{ minWidth: "200px" }}>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {itemValue.map((request, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>

                              <td>
                                {items.map((item) =>
                                  item.Id === parseInt(request.itemId)
                                    ? item.description
                                    : ""
                                )}
                              </td>
                              <td>
                                {items.map((item) =>
                                  item.Id === parseInt(request.itemId)
                                    ? models.map((model) =>
                                        model.Id === parseInt(item.modelId)
                                          ? model.modelName
                                          : ""
                                      )
                                    : ""
                                )}
                              </td>
                              <td>
                                {items.map((item) =>
                                  item.Id === parseInt(request.itemId)
                                    ? units.map((unit) =>
                                        unit.Id === parseInt(item.unitId)
                                          ? unit.unitName
                                          : ""
                                      )
                                    : ""
                                )}
                              </td>
                              <td>{request.remainQuantity}</td>

                              <td>{request.status === 1 ? "Add" : "Reject"}</td>
                              <td>
                                <Button
                                  variant="outline-danger"
                                  onClick={(e) => {
                                    setItemValue(
                                      itemValue.filter(
                                        (i) =>
                                          parseInt(i.itemId) !==
                                          parseInt(request.itemId)
                                      )
                                    );
                                  }}
                                >
                                  <div
                                    className="row"
                                    style={{
                                      marginBottom: "-10px",
                                      marginLeft: "-10px",
                                    }}
                                  >
                                    <div className="col">
                                      <FontAwesomeIcon
                                        style={{
                                          float: "right",
                                          marginRight: "-5px",
                                        }}
                                        icon={faTrashCan}
                                      />
                                    </div>
                                    <div className="col-8">
                                      <h6
                                        style={{
                                          float: "left",
                                          marginLeft: "-3px",
                                        }}
                                      >
                                        Delete
                                      </h6>
                                    </div>
                                  </div>
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </Row>
                  <Form.Group as={Row}>
                    <Button
                      className="mt-2"
                      style={{
                        width: "42%",
                        marginLeft: "4%",
                        height: "40px",
                      }}
                      variant="outline-primary"
                      onClick={(e) => {
                        setItemValue([]);
                        formik.values.itemId = "";
                        formik.values.remainQuantity = "";
                        formik.values.status = "";
                      }}
                    >
                      <div className="row mt-1">
                        <div className="col">
                          <FontAwesomeIcon
                            style={{ float: "right" }}
                            icon={faBackwardStep}
                          />
                        </div>
                        <div className="col-8">
                          <h6 style={{ float: "left" }}>Back To List</h6>
                        </div>
                      </div>
                    </Button>

                    <Button
                      className="mt-2"
                      variant="outline-primary"
                      style={{
                        width: "42%",
                        marginLeft: "10%",
                        height: "40px",
                      }}
                      onClick={async (e) => {
                        try {
                          await axios
                            .post(
                              "https://enin-server.ga.highendtradingplc.com/adjustment_ref/add",
                              {
                                date: new Date(),
                              }
                            )
                            .then(async (res1) => {
                              let newId = res1.data.data.Id + "";
                              if (newId.length === 1) {
                                newId = "A-000" + newId;
                              } else if (newId.length === 2) {
                                newId = "A-00" + newId;
                              } else if (newId.length === 3) {
                                newId = "A-0" + newId;
                              } else {
                                newId = "A-" + newId;
                              }
                              itemValue.map(async (values) => {
                                let newItem = items.find(
                                  (i) => i.Id === values.itemId
                                );
                                if (values.status === 1) {
                                  newItem.physicalQuantity =
                                    parseInt(newItem.physicalQuantity) +
                                    parseInt(values.remainQuantity);
                                  newItem.logicalQuantity =
                                    parseInt(newItem.logicalQuantity) +
                                    parseInt(values.remainQuantity);
                                } else {
                                  newItem.physicalQuantity =
                                    parseInt(newItem.physicalQuantity) -
                                    parseInt(values.remainQuantity);
                                  newItem.logicalQuantity =
                                    parseInt(newItem.logicalQuantity) -
                                    parseInt(values.remainQuantity);
                                }
                                await axios.post(
                                  "https://enin-server.ga.highendtradingplc.com/adjustment/add",
                                  {
                                    quantity: values.remainQuantity,
                                    itemId: values.itemId,
                                    statusId: parseInt(values.status),
                                  }
                                );
                                await axios.post(
                                  "https://enin-server.ga.highendtradingplc.com/quantity/add",
                                  {
                                    quantity: newItem.logicalQuantity,
                                    salePurchaseId: newId,
                                    itemId: values.itemId,
                                  }
                                );
                                await axios.post(
                                  "https://enin-server.ga.highendtradingplc.com/deliveryquantity/add",
                                  {
                                    quantity: newItem.physicalQuantity,
                                    deliveryPurchaseId: newId,
                                    itemId: values.itemId,
                                  }
                                );
                                await axios.put(
                                  "https://enin-server.ga.highendtradingplc.com/item/update/" +
                                    values.itemId,
                                  newItem
                                );
                                setOpen(false);
                              });
                            });
                        } catch (error) {
                          console.error(error);
                        } finally {
                          fetchAllData();
                        }
                      }}
                    >
                      <div className="row mt-1">
                        <div className="col">
                          <FontAwesomeIcon
                            style={{ float: "right" }}
                            icon={faFloppyDisk}
                          />
                        </div>
                        <div className="col-7">
                          <h6 style={{ float: "left" }}>Submit</h6>
                        </div>
                      </div>
                    </Button>
                  </Form.Group>
                </Form>
              </div>
            )}
          </div>
        </div>
      </main>
    </div>
  );
};

export default Adjustment;
