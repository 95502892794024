/** @format */

import React, { useEffect, useState } from "react";
import { Button, Modal, Form, Col, Row } from "react-bootstrap";
import { useFormik } from "formik";
import TextField from "@mui/material/TextField";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import * as Yup from "yup";
import axios from "axios";
import Header from "../Header";
import { faPenToSquare, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const validationSchema = Yup.object().shape({
  fullName: Yup.string()
    .required("Name is required")
    .min(8, "Too Short!")
    .max(50, "Too Long"),
  phoneNumber: Yup.string()
    .required("Phone Number is required")
    .min(9, "Too Short!")
    .max(10, "Too Long"),
  address: Yup.string()
    .required("Address is required")
    .min(3, "Too Short!")
    .max(30, "Too Long"),
  tinNumber: Yup.string()
    .required("Tin Number is required")
    .min(2, "Too Short!")
    .max(20, "Too Long"),
});
const CustomerPage = () => {
  const [open, setOpen] = useState(false);
  const [customers, setCustomers] = useState([]);

  const fetchAllData = async () => {
    try {
      const [customer] = await Promise.all([
        axios.get("https://enin-server.ga.highendtradingplc.com/customer"),
      ]);
      setCustomers(customer.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchAllData();
  }, []);

  const formik = useFormik({
    initialValues: {
      fullName: "",
      tinNumber: "",
      address: "",
      phoneNumber: "",
      id: "",
    },

    validationSchema,
    onSubmit: async (values) => {
      try {
        if (values.id === "") {
          const { phoneNumber } = values;
          const userExists = customers.find(
            (customer) => customer?.phoneNumber === phoneNumber
          );
          if (userExists) {
            formik.setErrors({
              phoneNumber: "Phone number already exists.",
            });
            return;
          }
          await axios.post(
            "https://enin-server.ga.highendtradingplc.com/customer/add",
            values
          );
          setOpen(false);
        } else {
          await axios.put(
            "https://enin-server.ga.highendtradingplc.com/customer/update/" +
              values.id,
            values
          );
          setOpen(false);
        }
      } catch (error) {
        console.log(error);
      } finally {
        fetchAllData();
      }
    },
  });
  const handleAdd = () => {
    formik.values.address = "";
    formik.values.fullName = "";
    formik.values.phoneNumber = "";
    formik.values.tinNumber = "";
    formik.values.id = "";
    setOpen(true);
  };
  const handleUpdate = (id) => {
    const customer = customers.find((cust) => cust.Id === parseInt(id));
    formik.values.address = customer.address;
    formik.values.fullName = customer.fullName;
    formik.values.phoneNumber = customer.phoneNumber;
    formik.values.tinNumber = customer.tinNumber;
    formik.values.id = parseInt(id);
    setOpen(true);
  };
  const handleDelete = async (id) => {
    try {
      await axios.delete(
        "https://enin-server.ga.highendtradingplc.com/customer/delete/" + id
      );
      fetchAllData();
    } catch (error) {}
  };

  const handleOpen = () => {
    setOpen(false);
  };
  const columns = [
    {
      field: "Id",
      headerAlign: "center",
      align: "center",
      headerName: "ID",
      minWidth: 80,
    },
    {
      field: "fullName",
      headerAlign: "center",
      align: "center",
      headerName: "Customer Name",
      minWidth: 150,
    },
    {
      field: "tinNumber",
      headerAlign: "center",
      align: "center",
      headerName: "Tin Number",
      minWidth: 120,
    },
    {
      field: "phoneNumber",
      headerAlign: "center",
      align: "center",
      headerName: "Phone Number",
      minWidth: 150,
    },
    {
      field: "address",
      headerAlign: "center",
      align: "center",
      headerName: "Address",
      minWidth: 120,
    },
    {
      field: "action",
      headerAlign: "center",
      align: "center",
      headerName: "Action",
      minWidth: 240,

      renderCell: (params) => (
        <>
          <Button
            onClick={() => handleUpdate(params.row.Id)}
            variant="outline-warning"
            className="m-1"
          >
            <div
              className="row"
              style={{
                marginBottom: "-10px",
                marginLeft: "-10px",
              }}
            >
              <div className="col">
                <FontAwesomeIcon
                  style={{ float: "right", marginRight: "-7px" }}
                  icon={faPenToSquare}
                />
              </div>
              <div className="col-7">
                <h6
                  style={{
                    float: "left",
                    marginLeft: "-3px",
                  }}
                >
                  Edit
                </h6>
              </div>
            </div>
          </Button>
          <Button
            onClick={() => handleDelete(params.row.Id)}
            variant="outline-danger"
            className=""
          >
            <div
              className="row "
              style={{
                marginBottom: "-10px",
                marginLeft: "-10px",
              }}
            >
              <div className="col">
                <FontAwesomeIcon
                  style={{ float: "right", marginRight: "-5px" }}
                  icon={faTrashCan}
                />
              </div>
              <div className="col-8">
                <h6 style={{ float: "left", marginLeft: "-3px" }}>Delete</h6>
              </div>
            </div>
          </Button>
        </>
      ),
    },
  ];
  return (
    <div className="">
      <div>
        <Header />
      </div>
      <main className="" style={{ marginTop: "120px" }}>
        <div className="">
          <div className="card card-info card-outline">
            <div className="card-header pl-2">
              <Button
                variant="outline-info"
                style={{ width: "150px", height: "35px" }}
                onClick={handleAdd}
              >
                <h3
                  style={{
                    float: "left",
                    marginTop: "-6px",
                    marginRight: "5px",
                  }}
                >
                  +
                </h3>
                <h6 style={{ float: "left", marginTop: "1px" }}> Create New</h6>
              </Button>
            </div>
            <DataGrid
              rows={customers}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              columns={columns}
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                },
              }}
              initialState={{
                ...customers.initialState,
                pagination: {
                  paginationModel: { pageSize: 5 },
                },
              }}
              pageSizeOptions={[5, 10, 15]}
              getRowId={(row) => row.Id}
            />
          </div>
          <Modal show={open} onHide={handleOpen} style={{ minWidth: "700px" }}>
            <Modal.Header closeButton>
              <span>
                Home / Customers /{" "}
                {formik.values.id === "" ? "Create" : "Update"}
              </span>
            </Modal.Header>
            <Modal.Body>
              <Form className="m-2 ml-5 pl-5" onSubmit={formik.handleSubmit}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formHorizontalUsername"
                >
                  <Col sm={8}>
                    <TextField
                      id="outlined-basic"
                      label="Full Name"
                      variant="outlined"
                      size="small"
                      type="text"
                      name="fullName"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.fullName}
                      error={formik.touched.fullName && formik.errors.fullName}
                      helperText={
                        formik.touched.fullName && formik.errors.fullName
                          ? `${formik.errors.fullName}`
                          : ""
                      }
                      style={{ maxWidth: "300px" }}
                    />
                  </Col>
                </Form.Group>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formHorizontalUsername"
                >
                  <Col sm={8}>
                    <TextField
                      id="outlined-basic"
                      label="Till Number"
                      variant="outlined"
                      size="small"
                      type="text"
                      name="tinNumber"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.tinNumber}
                      error={
                        formik.touched.tinNumber && formik.errors.tinNumber
                      }
                      helperText={
                        formik.touched.tinNumber && formik.errors.tinNumber
                          ? `${formik.errors.tinNumber}`
                          : ""
                      }
                      style={{ maxWidth: "300px" }}
                    />
                  </Col>
                </Form.Group>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formHorizontalUsername"
                >
                  <Col sm={8}>
                    <TextField
                      id="outlined-basic"
                      label="Phone Number"
                      variant="outlined"
                      size="small"
                      type="text"
                      name="phoneNumber"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.phoneNumber}
                      error={
                        formik.touched.phoneNumber && formik.errors.phoneNumber
                      }
                      helperText={
                        formik.touched.phoneNumber && formik.errors.phoneNumber
                          ? `${formik.errors.phoneNumber}`
                          : ""
                      }
                      style={{ maxWidth: "300px" }}
                    />
                  </Col>
                </Form.Group>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formHorizontalUsername"
                >
                  <Col sm={8}>
                    <TextField
                      id="outlined-basic"
                      label="Address"
                      variant="outlined"
                      size="small"
                      type="text"
                      name="address"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.address}
                      error={formik.touched.address && formik.errors.address}
                      helperText={
                        formik.touched.address && formik.errors.address
                          ? `${formik.errors.address}`
                          : ""
                      }
                      style={{ maxWidth: "300px" }}
                    />
                  </Col>
                </Form.Group>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formHorizontalUsername"
                >
                  <Col sm={12}>
                    <Button
                      style={{ width: "62%" }}
                      variant="outline-success"
                      type="submit"
                    >
                      {formik.values.id === "" ? "Create" : "Update"}
                    </Button>
                  </Col>
                </Form.Group>
              </Form>
            </Modal.Body>
          </Modal>
        </div>
      </main>
    </div>
  );
};

export default CustomerPage;
